import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Button, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { helpmoduleGuide } from "./guide";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditWrap from "../commonComponent/editWrap";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
      color: "black",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ColorBox = ({ color, label }) => (
  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <div style={{ backgroundColor: color, width: "15px", height: "15px" }}></div>
    &nbsp; &nbsp; <p>{label}</p>
  </div>
);

const Help = () => {
  const helpmoduleGuides = helpmoduleGuide();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <EditWrap>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              helpmoduleGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      {/* Accordion for Certificate Colors */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{ boxShadow: "1px 1px 5px #000" }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{ backgroundColor: "#ccc"}}
        >
          <Typography>Color Code</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: "flex", alignItems: "start", paddingLeft: "20px", flexDirection: "column" }}>
            <ColorBox color="rgb(108 231 150)" label="Certificate Signed By Client" />
            <ColorBox color="rgb(223 87 223)" label="Certificate Reviewed By Client" />
            <ColorBox color="#f37c4f" label="Certificate Witnessed By Client" />
            <ColorBox color="rgb(123 123 223)" label="Certificate Approved By Lab" />
            <ColorBox color="#22c9b0" label="Datasheet Calibrated" />
            <ColorBox color="#37AFE1" label="Srf Approved" />
          </Box>
        </AccordionDetails>
      </Accordion>
    

      <div style={{ textAlign: "left", margin: "20px 0px" }}>
        <h5>Application Term Glossary</h5>
        <br />
        <h6>NA : Not Available</h6>
        <h6>"-" : Not Required (Inward Cal Frequency)</h6>
      </div>

      <div style={{ textAlign: "left", margin: "20px 0px" }}>
        <h5>Srf Approved</h5>
        <div style={{display:"flex",alignItems:"center",marginTop:"5px"}}>
        <Button size="small">
           <CheckCircleIcon />
        </Button>
        <h6>Srf Not Approved</h6>
        </div>
        <div style={{display:"flex",alignItems:"center"}}>
        <Button size="small" disabled>
           <CheckCircleIcon />
        </Button>
        <h6>Srf Approved</h6>
        </div>
      </div>
    </EditWrap>
  );
};

export default Help;
