import React, { useEffect, useRef } from 'react';

const CustomPDFViewer = ({ url, height, canvasHeight }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const loadPdf = async () => {
      if (!url) return;

      try {

        const loadingTask = window.pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        const numPages = pdf.numPages;
        // Clear any previous canvases
        containerRef.current.innerHTML = '';

        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
          const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 1.3 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;

        // Disable right-click on the canvas
        canvas.addEventListener('contextmenu', (e) => e.preventDefault());

          // Append the canvas to the container
          containerRef.current.appendChild(canvas);
          // Set canvas height if provided
          if (canvasHeight) {
            canvas.style.height = canvasHeight;
          }
        }
      } catch (error) {
        console.error('Error loading or rendering PDF:', error);
      }
    };

    loadPdf();

    // Cleanup event listener on component unmount
    return () => {
      if (containerRef.current) {
        Array.from(containerRef.current.children).forEach((canvas) => {
          canvas.removeEventListener('contextmenu', (e) => e.preventDefault());
        });
      }
    };
  }, [url]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        height: height || '500px',
        overflow: 'auto',
      }}
    />
  );
};

export default CustomPDFViewer;
