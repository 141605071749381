import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Toolbar,
  TextField,
  Autocomplete,
  Modal,
  Grid,
  Box,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import { Table } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { unitListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import ListWrap from "../../commonComponent/listWrap";
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "disciplineId",
    label: "Discipline",
    align: "left",
    minWidth: 40,
  },
  {
    id: "symbol",
    label: "Symbol",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, disciplineId, symbol) {
  return {
    id,
    disciplineId,
    symbol,
  };
}

export default function UnitList() {
  const unitListGuides = unitListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);  
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Symbol");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || "");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [unitList, setUnitList] = React.useState([]);
  const [unitData, setUnitData] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const [selected, setSelected] = React.useState([]);
  const handleSelectRow = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const isSelected = (id) => selected.includes(id);

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    const idString = (Array.isArray(id) ? id : [id]).join(',');
    let query = {
      query: `DELETE FROM unit WHERE id IN (${idString});`
      };
    let url = BASE_URL;
    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        toast("Unit was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => console.log("err",error));
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Discipline Name": "discipline.name",
        "Symbol": "symbol",
      }[searchBy];

      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }
    if (whereConditions == "") {
      whereConditions += ` where NOT unit.status = -1`;
    } else {
      whereConditions += ` and NOT unit.status = -1 `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `SELECT unit.*, discipline.name AS disciplineId FROM unit JOIN discipline ON unit.disciplineId = discipline.id ${whereConditions} order by unit.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(*) as no_of_rows FROM unit JOIN discipline ON unit.disciplineId = discipline.id ${whereConditions} order by unit.id DESC`;

    return data;
  };

  // const getQuery = (event) => {
  //   let url = BASE_URL;
  //   const payload1 = {
  //     query:
  //       "SELECT unit.*, discipline.name AS disciplineId FROM unit JOIN discipline ON unit.disciplineId = discipline.id WHERE unit.status = 1 ORDER BY unit.id DESC",
  //   };
  //   axiosWithToken
  //     .post(url + "dynamic", payload1)
  //     .then((res) => {
  //       setUnitList(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       if (err.message !== "request_aborted") {
  //         toast.error(<h6 onClick={() => openErrorModal(err.message, "getUnitList" ,"Unit List")}><u>Error: {err.message}</u></h6>);
  //       }
  //     });
  // };
   function getTotalRows() {
        let data = getQuery(true);
        axiosWithToken
          .post(BASE_URL + `dynamic`, data)
          .then(async (res) => {
            setTotalRows(res.data[0].no_of_rows);
          })
          .catch((err) => console.log("data fetching error: ", err));
      }
    
      function fetchUnitList() {
        let data = getQuery();  
        axiosWithToken
        .post(BASE_URL + `dynamic`, data)
        .then((res) => {
          setUnitList(res.data);
        })
          .catch((err) => console.log("data fetching error: ", err));
      }

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    fetchUnitList();
    getTotalRows()
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Symbol");
    setSearchKey("");
    setSearched(false); 
  };

  const initializeUnitData = () => {
    var rowDaata = [];
    for (let i = 0; i < unitList.length; i++) {
      rowDaata.push(
        createData(
          unitList[i].units_id,
          unitList[i].disc_name,
          unitList[i].units_symbol
        )
      );
    }
    setUnitData(rowDaata);
  };

  useEffect(() => {
    if(searched==false){
      fetchUnitList();
      getTotalRows()
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  useEffect(() => {
    initializeUnitData();
  }, [unitList]);

  useEffect(() => { 
    fetchUnitList();
    getTotalRows()
  }, [rowsPerPage, page]);
  

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 0px", marginBottom: "2px" }}
    >
      <Grid item xs={12} sm={5} md={2} lg={2} id="client_list_searchby">
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          className="Listinputcss"
          size="small"
          value={searchBy}
          options={[
            { key: "instrumentId", label: "Discipline Name" },
            { key: "symbol", label: "Symbol" }
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <TextField
          id="client_list_searchbytext"
          label={"enter " + searchBy}
          size="small"
          className="Listinputcss"
          value={searchKey}
          variant="outlined"
          inputProps={{
            autoComplete: 'off',
          }}
          onChange={(e) => {
              setSearchKey(e.target.value)
              setSearched(false);
            }}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={1} lg={1} textAlign={"left"}>
        <Button
          id="client_list_searchbtn"
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <ListWrap>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="unit_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/unit/EditUnit/0"
            onClick={() => {
              {
              }
            }}
          >
            <b>create UNIT</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
          <Tooltip title="User Guide" placement="top-start">
            <Button
              onClick={() => {
                unitListGuides.drive();
              }}
            >
              <HelpIcon />
            </Button>
          </Tooltip>
        </Box>
      </div>
      {!isMobile && filterSection()}
      {selected.length > 0 && (
        <Box sx={{textAlign:"left", margin:"10px 0px"}}>
          <Button
          variant="contained"
          size="small"
          color={"error"}
          onClick={() => {
            if (window.confirm("Really want to delete unit?")){
              submitSiteDelete(selected);
            } 
          }}
          >Multiple Delete</Button>
        </Box>
      )}
      <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="unit_list_table"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {(editAccess?.includes(2) || editAccess?.includes(0)) && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < unitList.length}
                checked={selected.length === unitList.length}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSelected(unitList.map((row) => row.id));
                  } else {
                    setSelected([]);
                  }
                }}
              />
            </TableCell>
            )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unitList?.length > 0 ? (
              unitList
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                     {(editAccess?.includes(2) || editAccess?.includes(0)) && (
                   <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(row.id)}
                      onChange={() => handleSelectRow(row.id)}  
                    />
                  </TableCell>
                   )}
                    {columns.map((column) => {
                      let value = row[column.id];

                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell>
                      <ButtonGroup size="small" aria-label="small button group">
                        <Tooltip title="Edit Unit" placement="top-start">
                          <Button
                            id="unit_list_edit"
                            component={Link}
                            to={`/master/unit/EditUnit/${row.id}`}
                          >
                            <PreviewIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Delete Unit" placement="top-start">
                          <Button
                            id="unit_list_delete"
                            disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                            onClick={(e) => {
                              if (
                                window.confirm("Really want to delete Unit?")
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon  style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                 <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )} 
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 30, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </ListWrap>
  );
}
