import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Autocomplete,
  Tooltip,
  Box,
} from "@mui/material";
import { useParams } from "react-router";
import { mastereditsettingGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import EditWrap from "../../commonComponent/editWrap";


const KeysArray = [
  { label: "Certificate Number" },
  { label: "ULR number" },
  { label: "serviceReqNumber" },
  { label: "DC Number" },
  { label: "Remarks" },
  { label: "gstPercentages" },
  { label: "activeRole1" },
  { label: "activeRole2" },
  { label: "activeRole3" },
  { label: "activeRole4" },
  { label: "activeRole5" },
  { label: "DUCMasterStatus" },
  { label: "Calibration Type" },
  { label: "Invoice Number" },
  { label: "Invoice Terms" },
  { label: "QuotationNumber" },
  { label: "quotation terms" },
  { label: "lastModified" },
  { label: "Compliance Status" },
  { label: "amendment" },
  { label: "CSRFS" },
  { label: "Customer Annexure" },
  { label: "Client Settings" },
  { label: "role1_PossibleFields" },
  { label: "role2_PossibleFields" },
  { label: "role3_PossibleFields" },
  { label: "role4_PossibleFields" },
  { label: "role5_PossibleFields" },
  { label: "others_PossibleFields" },
  { label: "role1Label_PossibleFields" },
  { label: "role2Label_PossibleFields" },
  { label: "Opinion And Analysis Table" },
  { label: "DS Row: standard master validation" },
  { label: "KFactorFactionPercent" },
  { label: "CalibrationTypeOptions" },
  { label: "accreditationType" },
  { label: "electronicSign" },
  { label: "companyHierarchy" },
  { label: "materialType" },
  { label: "Datasheet Autoload" },
  { label: "TrainingAndMasterCertificate" },
  { label: "SOPUpload" },
  { label: "Compliance Status Alert"},
  { label: "Other" },
];

export default function EditSetting(props) {
  const mastereditsettingGuides = mastereditsettingGuide();
  const [setting, setSetting] = useState({});
  const [settingList, setSettingList] = useState([]);
  const params = useParams();
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage,stackTrace,fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getSettingsList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `settings${params?.id != 0 ? "/" + params.id : ""}`)
      .then((res) => {
        setSettingList(res.data);

        if (params?.id != 0) {
          setSetting({
            key: [
              "Certificate Number",
              "ULR number",
              "ULRNo",
              "SRF Number",
              "DC Number",
              "Remarks",
              "Other",
            ].includes(res.data[0].keyName)
              ? res.data[0].keyName
              : "Other",
            keyName: res.data[0].keyName,
            value: res.data[0].value,
          });
        }
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(<h6 onClick={() => openErrorModal(err.message, "getSettingsList" ,"Edit Setting")}><u>Error: {err.message}</u></h6>);
        }
      });
  };

  const onSubmit = (event) => {
    let row = {
      keyName: setting.key !== "Other" ? setting.key : setting.keyName,
      value: setting.value,
      type: 2, // text
    };

    let url = BASE_URL;
    if (params?.id != 0) {
      axiosWithToken
        .patch(url + `settings/${params?.id}`, row)
        .then((res) => {
          props.setLoader(false);
          toast("settings created successfully !");
          setTimeout(refresh, 500);
        })
        .catch((err) => {
          // props.setLoader(false);
          if (err.message !== "request_aborted") {
            toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"Edit Setting")}><u>Error: {err.message}</u></h6>);
          }
        });
    } else {
      axiosWithToken
        .post(url + `settings`, row)
        .then((res) => {
          props.setLoader(false);
          toast("settings created successfully !");
          setTimeout(refresh, 500);
        })
        .catch((err) => {
          // props.setLoader(false);
          if (err.message !== "request_aborted") {
            toast.error(<h6 onClick={() => openErrorModal(err.message, "onSubmit" ,"Edit Setting")}><u>Error: {err.message}</u></h6>);
          }
        });
    }
  };

  // data operations
  const updateSetting = (key, value) => {
    let newSetting = {
      ...setting,
    };
    newSetting[key] = value;
    setSetting(newSetting);
  };

  useEffect(() => {
    getSettingsList();
  }, []);
  return (
    <EditWrap>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create/Edit setting
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              mastereditsettingGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>

      <Grid container spacing={2} sx={{ pb: 2 }}>
        {params.id == 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              size="small"
              id="setting_create_selectkey"
              value={params?.id != 0 || setting?.key ? setting?.key : "Other"}
              options={KeysArray}
              renderInput={(params) => <TextField {...params} label="Key *" />}
              onInputChange={(e, newInputValue) => {
                updateSetting("key", newInputValue);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          {setting.key === "Other" && (
            <TextField
              id="setting_create_keyname"
              label="Key Name *"
              value={setting?.keyName}
              size="small"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateSetting("keyName", e.target.value);
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          <TextField
            id="setting_create_keyvalue"
            label="Value *"
            value={setting?.value || ""}
            multiline
            maxRows={10}
            rows={10}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSetting("value", e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="setting_create_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          onClick={() => {
            if (params.id == 0) {
              if (setting?.key == "Other" && !setting?.keyName) {
                toast.error('keyName is required');
                return;
              }
              if (!setting?.value) {
                toast.error('key value is required');
                return;
              }
            }
            props.setLoader(true);
            onSubmit();
          }}
        >
          {params.id != 0 ? "Update" : "Save"}
        </Button>
      </Toolbar>
      <ToastContainer />
      {errormodalIsOpen && <ErrorModal errormodalIsOpen={errormodalIsOpen} closeErrorModal={closeErrorModal} errorMessage={errorMessage} />}
    </EditWrap>
  );
}
