import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  TableBody,
  ButtonGroup,
  TablePagination,
  Tooltip,
  Toolbar,
  TableContainer,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { BASE_URL } from "../../global";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import HelpIcon from "@mui/icons-material/Help";
import { viewDocumentGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ListWrap from "../commonComponent/listWrap";
const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      textAlign: "center",
    },
  },
  tableWithoutBorder: {
    "& .MuiTableCell-root": {
      border: "1px solid black",
      borderBottom: "none !important",
      paddingLeft: "5px",
    },
  },
  row: {
    height: 15,
  },
  gstCell: {
    height: 5,
    border: "none",
  },
  cell: {
    padding: 1,
    width: 180,
    height: 5,
  },
  signCell: {
    padding: 1,
    width: 30,
    alignItems: "center",
    fontStyle: "bold",
  },
  srCell: {
    padding: 1,
    width: 100,
    height: 5,
  },

  nameCell: {
    padding: 1,
    width: 250,
    height: 5,
  },
  makeCell: {
    padding: 1,
    width: 100,
    height: 5,
  },
  rangeCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  qntCell: {
    padding: 1,
    width: 40,
    height: 5,
  },
  rateCell: {
    padding: 1,
    width: 120,
    height: 5,
  },
  totalCell: {
    padding: 1,
    width: 130,
    height: 5,
  },
});
const ViewDocument = () => {
  const viewDocumentGuides = viewDocumentGuide();
  const classes = useStyles();
  const [doclist, setDoclist] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userType, setUserType] = useState(localStorage.getItem("type"));

  const getQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";


    if (userType == "3") {
      whereConditions +=
        whereConditions.length > 0
          ? `WHERE documentName LIKE '%NABL %'`
          : `WHERE documentName LIKE '%NABL %'`;
    }

    

    let data = {
      query: `select * from documentUploader ${whereConditions} order by id DESC ${pagination_settings}`,
    };

    return data;
  };

  const getDocumentData = () => {
    let data = getQuery();
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setDoclist(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong while fetching standardRanges!");
        }
      });
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this document?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      axiosWithToken
        .delete(BASE_URL + `documentUploader/${id}`)
        .then((res) => {
          toast.success("Document Delete Successfully!");
          getDocumentData();
        })
        .catch((error) => {
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    } catch (error) {
      console.error("Error deleting equipment:", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getDocumentData();
  }, []);

  return (
    <ListWrap>
      {userType == 1 && (
       <div style={{ display: "flex", justifyContent: "space-between" }}>
         <Toolbar style={{ padding: "0px" }}>
          <Button
          id="viewDocument_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/documentUpload/create/0"
          >
            Add
          </Button>
         </Toolbar>
          <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          viewDocumentGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
        </div>
      )}
      <TableContainer>
      <Table 
      stickyHeader
      aria-label="sticky table"
      size="small"
      sx={{ borderCollapse: "collapse" }}
       id="viewDocument_list_table">
        <TableHead className="single-border-table">
          <TableRow>
            <TableCell className={[classes.srCell, "special2"]} align="center">
              <b>Sr. No</b>
            </TableCell>
            <TableCell
              className={[classes.nameCell, "special2"]}
              align="center"
            >
              <b>Document Name </b>
            </TableCell>
            <TableCell
              className={[classes.rateCell, "special2"]}
              align="center"
            >
              <b>Action</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {doclist?.length > 0 ? (
            doclist
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, ind) => (
                <TableRow key={ind}>
                  <TableCell
                    className={[classes.srCell, "special2"]}
                    align="center"
                  >
                    {ind + 1}
                  </TableCell>
                  <TableCell
                    className={[classes.srCell, "special2"]}
                    align="center"
                  >
                    {item?.documentName}
                  </TableCell>
                  <TableCell
                    className={[classes.srCell, "special2"]}
                    align="center"
                  >
                    <ButtonGroup size="small" aria-label="small button group">
                      {userType == 1 && (
                        <div>
                          <Tooltip title="Edit document" placement="top-start">
                            <Button
                            id="viewDocument_list_edit"
                              component={Link}
                              to={`/documentUpload/create/${item.id}`}
                            >
                              <EditIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Delete document"
                            placement="top-start"
                          >
                            <Button 
                            id="viewDocument_list_delete"
                            onClick={(e) => handleDelete(item.id)}>
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                      <Tooltip title="View document" placement="top-start">
                        <a
                          href={item?.documentURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button id="viewDocument_list_view">
                            <PreviewIcon />
                          </Button>
                        </a>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} align="center">
                <b>Data not found</b>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={doclist.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      ></TablePagination>
      </TableContainer>
    </ListWrap>
  );
};

export default ViewDocument;
