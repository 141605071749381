import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Toolbar,
  TextField,
  Autocomplete,
  Modal,
  Grid,
  Box,
  useMediaQuery,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  ButtonGroup,
  Table
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import ListWrap from "../../commonComponent/listWrap";

const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "userName",
    label: "Engineer Name",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, disciplineId) {
  return {
    id,
    disciplineId,
  };
}

export default function AssignedDucList() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [page, setPage] = React.useState(0);
  const [searchBy, setSearchBy] = React.useState(
    sessionStorage.getItem("searchBy") || "Engineer Name"
  );
  const [searchKey, setSearchKey] = React.useState(
    sessionStorage.getItem("searchKey") || ""
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [assignedDucsList, setassignedDucsList] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axiosWithToken
      .delete(url + `assignedDucs/${id}`)
      .then((res) => {
        toast("Assigned Duc was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "submitSiteDelete",
                  "assignedDucs List"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getassignedDucsList = (event) => {
    let url = BASE_URL;
    const payload = {
      query:
        "SELECT assignedDucs.id, users.userName, assignedDucs.status FROM users JOIN assignedDucs ON users.id = assignedDucs.userId WHERE assignedDucs.status = 1 ORDER BY assignedDucs.id DESC",
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        setassignedDucsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuery = async () => {
    let colName = {
      "Engineer Name": "userName",
    }[searchBy];
    let query = {
      query: `SELECT users.id, users.userName, assignedDucs.status FROM users JOIN assignedDucs ON users.id = assignedDucs.userId WHERE assignedDucs.status = 1 and  ${colName} LIKE '%${searchKey}%'  ORDER BY assignedDucs.id DESC`,
    };
    try {
      const response = await axiosWithToken.post(BASE_URL + `dynamic`, query);
      console.log(response.data);
      setassignedDucsList(response.data);
    } catch (err) {
      console.error(err);
      let trace = new Error().stack;
      if (err.message !== "request_aborted") {
        toast.error(
          <h6
            onClick={() =>
              openErrorModal(err.message, trace, "assignedDucs List")
            }
          >
            <u>Error: {err.message}</u>
          </h6>
        );
      }
    }
  };
  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if (storedSearchBy) {
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getQuery();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Engineer Name");
    setSearchKey("");
    setSearched(false);
  };

  useEffect(() => {
    if (searched == false) {
      getassignedDucsList();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 0px", marginBottom: "2px" }}
    >
      <Grid item xs={6} sm={6} md={2} lg={2}>
        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
          }}
          className="Listinputcss"
          size="small"
          value={searchBy}
          options={[{ key: "userName", label: "Engineer Name" }]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
            setSearched(false);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2}>
        <TextField
          label={"enter " + searchBy}
          size="small"
          className="Listinputcss"
          value={searchKey}
          variant="outlined"
          inputProps={{
            autoComplete: "off",
          }}
          onChange={(e) => {
            setSearchKey(e.target.value);
            setSearched(false);
          }}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={1} lg={1} textAlign={"left"}>
        <Button
          variant="contained"
          size="small"
          color={searched ? "error" : "primary"}
          onClick={() => {
            searched ? clearSearch() : search();
            handleFilterClose();
          }}
        >
          {searched ? <RestartAltIcon /> : <SearchIcon />}
        </Button>
      </Grid>
    </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  return (
    <ListWrap>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="assignedDucs_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/editassignedDuc/0"
          >
            <b>Assigned Master</b>
          </Button>
        </Toolbar>
        <Box sx={{ display: "flex", gap: "0rem" }}>
          <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
        </Box>
      </div>
      {!isMobile && filterSection()}
      <TableContainer sx={{ maxHeight: isMobile ? "80vh" : "65vh" }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          style={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell>
                <b>Actions</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignedDucsList?.length > 0 ? (
              assignedDucsList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        let value = row[column.id];

                        if (column.id == "id") {
                          value = page * rowsPerPage + index + 1;
                        }
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}

                      <TableCell>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                        >
                          <Tooltip
                            title="Edit assignedDucs"
                            placement="top-start"
                          >
                            <Button
                              id="assignedDucs_list_edit"
                              component={Link}
                              to={`/master/editassignedDuc/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Delete assignedDucs"
                            placement="top-start"
                          >
                            <Button
                              disabled={
                                !(
                                  editAccess?.includes(2) ||
                                  editAccess?.includes(0)
                                )
                              }
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Really want to delete assigned Ducs"
                                  )
                                ) {
                                  submitSiteDelete(row.id);
                                }
                              }}
                            >
                              <DeleteIcon
                                style={{
                                  color: !(
                                    editAccess?.includes(2) ||
                                    editAccess?.includes(0)
                                  )
                                    ? "lightgray"
                                    : "#dc3545",
                                }}
                              />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <h6>
                    <b>Data Not Found</b>
                  </h6>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 30, 100]}
        component="div"
        count={assignedDucsList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </ListWrap>
  );
}
