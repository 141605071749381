import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getClientsThunk } from "../../globalDataSlice";
import { request } from "../../../psuedoApi";

export const getEnquiryThunk = createAsyncThunk(
  "enquiry/getEnquiry",
  async (params, thunkApi) => {
    thunkApi.dispatch(setStatus({ status: 1 }));
    let enquiry = {};
    if (params)
      enquiry = await request({
        url: "getEnquiry",
        params,
        type: "get",
      });
    if (enquiry.length) {
      enquiry = enquiry[0];
    } else enquiry = {};
    await thunkApi.dispatch(getClientsThunk());
    thunkApi.dispatch(setEnquiry({ enquiry }));
  }
);

export const getEnquiesTableThunk = createAsyncThunk(
  "enquiry/getEnquiryTable",
  async (params, thunkApi) => {
    thunkApi.dispatch(setStatus({ status: 1 }));
    let table = [],
      count = 0;
    if (params) {
      table = await request({
        url: "getEnquiryTable2",
        params,
        type: "get",
      });
      count = await request({
        url: "getEnquiryTableCount",
        params,
        type: "post",
        dynamic: true,
      });
    }
    if (table.length) {
      count = count[0].no_of_rows;
    } else {
      table = [];
      count = 0;
    }
    await thunkApi.dispatch(getClientsThunk());
    thunkApi.dispatch(setEnquiryTable({ table, count }));
  }
);

export const createEnquiryThunk = createAsyncThunk(
  "enquiry/createEnquiry",
  async (params, thunkApi) => {
    thunkApi.dispatch(setStatus({ status: 1 }));
    await request({
      url: "createEnquiry",
      params,
      type: "post",
    });
    thunkApi.dispatch(setCreated());
  }
);

export const updateEnquiryThunk = createAsyncThunk(
  "enquiry/updateEnquiry",
  async (params, thunkApi) => {
    thunkApi.dispatch(setStatus({ status: 1 }));
    await request({
      url: "updateEnquiry",
      params,
      type: "patch",
    });
    thunkApi.dispatch(setUpdated());
  }
);

export const deleteEnquiryThunk = createAsyncThunk(
  "enquiry/deleteEnquiry",
  async (params, thunkApi) => {
    thunkApi.dispatch(setStatus({ status: 1 }));
    await request({
      url: "deleteEnquiry",
      params,
      type: "delete",
    });
    thunkApi.dispatch(setStatus({ status: 2 }));
  }
);

export const createClientThunk = createAsyncThunk(
  "enquiry/createClientThunk",
  async (params, thunkApi) => {
    thunkApi.dispatch(setStatus({ status: 1 }));
    await request({
      url: "createClient",
      params,
      type: "post",
    });
    await thunkApi.dispatch(getClientsThunk({ refresh: true }));
  }
);

const initialState = {
  enquiry: {},
  enquiryUpdates: {},
  enquiryList: [],
  enquiryListCount: 0,
  loading: 0,
  created: 0,
  updated: 0,
};

export const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    setEnquiryTable: (state, action) => {
      state.enquiryList = action.payload.table;
      state.enquiryListCount = action.payload.count;
      state.loading = 0;
    },
    setEnquiry: (state, action) => {
      state.enquiry = action.payload.enquiry;
      state.loading = 0;
    },
    updateEnquiry: (state, action) => {
      state.enquiryUpdates[action.payload.name] = action.payload.value;
    },
    setStatus: (state, action) => {
      state.loading = action.payload.status;
    },
    setUpdated: (state, action) => {
      state.loading = 0;
      state.updated = 1;
    },
    setCreated: (state, ation) => {
      state.loading = 0;
      state.created = 1;
    },
    normalize: (state, action) => {
      state.created = 0;
      state.updated = 0;
    },
  },
});

export const {
  setEnquiry,
  updateEnquiry,
  setEnquiryTable,
  setStatus,
  setUpdated,
  setCreated,
  normalize,
} = enquirySlice.actions;

export const getEnquiryData = (state) => {
  return {
    enquiry: state.enquiry.enquiry,
    enquiryUpdates: state.enquiry.enquiryUpdates,
    clients: state.globalData.clients,
    loading: state.enquiry.loading,
    created: state.enquiry.created,
    updated: state.enquiry.updated,
  };
};

export const getEnquiryListData = (state) => {
  return {
    enquiry: state.enquiry.enquiryList,
    count: state.enquiry.enquiryListCount,
    loading: state.enquiry.loading,
    clients: state.globalData.clients,
  };
};

export default enquirySlice.reducer;
