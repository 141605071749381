import React, { useState, useEffect, Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";
import { Autocomplete, Checkbox, createFilterOptions } from "@mui/material";
import { BASE_URL, DOMAIN } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ListWrap from "../commonComponent/listWrap";
const userType = localStorage.getItem("type");
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");


var columns = [
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "customerName",
    label: "Client",
    align: "left",
    minWidth: 40,
  },
  {
    id: "companyHierarchy",
    label: "Division",
    align: "left",
    minWidth: 40,
  },
  {
    id: "companyHierarchy",
    label: "Department",
    align: "left",
    minWidth: 40,
  },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "ULRNo",
    label: "ULR Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "range",
    label: "Range",
    minWidth: 40,
    align: "left",
  },
  {
    id: "lc",
    label: "LC",
    minWidth: 40,
    align: "left",
  },
  {
    id: "idNo",
    label: "DUC ID",
    minWidth: 40,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "make",
    label: "Make",
    minWidth: 40,
    align: "left",
  },
  {
    id: "model",
    label: "Model",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dateOfCalibration",
    label: "Date of Calibration",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "dueDate",
    label: "Due Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

var createData = (
  id,
  customerName,
  workOrderNo,
  instrument,
  identificationNo,
  dateOfCalibration,
  calProcRefNo
) => {
  return {
    id,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo,
  };
};

const notNeededColumn = [
  "id",
  "status",
  "clientId",
  "dcNumber",
  "identificationNo",
  "dateOfIssue",
  "srnNo",
  "isProgress",
  "companyHierarchy"
];

const filter = createFilterOptions();

export default function RenewCertificate(props) {
  const printComponentRef = React.useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [certificate, setCertificate] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [searched, setSearched] = React.useState(false);

  const [enableDisable, setEnableData] = React.useState("");
  const [certReadings, setCertReadings] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [selectedCompanyHierarchy, setSelectedCompanyHierarchy] =
    React.useState({});
  const [companyHierarchyOptions, setCompanyHierarchyOptions] = React.useState([
    [],
    [],
  ]);
  const [hierarchyResultArray, setHierarchyResultArray] = React.useState([
    [],
    [],
  ]);
  const [companyHierarchy, setCompanyHierarchy] = React.useState(null);
  const [searchBy, setSearchBy] = React.useState("Client");
  const [searchKey, setSearchKey] = React.useState("");
  const [totalRows, setTotalRows] = React.useState(0);
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");
  const [settingList, setSettingList] = React.useState({});
  const _companyHierarchy = localStorage.getItem("companyHierarchy");

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getQuery = (count) => {
    let whereConditions1 = "AND DATE(cert.nextDueDate) <= CURDATE()";
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        Client: "cust.companyName",
        "Instrument Name": "ins.instrumentName",
        "Certificate Number": "cert.certificateNumber",
        "ULR Number": "cert.ULRNo",
      }[searchBy];
      whereConditions = `and ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " and ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` cert.nextDueDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (props.isCount) {
      const today = new Date().toISOString().slice(0, 10);
      whereConditions += (whereConditions ? " and " : " and ") + `DATE(cert.nextDueDate) = '${today}'`;
}

    if (whereConditions == "") {
      whereConditions = whereConditions1 + whereConditions;
    }

    if (userType == "3") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (cert.clientId=${_clientId})`
          : `and (cert.clientId=${_clientId})`;
    }

    if (userType === "3" && _companyHierarchy && _companyHierarchy !== "null") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (companyHierarchy='${_companyHierarchy}')`
          : `where (companyHierarchy='${_companyHierarchy}')`;
    }

    if ( companyHierarchy && companyHierarchy !== "null") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and (companyHierarchy='${companyHierarchy}')`
          : `where (companyHierarchy='${companyHierarchy}')`;
    }

    if (whereConditions == "") {
      whereConditions += ` and cert.status != -1`;
    } else {
      whereConditions += ` and cert.status != -1 `;
    }

    if (company?.length == 1 ) {
      whereConditions +=
        whereConditions.length > 0 ? ` and cert.clientId = ${company?.join(",")}`:` and cert.clientId = ${company?.join(",")}`;
    }


    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT cert.clientId, cert.companyName, cert.certificateNumber, cert.ULRNo, cert.instrumentName, REPLACE(REPLACE(cert.ranges, '#', ''), '|', ' to ') AS ranges, REPLACE(cert.lc, '#', ' ') AS lc, cert.DUCID, cert.make, cert.model, cert.calibrationDate, cert.nextDueDate, cert.id, cert.status, cert.dcNumber, cert.identificationNo, cert.dateOfIssue, cert.srnNo, cert.companyHierarchy FROM certificatesView AS cert 
      WHERE cert.nextDueDate IS NOT NULL ${whereConditions} ORDER BY cert.id DESC ${pagination_settings}`,
    };

    if (count)
      // data.query = `select count(*) as no_of_rows from certificates as cert left join clients as cust on cert.clientId = cust.id left join instruments as ins on cert.instrumentId = ins.id  ${whereConditions}`;
    data.query = `SELECT COUNT(*) AS no_of_rows FROM certificatesView AS cert WHERE cert.nextDueDate IS NOT NULL ${whereConditions} ORDER BY cert.id DESC`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchCertificates() {
    getTotalRows();
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        const modifyData = res.data.map(row => {
          return {
            ...row,
            calibrationDate: moment(row.calibrationDate).format("DD-MM-YYYY"),
            nextDueDate: moment(row.nextDueDate).format("DD-MM-YYYY")
          };
        });

        setCertificate([...modifyData]);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
 
  // api calls
  function fetchCertificateReadings() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `datasheetStaticReadings`)
      .then(async (res) => {
        setCertReadings(res.data);
      })
      .catch((err) => {
        console.log("cert data fetching error: ", err);
      });
  }

  const initializeCertTable = () => {
    for (let i = 0; i < certificate.length; i++) {
      if (certificate[i].cert_dateOfIssue != null) {
        certificate[i]["isProgress"] = 90;
      } else if (
        certReadings.some((data) => data.datasheetId == certificate[i].id)
      ) {
        certificate[i]["isProgress"] = 70;
      } else {
        certificate[i]["isProgress"] = 30;
      }
    }
    setFinalData(certificate);
  };

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    setSearched(true);
    fetchCertificates();
    getTotalRows();
  };

  const clearSearch = () => {
    setSearchBy("Client");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
  };

  function fetchSettings() {
    axiosWithToken
      .get(BASE_URL + `settings?_where=(status,eq,1)`)
      .then((res) => {
         let settings = res.data;
        let _settingList = {};
        settings.forEach((setting) => {
          _settingList[setting.keyName] = setting.value;
        });
        setSettingList(_settingList);
      })
      .catch((err) => {
        console.error("Error fetching setting:", err);
        if (err.message !== "request_aborted") {
          toast.error(
            err.message
          );
        }
      });
  }

  const getCustomer = () => {
    let url = BASE_URL;
    let endPoint = _userType == "3"
      ? `clients/${_clientId}?_where=(status,eq,1)&_fields=id,companyName`
      : `clients?_where=(status,eq,1)&_fields=id,companyName`;
  
    axiosWithToken
      .get(url + endPoint)
      .then((res) => {
        setClientList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const fetchClientSettingsData = async () => {
    try {
      const sqlQuery = {
        query: `SELECT keyName, value,companyHierarchy  FROM clientSettings WHERE clientId IN (${company?.join(
          ","
        )})`,
      };

      const res = await axiosWithToken.post(BASE_URL + `dynamic`, sqlQuery, {
        headers: { requestType: "fetchData" },
      });

      const response = res.data;

      // Use map to extract companyHierarchy and filter out empty strings and null values
      const HierarchyArray = response
        .map((ele) => ele.companyHierarchy)
        .filter((hierarchy) => hierarchy !== null && hierarchy !== "");

      // Use Set to ensure uniqueness and then convert back to array
      const uniqueArray = [...new Set(HierarchyArray)];

      // Split each element by "," and create an array of arrays
      const resultArray = uniqueArray.reduce((acc, element) => {
        const splitValues = element.split(",");
        splitValues.forEach((value, index) => {
          if (!acc[index]) {
            acc[index] = [];
          }
          acc[index].push(value);
        });
        return acc;
      }, []);

      setHierarchyResultArray(resultArray);
      // Remove duplicates within each inner array
      const uniqueData = resultArray.map((innerArray) => [
        ...new Set(innerArray),
      ]);
      setCompanyHierarchyOptions(uniqueData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompanyHierarchyChange = (label, value) => {
    setSelectedCompanyHierarchy((prev) => {
      const updatedHierarchy = {
        ...prev,
        [label]: value,
      };

      const newCompanyHierarchy = Object.values(updatedHierarchy).join(",");
      setCompanyHierarchy(newCompanyHierarchy);

      return updatedHierarchy;
    });
  };

  const updateCompanyHierarchyOptions = () => {
    const selectedDivision = selectedCompanyHierarchy?.Division || "default";

    const allIndices = hierarchyResultArray[0]
      ?.map((item, index) => (item === selectedDivision ? index : null))
      .filter((index) => index !== null);

    const result = hierarchyResultArray.map((el, i) =>
      i === 0 ? el : el.filter((item, ind) => allIndices.includes(ind))
    );

    const uniqueData = result.map((innerArray) => [...new Set(innerArray)]);
    setCompanyHierarchyOptions(uniqueData);
  };

  useEffect(() => {
    updateCompanyHierarchyOptions();
  }, [selectedCompanyHierarchy?.Division]);

  useEffect(() => {
    if (company) fetchClientSettingsData();
  }, [company]);


  useEffect(() => {
    fetchCertificateReadings();
    fetchSettings()
    getCustomer()
  }, []);

  useEffect(() => {
    getTotalRows();
    fetchCertificates();
  }, [rowsPerPage, page]);

  useEffect(() => {
    initializeCertTable();
  }, [certificate]);

  useEffect(() => {
    if(searched==false){
      fetchCertificates();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  if (enableDisable.length > 0) {
    var result = enableDisable
      .map(function (item) {
        if (item) {
          let [id, closed] = item.split(",");
          return closed === "true" ? id : null;
        }
      })
      .filter(function (item) {
        return item != undefined && item != null;
      });
  }

  return (
    <ListWrap>
         <Grid
          container
          spacing={1}
          justifyContent={"start"}
          alignItems={"center"}
        >
       <Grid item xs={12} sm={12} md={3} lg={3} textAlign={"left"}>
  <Autocomplete
    size="small"
    className="Listinputcss"
    id="combo-box-demo"
    options={clientList}
    disableCloseOnSelect
    multiple
    value={clientList.filter((option) => company.includes(option.id))}
    onChange={(event, value) => {
      const selectedIds = value.map((e) => e.id);
      setCompany(selectedIds);
      setSearched(false);
    }}
    getOptionLabel={(option) => option.companyName} // Use companyName as the display label
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox
          style={{ marginRight: 8 }}
          checked={company.includes(option.id)}
        />
        {option.companyName}
      </li>
    )}
    renderInput={(params) => <TextField {...params} label="Client" />}
  />
</Grid>

           {settingList["companyHierarchy"] &&
                  _userType !== "3" &&
                  company?.length < 2 && (
                    <>
                      {/* <Grid container spacing={2}> */}
                      {settingList["companyHierarchy"]
                        ?.split(",")
                        ?.map((labelName, index) => (
                          <Grid item xs={6} sm={3} md={2} lg={2} key={index}>
                            <Autocomplete
                              className="Listinputcss"
                              onChange={(event, newValue) => {
                                handleCompanyHierarchyChange(labelName, newValue);
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                return filtered;
                              }}
                              // disabled={params.id != 0}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              id="uncertinity_edit_condcoeff"
                              options={
                                companyHierarchyOptions?.[index]?.includes("default")
                                  ? companyHierarchyOptions?.[index]
                                  : companyHierarchyOptions?.[index]?.concat("default")
                              }
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option}</li>
                              )}
                              freeSolo={false}
                              renderInput={(params) => (
                                <TextField {...params} label={labelName} />
                              )}
                              size="small"
                            />
                          </Grid>
                        ))}
                      {/* </Grid> */}
                      {/* <br /> */}
                    </>
                  )}
          <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
              className="Listinputcss"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFrom(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
            </Grid>
            <Grid item xs={6} sm={3} md={1} lg={1.5} textAlign={"left"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
              className="Listinputcss"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setTo(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2} textAlign={"left"}>
          <Autocomplete
            sx={{
              m: 0,
              minWidth: 180,
              marginLeft: "10px",
            }}
            size="small"
            id="combo-box-demo"
            value={searchBy}
            className="Listinputcss"
            options={
              _userType != 3
                ? [
                    { key: "customerName", label: "Client" },
                    { key: "instrumentName", label: "Instrument Name" },
                    { key: "certificateNumber", label: "Certificate Number" },
                    { key: "ULRNo", label: "ULR Number" },
                  ]
                : [{ key: "instrumentName", label: "Instrument Name" }]
            }
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
              setSearched(false);
            }}
          />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2} textAlign={"left"}>
          <TextField
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            className="Listinputcss"
            value={searchKey}
            variant="outlined"
            inputProps={{
              autoComplete: 'off',
            }}
            onChange={(e) => {
              setSearchKey(e.target.value);
              setSearched(false);
            }}
            sx={{ marginLeft: "10px" }}
          />
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            color={searched ? "error" : "primary"}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2} textAlign={"left"}>
          <ExcelDownload
            finalData={finalData}
            notNeededColumn={notNeededColumn}
          />
          </Grid>            
          </Grid>
        
        
      <Box sx={{ width: "100%", overflow: "hidden", marginTop:"5px" }}>   
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) =>
               !settingList["companyHierarchy"] &&
               column.id === "companyHierarchy" ? null :
              (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData?.length > 0 ? (
              finalData.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    {settingList["companyHierarchy"] && (
                      <>
                          <TableCell>{row.companyHierarchy?.split(",")?.[0]}</TableCell>
                          <TableCell>{row.companyHierarchy?.split(",")?.[1]}</TableCell>
                      </>
                    )}
                    <TableCell>{row.certificateNumber}</TableCell>
                    <TableCell>{row.ULRNo}</TableCell>
                    <TableCell>{row.instrumentName}</TableCell>
                    <TableCell>
                      {row.ranges
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", "")}
                    </TableCell>
                    <TableCell>
                      {row.lc
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", "")}
                    </TableCell>
                    <TableCell>{row.DUCID}</TableCell>
                    <TableCell>{row.make}</TableCell>
                    <TableCell>{row.model}</TableCell>
                    <TableCell>
                      {row.calibrationDate}
                    </TableCell>
                    <TableCell>
                      {row.nextDueDate}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                 <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )} 
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 30, 50]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </TableContainer>
      </Box>
    </ListWrap>
  );
}
