import moment from "moment";
import { BASE_URL, MAX_COLS } from "../../../global";
import { toast } from "react-toastify";
import { prepareTypeBValues, withUnit } from "./utils";
import { clearPreviousReadings, getCustomColumnCount } from "../helper";
import { updateSRFAnalytics } from "../../../utils/analytics/srfAnalytics";
import { processDatasheet } from "./ObservedReadings/helper";
import { onSubmitOpinionAndAnalysis } from "./opinionAndAnalysisTable";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import { processReports } from "../../uncertainties/process";

export const processDatasheetReadings = async (
  datasheetContextValue,
  _staticReadingRows = null,
) => {
  // 0. extract the context

  const {
    state: {
      staticTables,
      staticReadingRows,
      certificateStaticTables,
      datasheetDetails,
      standardRanges,
      selectedStandardMasters,
      referenceData,
      settings,
      currentOperationState,
    },
  } = datasheetContextValue;
  // disable all alerts
  let originalAlert = window.alert;
  let originalConfirm = window.confirm;
  if (currentOperationState == 1) {
    originalAlert = window.alert;
    originalConfirm = window.confirm;
    window.alert = () => {};
    window.confirm = () => true;
  }

  // 1. process data before submit
  let latestStaticReadingRows = _staticReadingRows
    ? _staticReadingRows
    : staticReadingRows;
  let updatedStaticReadingRows = await processDatasheet(
    staticTables,
    latestStaticReadingRows,
    settings?.["datasheetObservedReadingFallbackValue"],
    certificateStaticTables,
    datasheetDetails,
    standardRanges,
    selectedStandardMasters,
    referenceData,
  );
  if (!updatedStaticReadingRows) {
    return null;
  }

  // reenable original alerts
  if (currentOperationState == 1) {
    window.alert = originalAlert;
    window.confirm = originalConfirm;
  }

  return updatedStaticReadingRows;
};

const getGroupedReadingDetails = (
  table,
  readingRows,
  readingsGroupByDetails,
  firstReadingId,
) => {
  let rangeCol = table["defaultConfiguration"]["rangeCol"];
  if (!rangeCol) return undefined;

  let groupByUnit = {};
  let groupByHeader = {};
  let tmp = [];
  let currentUnit = null;
  let currentHeader = "undefined";
  let rowIds = [];

  readingRows.forEach((row, rowIndex) => {
    if (row?.tableId === table.id) {
      let value = row[rangeCol];
      if (value && value !== "NA") {
        value = value.replaceAll("$", "");

        if (row["c1"]?.includes("_rh_")) {
          groupByUnit[currentUnit] = [
            ...(groupByUnit[currentUnit] || []),
            ...tmp,
          ];
          Object.keys(groupByUnit).forEach((unit) => {
            groupByHeader[currentHeader] = {
              ...groupByHeader[currentHeader],
              [unit]: [
                ...(groupByHeader[currentHeader]?.[unit] || []),
                ...groupByUnit[unit],
              ],
            };
          });
          groupByHeader[currentHeader]["rowIds"] = [
            ...(groupByHeader[currentHeader]["rowIds"] || []),
            ...rowIds,
          ];

          tmp = [];
          groupByUnit = {};
          rowIds = [];

          currentHeader =
            readingsGroupByDetails?.valuesPerRow[rowIndex] || "undefined";
        } else if (value?.includes("_unit_")) {
          let newUnit = value.split("_unit_")[1];
          if (currentUnit && newUnit != currentUnit) {
            groupByUnit[currentUnit] = [
              ...(groupByUnit[currentUnit] || []),
              ...tmp,
            ];
            tmp = [];
          }
          currentUnit = newUnit;
        } else {
          rowIds.push(firstReadingId + rowIndex);
          value = Number(value);
          if (isNaN(value)) return;
          tmp.push(value);
        }
      }
    }
  });
  groupByUnit[currentUnit] = [
    ...(groupByHeader[currentHeader]?.[currentUnit] || []),
    ...tmp,
  ];
  Object.keys(groupByUnit).forEach((unit) => {
    groupByHeader[currentHeader] = {
      ...groupByHeader[currentHeader],
      [unit]: [
        ...(groupByHeader[currentHeader]?.[unit] || []),
        ...groupByUnit[unit],
      ],
    };
  });
  groupByHeader[currentHeader]["rowIds"] = [
    ...(groupByHeader[currentHeader]["rowIds"] || []),
    ...rowIds,
  ];

  // parse group by table name and columms
  let columns = table?.defaultConfiguration.readingGroupByConfig?.split(",");
  let tableName = columns?.[0]?.split(".")[0];
  columns = columns?.map((column) => column.split(".")[1]);

  let groupedDsReadingDetails = {
    config: {
      tableName: tableName,
      columns: columns,
    },
    rangeValues: groupByHeader,
  };

  return groupedDsReadingDetails;
};

const checkSupportiveMaster = (
  updatedStaticReadingRows,
  selectedSupportiveRanges,
  supportiveStandardMasterArray,
  additionalAttr = {},
) => {
  let isSupportiveMasterMissing = false;
  if (supportiveStandardMasterArray.length > 0) {
    for (let idx = 0; idx < updatedStaticReadingRows.length; idx++) {
      const updatedReadingRow = updatedStaticReadingRows[idx];
      const supportiveRanges = selectedSupportiveRanges[idx] ?? null;

      // Check conditions for notselect supportiverange
      if (
        (supportiveRanges === null || supportiveRanges === "") &&
        !updatedReadingRow["c1"]?.includes("_unit_") &&
        !updatedReadingRow["c1"]?.includes("_rh_")
      ) {
        isSupportiveMasterMissing = true;
      }
    }
  }

  if (
    isSupportiveMasterMissing &&
    additionalAttr?.origin === "process" &&
    !window.confirm(
      "Supportive Master is/are not added for rows? Do you want to save?",
    )
  ) {
    return false;
  }
  return true;
};

export const validateAndSubmitData = async (
  datasheetContextValue,
  showAmendmentModal,
  additionalAttr = {},
  amendmentStatus
) => {
  // 0. extract the context
  const {
    state: {
      datasheetDetails,
      certificateDetails,
      staticTables,
      staticReadingRows,
      certificateStaticTables,
      readingsGroupByDetails,
      selectedStandardIds,
      selectedSupportiveRanges,
      indicators,
      indicators_complianceStatus,
      analysisSelectedOption,
      settings,
      instrumentDetails,
      DatasheetReadingsAdditionalInfo,
      srfDetails,
      supportiveStandardMasterArray,
      referenceData,
      datasheetImages,
      expiredStandardNames,
    },
  } = datasheetContextValue;

  let stepsCompleted = 0;

  // 1. validate the data
  if (
    !datasheetDetails?.calibrationDate ||
    !datasheetDetails?.startHumidity ||
    !datasheetDetails?.startTemp ||
    expiredStandardNames?.length > 0
  ) {
    let alerts = [];
    if (!datasheetDetails?.calibrationDate)
      alerts.push("Please add the calibration date!");
    if (!datasheetDetails?.startHumidity)
      alerts.push("Please add the startHumidity!");
    if (!datasheetDetails?.startTemp) alerts.push("Please add the startTemp!");

    if (expiredStandardNames?.length > 0) {
      alerts.push(
        `Below Masters are expired:\n${expiredStandardNames.join(", \n")}`,
      );
    }
    alert(alerts.join("\n"));
    return { status: false, stepsCompleted: stepsCompleted };
  }

  if (
    !checkSupportiveMaster(
      staticReadingRows,
      selectedSupportiveRanges,
      supportiveStandardMasterArray,
      additionalAttr,
    )
  ) {
    return { status: false, stepsCompleted: stepsCompleted };
  }

  stepsCompleted = 1;

  // 2. take amendmend if required
  if (
    indicators?.isShowAmendment &&
    datasheetDetails?.revisionNumber > -1 &&
    !datasheetDetails?.reasonForAmendment && 
    amendmentStatus
  ) {
    showAmendmentModal();
    return { status: false, stepsCompleted: stepsCompleted };
  }
  stepsCompleted = 2;

  try {
    let res = null;
    // 3 submit datasheet details
    console.log("submitting datasheet details");
    res = await submitDatasheet(
      datasheetDetails?.id,
      datasheetDetails,
      staticReadingRows,
      readingsGroupByDetails,
      selectedStandardIds,
      indicators,
      indicators_complianceStatus,
      instrumentDetails,
      referenceData,
      datasheetImages,
      certificateDetails
    );
    if (!res) {
      alert("Failed while submitting datasheet details, please try again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 3;

    // 4 submit certificate details
    console.log("submitting certificate details");
    res = await submitCertificate(
      datasheetDetails?.id,
      datasheetDetails,
      certificateDetails,
      selectedStandardIds,
      indicators_complianceStatus,
      instrumentDetails,
    );
    if (!res) {
      alert("Failed while submitting certificate details, please try again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 4;

    // 5 submit srf instrument details
    console.log("submitting srf instrument details");
    res = await submitSrfIntrument(datasheetDetails?.id, datasheetDetails);
    if (!res) {
      alert(
        "Failed while submitting srf instrument details, please try again.",
      );
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 5;

    // 6 submit srf details
    console.log("submitting srf details");
    res = await submitSRF(datasheetDetails, srfDetails);
    if (!res) {
      alert("Failed while submitting srf details, please try again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 6;

    // 7 submit datasheet readings
    console.log("submitting datasheet readings");
    let [status, _res] = await submitDatasheetReadings(
      datasheetDetails?.id,
      datasheetDetails,
      staticReadingRows,
      staticTables,
      selectedSupportiveRanges,
    );
    if (!status) {
      alert("Failed while submitting datasheet readings, please save again.");
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 7;

    // 8 calculate uncertainty
    let firstReadingId = _res?.data?.insertId;
    if (additionalAttr?.isSubmitUncertainty) {
      console.log("Calculating uncertainty values");
      try {
        for (let i = 0; i < staticTables.length; i++) {
          if (!staticTables[i].isUncertainty) continue;

          let groupedReadingRangeValues = getGroupedReadingDetails(
            staticTables[i],
            staticReadingRows,
            readingsGroupByDetails,
            firstReadingId,
          );
          res = await processReports(
            null,
            staticTables[i].id,
            datasheetDetails?.id,
            datasheetDetails?.instrumentId,
            groupedReadingRangeValues,
            null,
            "origin:fromDatasheet",
          );
        }
        console.log("uncertainty values calculated successfully");
      } catch (error) {
        console.error(
          "Failed while calculating uncertainty values, error: ",
          error,
        );
        return { status: false, stepsCompleted: stepsCompleted };
      }
    }
    stepsCompleted = 8;

    // 9 submit AdditionalInfo of Datasheet readings
    res = await submitDatasheetReadingsAdditionalInfo(
      DatasheetReadingsAdditionalInfo,
      datasheetDetails?.id,
    );
    if (!res) {
      alert(
        "Failed while submitting AdditionalInfo of Datasheet readings, please try again.",
      );
      return { status: false, stepsCompleted: stepsCompleted };
    }
    stepsCompleted = 9;

    // 10 Submit OpinionAndAnalysisTable
    if (settings?.["Opinion And Analysis Table"] === "true") {
      console.log("Submitting Opinion And Analysis Data");
      res = await onSubmitOpinionAndAnalysis(
        analysisSelectedOption,
        datasheetDetails?.id,
      );

      if (!res) {
        alert(
          "Failed while submitting opinion and analysis data, please try again.",
        );
        return { status: false, stepsCompleted: stepsCompleted };
      }
    }
    stepsCompleted = 10;
  } catch (error) {
    console.error(
      "[ERROR] Error occured while submitting datasheet data, error: ",
      error,
    );
    return { status: false, stepsCompleted: stepsCompleted };
  }

  if (additionalAttr?.origin !== "process") {
    toast.success("Datasheet updated successfully.");
  }
  console.log("datasheet updated successfully.");
  return { status: true, stepsCompleted: stepsCompleted };
};

export const submitDatasheet = async (
  datasheetId,
  datasheetDetails,
  updatedStaticReadingRows,
  readingsGroupByDetails,
  selectedStandardIds,
  indicators,
  indicators_complianceStatus,
  instrumentDetails,
  referenceData,
  datasheetImages,
  certificateDetails
) => {
  try {
    const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
    let designation = localStorage.getItem("designation") || "";
    let data = {
      calibrationDate: datasheetDetails?.calibrationDate
        ? moment(datasheetDetails?.calibrationDate).format(
            "YYYY-MM-DD HH:mm:ss",
          )
        : null,
      endCalibrationDate: datasheetDetails?.endCalibrationDate
        ? moment(datasheetDetails?.endCalibrationDate).format(
            "YYYY-MM-DD HH:mm:ss",
          )
        : null,
      nextDueDate: datasheetDetails?.nextDueDate
        ? moment(datasheetDetails?.nextDueDate).format("YYYY-MM-DD")
        : null,
      receiptDate: datasheetDetails?.receiptDate
        ? moment(datasheetDetails?.receiptDate).format("YYYY-MM-DD")
        : null,
      DCDate: datasheetDetails?.dcDate
        ? moment(datasheetDetails?.dcDate).format("YYYY-MM-DD")
        : null,
      dateOfIssue: datasheetDetails?.dateOfIssue
        ? moment(datasheetDetails?.dateOfIssue).format("YYYY-MM-DD")
        : null,
      identificationNo: datasheetDetails?.identificationNo,
      make: datasheetDetails?.make,
      ranges: datasheetDetails?.ranges,
      lc: datasheetDetails?.lc,
      DUCID: datasheetDetails?.DUCID,
      mfgNo: datasheetDetails?.mfgNo,
      startTemp: datasheetDetails?.startTemp,
      middleTemp: datasheetDetails?.middleTemp,
      endTemp: datasheetDetails?.endTemp,
      startLiquidTemp: datasheetDetails?.startLiquidTemp,
      middleLiquidTemp: datasheetDetails?.middleLiquidTemp,
      endLiquidTemp: datasheetDetails?.endLiquidTemp,
      startHumidity: datasheetDetails?.startHumidity,
      middleHumidity: datasheetDetails?.middleHumidity,
      endHumidity: datasheetDetails?.endHumidity,
      configuration: JSON.stringify({
        ...datasheetDetails?.configuration,
        remark:
          datasheetDetails?.configuration?.remark ||
          datasheetDetails?.defaultReamrk ||
          instrumentDetails?.instrumentRemark,
      }),
      totalReadings: updatedStaticReadingRows?.length || 0,
      tableDisplayStatus: datasheetDetails.tableDisplayStatus,
      calibratedby: user[0],
      designation: designation,
      revisionNumber:  certificateDetails?.approvedby ? Number(datasheetDetails?.revisionNumber) + 1 : Number(datasheetDetails?.revisionNumber) ,
      requestedname: referenceData?.srfInstruments?.requestedDucName
        ? referenceData?.srfInstruments?.requestedDucName
        : datasheetDetails?.requestedname,
      additionalStandardIds: selectedStandardIds?.join(","),
      calibrationReason: datasheetDetails?.calibrationReason,
      complianceStatus: indicators_complianceStatus,
      calProcRefNo: datasheetDetails?.calProcRefNo,
      isaStandard: datasheetDetails?.isaStandard,
      amendment:
        indicators?.isShowAmendment && datasheetDetails?.revisionNumber > -1
          ? datasheetDetails?.amendmentHistory?.length
            ? datasheetDetails?.amendmentHistory +
              "\n" +
              datasheetDetails?.reasonForAmendment +
              "," +
              moment().format("DD/MM/YYYY")
            : datasheetDetails?.reasonForAmendment +
              "," +
              moment().format("DD/MM/YYYY")
          : "",
      startPressure: datasheetDetails?.startPressure,
      middilePressure: datasheetDetails?.middilePressure,
      datasheetImages: datasheetImages?.join(",") || null,
      endPressure: datasheetDetails?.endPressure,
      extraColumns: (() => {
        let tmp = {};
        datasheetDetails?.extraColumns.forEach((e) => (tmp[e[0]] = e[1]));
        return JSON.stringify(tmp);
      })(),
      readingsGroupByDetails: JSON.stringify(readingsGroupByDetails),
      datasheetApprovedBy: null,
    };

    let res = await axiosWithToken.patch(
      BASE_URL + `datasheets/${datasheetId}`,
      data,
    );
    return res ? true : false;
  } catch (error) {
    console.error(
      `[ERROR] Failed to submit datasheet details, error: ${error}`,
    );
    return false;
  }
};

export const submitCertificate = async (
  datasheetId,
  datasheetDetails,
  certificateDetails,
  selectedStandardIds,
  indicators_complianceStatus,
  instrumentDetails,
) => {
  try {
    let data = {
      calibrationDate: datasheetDetails?.calibrationDate
        ? moment(datasheetDetails?.calibrationDate).format("YYYY-MM-DD")
        : null,
      nextDueDate: datasheetDetails?.nextDueDate
        ? moment(datasheetDetails?.nextDueDate).format("YYYY-MM-DD")
        : null,
      receiptDate: datasheetDetails?.receiptDate
        ? moment(datasheetDetails?.receiptDate).format("YYYY-MM-DD")
        : null,
      DCDate: datasheetDetails?.dcDate
        ? moment(datasheetDetails?.dcDate).format("YYYY-MM-DD")
        : null,
      additionalStandardIds: selectedStandardIds?.join(","),
      identificationNo: datasheetDetails?.identificationNo,
      dateOfIssue: datasheetDetails?.dateOfIssue
        ? moment(datasheetDetails?.dateOfIssue).format("YYYY-MM-DD")
        : null,
      certificateNumber: certificateDetails?.certificateNumber,
      serviceReqNumber: certificateDetails?.serviceReqNumber,
      ULRNo: certificateDetails?.ULRNo,
      make: datasheetDetails?.make,
      ranges: datasheetDetails?.ranges,
      lc: datasheetDetails?.lc,
      DUCID: datasheetDetails?.DUCID,
      mfgNo: datasheetDetails?.mfgNo,
      startTemp: datasheetDetails?.startTemp,
      endTemp: datasheetDetails?.endTemp,
      startHumidity: datasheetDetails?.startHumidity,
      endHumidity: datasheetDetails?.endHumidity,
      calibrationReason: datasheetDetails?.calibrationReason,
      complianceStatus: indicators_complianceStatus,
      tempDiff: String(
        Math.abs(
          Number(datasheetDetails?.startTemp) -
            Number(datasheetDetails?.endTemp),
        ).toFixed(2),
      ),
      humDiff: String(
        Math.abs(
          Number(datasheetDetails?.startHumidity) -
            Number(datasheetDetails?.endHumidity),
        ).toFixed(2),
      ),
      configuration: JSON.stringify({
        remark:
          datasheetDetails?.configuration?.remark ||
          datasheetDetails?.defaultReamrk ||
          instrumentDetails?.instrumentRemark,
      }),
      tableDisplayStatus: datasheetDetails.tableDisplayStatus,
      approvedby: null,
      reviewedBy: null,
      signedBy: null,
      rejectedBy: null,
      witnessBy: null,
      approvedDate: null,
      reviewedDate: null,
      witnessDate: null,
      signedDate: null,
    };

    let res = await axiosWithToken.patch(
      BASE_URL + `certificates/${datasheetId}`,
      data,
    );
    if (res) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(
      `[ERROR] Failed to submit certificate details, error:  ${error}`,
    );
    return false;
  }
};

export const submitSrfIntrument = async (datasheetId, datasheetDetails) => {
  let data = {
    make: datasheetDetails?.make,
    ranges: datasheetDetails?.ranges,
    lc: datasheetDetails?.lc,
    DUCID: datasheetDetails?.DUCID,
    serialNumber: datasheetDetails?.serialNo,
    model: datasheetDetails?.models,
  };

  try {
    let res = await axiosWithToken.patch(
      BASE_URL + `srfInstruments/${datasheetId}`,
      data,
    );
    if (res) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error(
      `[ERROR] Failed to submit srf instrument details, error: ${err}`,
    );
    return false;
  }
};

export const submitSRF = async (datasheetDetails, srfDetails) => {
  try {
    let row = {
      receiptDate: datasheetDetails?.receiptDate
        ? moment(datasheetDetails?.receiptDate).format("YYYY-MM-DD")
        : null,
      dcNumber: datasheetDetails?.dcNo,
      dcDate: datasheetDetails?.dcDate
        ? moment(datasheetDetails?.dcDate).format("YYYY-MM-DD")
        : null,
      // address: address,
      poNumber: datasheetDetails?.poNo,
      poDate: datasheetDetails?.poDate
        ? moment(datasheetDetails?.poDate).format("YYYY-MM-DD")
        : null,
    };
    let res = await axiosWithToken.patch(
      BASE_URL + `srfs/${srfDetails?.srfId}`,
      row,
    );

    if (res) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error while updating createSRFAnalytics, error: ", error);
    return false;
  }
};

export const submitDatasheetReadings = async (
  datasheetId,
  datasheetDetails,
  updatedStaticReadingRows,
  staticTables,
  selectedSupportiveRanges,
) => {
  try {
    const historyData =
      JSON.parse(sessionStorage.getItem("historicalTable")) || false;

    let updatedReadingRows = updatedStaticReadingRows;

    // create list of reading objects
    let readings = [];
    let datasheetReadingIds = [];
    let indx = 0;
    let currentRowIndex = -1;

    // sorting to group same table records together
    updatedReadingRows = updatedReadingRows.sort(
      (a, b) => a.tableId - b.tableId,
    );

    updatedReadingRows.forEach((updatedReadingRow, idx) => {
      let row = { ...updatedReadingRow };

      let customColumnCount = getCustomColumnCount(
        updatedReadingRow["tableId"],
        null,
        staticTables,
        "static",
      );
      if (String(updatedReadingRow["c1"]).includes("_unit_"))
        currentRowIndex = idx;

      if (!row["c1"].includes("_rh_")) {
        // skip row headers
        for (let j = 0; j < customColumnCount; j++) {
          row[`c${j + 1}`] = withUnit(
            updatedReadingRow[`c${j + 1}`] !== undefined ||
              updatedReadingRow[`c${j + 1}`] !== null
              ? updatedReadingRow[`c${j + 1}`]
              : null,
            currentRowIndex > -1
              ? updatedReadingRows[currentRowIndex][`c${j + 1}`]
              : "",
          );
        }
      }

      for (let j = customColumnCount; j < MAX_COLS; j++) {
        row[`c${j + 1}`] = null;
      }

      // set uncertainty unit only
      if (String(updatedReadingRow["uncertainty"]).includes("_unit_")) {
        row["uncertainty"] = updatedReadingRow["uncertainty"]?.replaceAll(
          "$",
          "",
        );
      } else {
        row["uncertainty"] =
          updatedReadingRow["uncertainty"] !== undefined ||
          updatedReadingRow["uncertainty"] !== null
            ? updatedReadingRow["uncertainty"]
            : null;

        row["uncertainty"] = String(row["uncertainty"])?.replaceAll("$", "");
        row["uncertainty"] = withUnit(
          row["uncertainty"],
          currentRowIndex > -1
            ? updatedReadingRows[currentRowIndex]["uncertainty"]
            : "",
        );
      }

      // set standard range ids
      row["standardRanges"] = updatedReadingRow["standardRanges"]
        ? updatedReadingRow["standardRanges"]
            ?.split(",")
            ?.filter((e) => e)
            ?.join(",")
        : updatedReadingRow["standardRanges"];

      // resolve typeB values
      let table = staticTables.find((table) => table.id == row["tableId"]);

      if (table?.defaultConfiguration?.typeb?.relations) {
        row["typeBValues"] = prepareTypeBValues(
          table?.defaultConfiguration.typeb?.relations,
          row,
        );
      } else {
        row["typeBValues"] = null;
      }

      updatedReadingRow["id"] != 0 &&
        datasheetReadingIds.push(updatedReadingRow["id"]);

      row["supportiveRanges"] =
        selectedSupportiveRanges[indx] !== undefined &&
        selectedSupportiveRanges[indx] !== null
          ? selectedSupportiveRanges[indx]
          : null;
      indx += 1;

      // set compliance status col if not present
      row["complianceStatus"] =
        row["complianceStatus"] != undefined ? row["complianceStatus"] : 1;

      // remove unwanted keys from row
      delete row["id"];
      delete row["lastModified"];
      readings.push(row);
    });

    let st = {};
    staticTables.map((e) => (st[e.id] = e));

    let res = null;
    // update datasheet readings
    let datasheetProgress = 25;
    // submit datasheet readings with max retry of 3
    let needToRetry = false,
      retryCount = 0;
    do {
      if (readings.length > 0) {
        try {
          res = await axiosWithToken.post(
            BASE_URL + `datasheets/readings/${datasheetId}`,
            { readings, historyData },
          );
          if (res.status == 201) {
            datasheetProgress = 60;
            needToRetry = false;
          } else {
            needToRetry = true;
            retryCount += 1;

            console.error(
              `Failed while submitting datasheet readings: status: ${res.status}, response: ${res.data} `,
            );
            console.error(`retrying: ${retryCount} retry`);
          }
        } catch (err) {
          needToRetry = true;
          retryCount += 1;
          console.error("Failed while submitting datasheet readings: ", err);
          console.error(`retrying: ${retryCount} retry`);
        }
      }
    } while (needToRetry && retryCount < 3);

    // update analytics
    await updateSRFAnalytics([datasheetId], datasheetProgress);

    return [true, res];
  } catch (err) {
    console.error(
      `[ERROR] Failed to submit datasheet readings (or associated function error), error: ${err}`,
    );
    return false;
  }
};

export const submitDatasheetReadingsAdditionalInfo = async (
  DatasheetReadingsAdditionalInfo,
  datasheetId,
) => {
  try {
    // Define the normalizeArray function
    function normalizeArray(array, keysToEnsure) {
      // Iterate over each object in the array
      array?.forEach((obj) => {
        // Iterate over the keys that should be present
        keysToEnsure?.forEach((key) => {
          // If the key is not present in the object, add it with a value of null
          if (!(key in obj)) {
            obj[key] = null;
          }
        });
      });
      return array;
    }

    // Find all keys present in DatasheetReadingsAdditionalInfo array
    const allKeys = DatasheetReadingsAdditionalInfo?.reduce((keys, obj) => {
      Object?.keys(obj)?.forEach((key) => {
        if (!keys?.includes(key)) {
          keys?.push(key);
        }
      });
      return keys;
    }, []);

    // Exclude any keys that are not needed (e.g., 'id', 'status', 'lastModified', etc.)
    const keysToEnsure = allKeys.filter(
      (key) => key !== "id" && key !== "status" && key !== "lastModified",
    );

    // Normalize the data
    const normalizedData = normalizeArray(
      DatasheetReadingsAdditionalInfo,
      keysToEnsure,
    );

    // Delete existing entries if there are objects with id
    if (normalizedData?.length > 0) {
      const deleteQuery = {
        query: `DELETE FROM datasheetReadingsAdditionalInfo WHERE datasheetReadingId=${datasheetId}`,
      };
      await axiosWithToken.post(BASE_URL + `dynamic`, deleteQuery);
    }

    // Insert new entries without id
    if (normalizedData?.length > 0) {
      await axiosWithToken.post(
        BASE_URL + "datasheetReadingsAdditionalInfo/bulk",
        normalizedData,
      );
      toast.success("Datasheet Readings Additional Info Added Successfully!");
    }

    return true;
  } catch (error) {
    console.error(
      `[ERROR] Failed to submit AdditionalInfo of Datasheet readings rows, error: ${error}`,
    );
    return false;
  }
};

export const datasheetsApprove = (datasheetId) => {
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  axiosWithToken
    .patch(BASE_URL + `datasheets/${datasheetId}`, {
      datasheetApprovedBy: user[0],
    })
    .then((res) => {
      toast.success("Datasheet Approved successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    })
    .catch((err) => console.log(err));
};
