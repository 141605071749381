import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToWords } from "to-words";
import moment from "moment";
const UnitechHeader = "/images/certificate_header.png";
const UnitechFooter = "/images/certificate_footer.png";

let config = {
  margin: 15,
  borderMagin: 7,
  headerHeight: 60,
  footerHeight: 60,
  fontSize: 9,
  cusotmerHeight: 50,
  bordergap: 5,
  currentY: 0,
};

// Helper function to draw the header and footer images
function addHeaderFooter(doc) {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Draw header image at the top (x:0, y:0)
  doc.addImage(UnitechHeader, "PNG", 0, 0, pageWidth, config?.headerHeight);
  doc.addImage(
    UnitechFooter,
    "PNG",
    0,
    pageHeight - config?.footerHeight,
    pageWidth,
    config?.footerHeight
  );
}

const generatePDFVoucher = (
  nameOfPerson,
  amount,
  expenseDate,
  reason,
  letterHead
) => {
  const doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
    compress: true,
  });
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.text(
    "Payment Voucher",
    doc.internal.pageSize.getWidth() / 2,
    config.headerHeight + 20,
    { align: "center" }
  );
  // Title
const titleX = doc.internal.pageSize.getWidth() / 2;
const titleY = config.headerHeight + 20;

doc.text("Payment Voucher", titleX, titleY, { align: "center" });

// Draw underline (x1, y1, x2, y2)
const textWidth = doc.getTextWidth("Payment Voucher");
doc.line(titleX - textWidth / 2, titleY + 2, titleX + textWidth / 2, titleY + 2);


  const toWords = new ToWords();

  // Adding a table (if needed)
  const tableRows = [
    [
      { content: "Paid To", styles: { fontStyle: "bold" } },
      { content: nameOfPerson || "" },
    ],
    [
      { content: "Date", styles: { fontStyle: "bold" } },
      {
        content: moment(expenseDate).format("DD-MM-YYYY") || "",
      },
    ],
    [
      { content: "Amount", styles: { fontStyle: "bold" } },
      { content: `${amount.toFixed(2)}` || "" },
    ],
    [
      { content: "Amount in Words", styles: { fontStyle: "bold" } },
      {
        content: toWords.convert(amount ? amount.toFixed(2) : 0, {
          currency: true,
        }),
      },
    ],
    [
      { content: "Towards", styles: { fontStyle: "bold" } },
      { content: reason || "" },
    ],
  ];
  doc.autoTable({
    startY: config?.headerHeight + config?.bordergap + 30,
    theme: "grid",
    margin: {
      left: config.margin + 50,
      right: config.margin + 50,
      top: config?.headerHeight + config?.bordergap,
      bottom: config?.footerHeight + config?.bordergap,
    },
    tableLineWidth: 0.5,
    body: tableRows,
  });

  if (letterHead) {
    const pageCount = doc.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      addHeaderFooter(doc);
    }
  }

  doc.save("PaymentVoucher.pdf");
};

export default generatePDFVoucher;
