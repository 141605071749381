import { driver } from "driver.js";
export const templateMasterListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Template list.",
        },
      },
      {
        element: "#template_master_list_createbtn",
        popover: {
          title: "Create new Template List",
          description: "When click this button you can create new Template.",
        },
      },
      {
        element: "#template_master_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#template_master_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter your desired search value.",
        },
      },
      {
        element: "#template_master_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#template_master_list_table",
        popover: {
          title: "Template List Table",
          description:
            "Table displaying Template List details where you can delete and edit Template.",
        },
      },
      {
        element: "#template_master_list_copy",
        popover: {
          title: "Copy Template",
          description:
            "Click this button to copy; it will create a new Template with existing values..",
        },
      },
      {
        element: "#template_master_list_edit",
        popover: {
          title: "Edit Template",
          description: "Click this button to edit the  Template.",
        },
      },
      {
        element: "#template_master_list_delete",
        popover: {
          title: "Delete Template",
          description: "Click this button to delete the Template.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage Template List.",
        },
      },
    ],
  });
};
