import React, { useContext, useRef } from "react";
import { Button, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { DatasheetContext } from "../datasheet.context";

const ExportImportData = ({
  tableId,
  tableName,
  datasheetId,
  tableIndex,
  setReadingRowsLocal,
}) => {
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: { staticReadingRows },
    dispatch,
  } = datasheetContextValue;
  const fileInputRef = useRef(null);

  const handleExport = () => {
    // Filter rows matching the tableId
    const dataToExport = staticReadingRows
      ?.filter((row) => row.tableId === tableId)
      ?.map(({ id, datasheetId, ...rest }) => rest);

    // Convert the filtered data to a JSON string
    const dataStr = JSON.stringify(dataToExport, null, 2);
    const blob = new Blob([dataStr], { type: "application/json" });

    // Create the download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    // Set the file name to include tableName and datasheetId
    link.download = `${datasheetId}_${tableId}_T${tableIndex + 1}.json`;
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };

  const handleImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedData = JSON.parse(e.target.result);
          // Filter imported data to include only matching tableId entries
          const filteredData = importedData
            ?.filter((row) => row.tableId === tableId)
            ?.map((row) => ({ ...row, datasheetId }));

          setReadingRowsLocal([
            ...staticReadingRows.filter((row) => row.tableId !== tableId),
            ...filteredData,
          ]);

          // Update the context state with filtered data
          dispatch({
            field: "staticReadingRows",
            value: [
              ...staticReadingRows.filter((row) => row.tableId !== tableId),
              ...filteredData,
            ],
          });
        } catch (error) {
          console.error("Error parsing JSON file:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <Tooltip title="Export" arrow>
        <Button
          variant="text"
          color="primary"
          onClick={handleExport}
          style={{ minWidth: "20px" }}
        >
          <FileDownloadIcon />
        </Button>
      </Tooltip>
      <Tooltip title="Import" arrow>
        <Button
          variant="text"
          color="primary"
          onClick={triggerFileInput}
          style={{ minWidth: "20px" }}
        >
          <FileUploadIcon />
        </Button>
      </Tooltip>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept=".json"
        onChange={handleImport}
      />
    </div>
  );
};

export default ExportImportData;
