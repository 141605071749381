import { jsPDF } from "jspdf";
import AptosNormal from "../../../utils/Customfonts/Aptos.ttf";
import AptosBold from "../../../utils/Customfonts/Aptos-Bold.ttf";

import autoTable from "jspdf-autotable";
import {
  fetchCertificate,
  fetchCertificateStaticTables,
  fetchStaticReadingRows,
} from "../../certificate/fetchHandler";
import { drawTables } from "./renderTables";
import {
  datasheetBorderLayout,
  customFontFamily,
  datasheetfirstBox,
  footerConfig,
  loadAndAddFont,
  NameAndAddressDetails,
  printdatasheetSection,
  tableConfiguration,
  totalHeaderHeightWithCalibrationLableorNot,
} from "./datasheetConfig";

import {
  layoutConfig,
  printdatasheetConfigData,
  printDatasheetConfiguration,
} from "./datasheetConstant";
import {
  detailsofstandards,
  drawTextBoldAndNormal,
  drawText,
  drawUUCTable,
  environmenentTable,
  remarksTable,
  firstcolumnHeadersecondValue,
} from "./commonTable";
import {
  drawDynamicFooter,
  drawLetterFooterImg,
  drawLetterHeadHeaderImg,
} from "./headerAndfooter";
import { drawDynamicHeader } from "./headerAndfooter";
import moment from "moment";
import {
  DataisValid,
  DateisValid,
} from "../../../utils/components/accuracyandlcandrangesshow";

const Logo = "./images/logo.png";

const drawPageHeader = () => {
  // drawLetterHeadHeaderImg();
  drawDynamicHeader();
};

const drawPageFooter = () => {
  drawDynamicFooter();
  // drawLetterFooterImg();
};

const initPdfDoc = async () => {
  printDatasheetConfiguration.pdfDoc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
    compress: true,
  });

  await Promise.all([
    loadAndAddFont(
      printDatasheetConfiguration.pdfDoc,
      AptosNormal,
      "Aptos",
      customFontFamily?.normalFont,
      customFontFamily?.normal
    ),
    loadAndAddFont(
      printDatasheetConfiguration.pdfDoc,
      AptosBold,
      "Aptos-Bold",
      customFontFamily?.boldFont,
      customFontFamily?.bold
    ),
  ]);

  // Set default font
  printDatasheetConfiguration.pdfDoc.setFont(customFontFamily?.normalFont);
  printDatasheetConfiguration.pdfDoc.setFont(customFontFamily?.boldFont);

  commonPageContet();

  layoutConfig.yPositionGlobal =
    totalHeaderHeightWithCalibrationLableorNot.total;
};
const commonPageContet = () => {
  // add page header
  drawPageHeader();
  // // add page footer
  drawPageFooter();
  // draw border
  printDatasheetConfiguration.pdfDoc.rect(
    datasheetBorderLayout.x,
    datasheetBorderLayout.y,
    datasheetBorderLayout.width,
    datasheetBorderLayout.height
  );
};
export const addNewPage = async () => {
  printDatasheetConfiguration.pdfDoc.addPage();
  commonPageContet();
};

const initPage = () => {
  commonPageContet();
};

const setPageSection = async (addNewPages = false) => {
  if (
    layoutConfig.yPositionGlobal >
      printDatasheetConfiguration.pageHeight -
        footerConfig.footerimgHeight +
        footerConfig.footertableHeight -
        100 ||
    addNewPages
  ) {
    await addNewPage();
    layoutConfig.yPositionGlobal =
      totalHeaderHeightWithCalibrationLableorNot.total;
  }
};

const disciplineNameShow = async () => {
  await setPageSection(
    printdatasheetSection.disciplineAndReadinTableStartNewpage
  );

  let boldtext = "Discipline : ";
  let normaltext =
    printdatasheetConfigData?.datasheetContextData?.datasheetDetails
      ?.disciplineName;

  drawTextBoldAndNormal(
    boldtext,
    normaltext,
    layoutConfig.xPositionGlobal,
    layoutConfig.yPositionGlobal,
    printDatasheetConfiguration.align.left,
    printDatasheetConfiguration.fontSizes.h2,
    5,
    0
  );
};

const drawNameandAddress = async () => {
  await setPageSection();

  const data1 = datasheetfirstBox(
    printdatasheetConfigData.datasheetContextData
  );
  firstcolumnHeadersecondValue(data1);
};



export const generateDatasheetPDF = async ({
  datasheetId,
  footerSignSetting,
  letterHeadAndElectronicAndDraft,
  remarksCondition,
  setDownloadPdfloading,
  datasheetContext,
}) => {
  try {
    setDownloadPdfloading(true);

    printdatasheetConfigData.datasheeteId = datasheetId;
    printdatasheetConfigData.footerSignSettingConfig = footerSignSetting;
    printdatasheetConfigData.letterHeadAndElectronicAndDraftConfig =
      letterHeadAndElectronicAndDraft;

    const staticCertificateReadings = await fetchStaticReadingRows(datasheetId);
    printdatasheetConfigData.datasheetContextData = datasheetContext;

    await initPdfDoc();

    await drawNameandAddress();
    await drawUUCTable(setPageSection);

    await environmenentTable(setPageSection);
    await detailsofstandards(setPageSection);
    await disciplineNameShow();

    // // draw tables
    layoutConfig.yPositionGlobal = await drawTables(
      printDatasheetConfiguration.pdfDoc,
      layoutConfig.yPositionGlobal,
      datasheetContext.staticTables,
      staticCertificateReadings,
      {
        margin: printDatasheetConfiguration.margin,
        tableMargin: {
          top: totalHeaderHeightWithCalibrationLableorNot.total,
          right: printDatasheetConfiguration.margin,
          left: printDatasheetConfiguration.margin,
          bottom:
            footerConfig.footerimgHeight +
            footerConfig.footertableHeight +
            footerConfig.footerandbodygap - 20,
        },
        initPage: initPage,
      }
    );
    await remarksTable(remarksCondition, setPageSection);

    // await endofcertificate();

    const totalPages = printDatasheetConfiguration.pdfDoc.getNumberOfPages();
    for (let page = 1; page <= totalPages; page++) {
      printDatasheetConfiguration.pdfDoc.setPage(page);
      let content = `Page No : ${String(page).padStart(2, "0")} of ${String(
        totalPages
      ).padStart(2, "0")}`;
      drawText(
        content,
        printDatasheetConfiguration.pageWidth - 85,
        printDatasheetConfiguration.pageHeight - 10,
        "left",
        printDatasheetConfiguration.fontSizes.h2,
        5,
        0
      );
    }

    // save and download pdf
    printDatasheetConfiguration.pdfDoc.save(
      `${datasheetContext.certificateDetails?.certificateNumber}.pdf`
    );
    setDownloadPdfloading(false);
  } catch (error) {
    console.error("Error generating PDF:", error);
    setDownloadPdfloading(false);
  }
};
