import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ClassicTable } from "../../utils/components/Styles";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import {fetchAddresses} from "../invoice/productDetails";

export default function EditInvoice(props) {
  const [companyName, setCompanyName] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState("");
  const [discount, setTotalDiscount] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [invoiceDate, setInvoiceDate] = React.useState("");
  const [instrumentIds, setInstrumentIds] = React.useState([]);
  const [invoiceNumber, setInvoiceNumber] = React.useState(
    ""
    // (Math.random() + 1).toString(36).substring(7)
  );
  const [paymentAmount, setPaymentAmount] = React.useState(0);
  const [paymentDate, setPaymentDate] = React.useState("");
  const [paidAmount, setPaidAmount] = React.useState(0);
  const [paymentMode, setPaymentMode] = React.useState("");
  const [paymentHistory, setPaymentHistory] = React.useState("");
  const [paymentHistoryMaker, setpaymentHistoryMaker] = React.useState("");
  const [paymentHistoryAllData, setpaymentHistoryAllData] = React.useState([]);
  const [narration, setNarration] = React.useState("");
  var [buttonCon, setButtonCon] = React.useState(false);
  const [lastDueAmount, setLastDueAmount] = React.useState("");
  const [selectedAddress, setSelectedAddress] = React.useState({});

  const params = useParams();
  const getPaymentData = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(
        url +
          `xjoin?_join=payment.paymentMaster,_j,client.clients&_on1=(payment.clientId,eq,client.id)&_fields=payment.id,payment.clientId,client.companyName,payment.address,payment.conveyanceCharges,payment.discount,payment.gstType,payment.instrumentId,payment.invoiceDate,payment.invoiceNumber,payment.poNumber,payment.subject,payment.totalAmount,payment.paymentHistory,payment.narration,payment.paidAmount&_where=(payment.id,eq,${params.id})~and(payment.status,eq,1)`
      )
      .then(async(res) => {
        let addressIDs = res.data[0]?.payment_address?.split(",");
        let newAddressArray = await fetchAddresses(addressIDs?.[0],addressIDs?.[1]);
        
        setCompanyName(res.data[0].client_companyName);
        setClientId(res.data[0].payment_clientId);
        setInstrumentArray(res.data);
        setAddress(newAddressArray[0]?.address);
        setSubject(res.data[0].payment_subject);
        setTotalAmount(res.data[0].payment_totalAmount);
        setInvoiceDate(res.data[0].payment_invoiceDate);
        setPaymentAmount(res.data[0].payment_paymentAmount);
        setInvoiceNumber(res.data[0].payment_invoiceNumber);
        // setPaidAmount(res.data[0].payment_paidAmount);
        setPaymentHistory(res.data[0].payment_paymentHistory);
        setNarration(res.data[0].payment_narration);
      })
      .catch((error) => {
        toast.error("Something Went Wrong with standards!");
      });
  };

  const handleSubmit = (event) => {
    var paymentHostoryObj = [
      params.id,
      paymentAmount,
      paymentDate
        ? moment(paymentDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      paymentMode,
      lastDueAmount
        ? lastDueAmount - paymentAmount
        : totalAmount - paymentAmount,
      narration,
      totalAmount,
    ];

    let row = {
      clientId: clientId,
      address: address,
      totalAmount: totalAmount ? totalAmount : 0,
      paidAmount: paidAmount + Number(paymentAmount),
      discount: discount ? discount : 0,
      paymentMode: paymentMode,
      subject: subject,
      invoiceNumber: invoiceNumber,
      instrumentId: instrumentIds.toString(),
      narration: narration,
      paymentDate: moment(new Date()).format("YYYY-MM-DD"),
      invoiceDate: invoiceDate
        ? moment(invoiceDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      paymentHistory: paymentHistory
        ? paymentHistory.concat(":", paymentHostoryObj).replaceAll('"', "")
        : paymentHostoryObj.toString().replaceAll('"', ""),
    };

    let url = BASE_URL;
    axiosWithToken
      .patch(url + `paymentMaster/${params.id}`, row)
      .then((res) => {
        props.setLoader(false);
        toast("Payment updated successfully!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        // props.setLoader(false);
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const initiateInvoiceData = () => {
    let rows = [];
    const instrumentIds = [];
    for (let i = 0; i < invoiceData.length; i++) {
      rows.push([invoiceData[i].instrumentId, invoiceData[i].clientId]);
      instrumentIds.push(invoiceData[i].instrumentId);
    }
    const newArr = [];
    for (let i = 0; i < rows.length; i++) {
      newArr.push(rows[i][0]);
    }

    const count = {};
    newArr.forEach((element) => {
      count[element] = (count[element] || 0) + 1;
    });

    const removeDuplicates = [...new Set(newArr)];

    let newRows = [];
    for (const property in count) {
      for (let i = 0; i < removeDuplicates.length; i++) {
        if (parseInt(property) === removeDuplicates[i]) {
          newRows.push([parseInt(property), count[property]]);
        }
      }
    }

    for (let i = 0; i < newRows.length; i++) {
      for (let j = 0; j < instrumentArray.length; j++) {
        if (newRows[i][0] === instrumentArray[j].ins_id) {
          newRows[i][0] = instrumentArray[j].ins_instrumentName;
          newRows[i][1] = newRows[i][1];
          newRows[i][2] = 200;
          newRows[i][3] = 200 * newRows[i][1];
        }
      }
    }
    let uniqueChars = [...new Set(instrumentIds)];

    setInstrumentIds(uniqueChars);
  };

  const initiatePaymentHistoryData = () => {
    let paymentMaker = [];
    var splitByColon = paymentHistory ? paymentHistory.split(":") : [];
    if (splitByColon.length) {
      for (let i = 0; i < splitByColon.length; i++) {
        let splitByComma = splitByColon[i].split(",");
        paymentMaker.push([
          {
            id: splitByComma[0] ? splitByComma[0].replace(/['"]+/g, "") : "",
            paidAmount: splitByComma[1]
              ? splitByComma[1].replace(/['"]+/g, "")
              : "",
            paidDate: splitByComma[2]
              ? splitByComma[2].replace(/['"]+/g, "")
              : "",
            paymentMode: splitByComma[3]
              ? splitByComma[3].replace(/['"]+/g, "")
              : "",
            narration: splitByComma[5]
              ? splitByComma[5].replace(/['"]+/g, "")
              : "",
            dueAmount: splitByComma[4],
          },
        ]);
      }
    }
    setpaymentHistoryMaker(paymentMaker);
  };

  const initiatePaymentHistoryAllData = () => {
    var pushArr = [];

    paymentHistoryMaker.length
      ? paymentHistoryMaker.forEach((item, index) => {
          pushArr.push(paymentHistoryMaker[index][0]);
        })
      : console.log("empty");

    if (pushArr.length) {
      let reverseArray = pushArr.reverse();
      setpaymentHistoryAllData(reverseArray);

      setLastDueAmount(reverseArray?.[0]?.dueAmount);
    } else {
      setpaymentHistoryAllData([]);
    }
  };

  const totalPaidAmount = () => {
    const paidAmount = paymentHistoryAllData.reduce(
      (sum, ele) => sum + Number(ele.paidAmount),
      0
    );
    setPaidAmount(paidAmount);
  };

  useEffect(() => {
    totalPaidAmount();
  }, [paymentHistoryAllData]);

  useEffect(() => {
    getPaymentData();
  }, []);

  useEffect(() => {
    initiateInvoiceData();
  }, [invoiceData]);

  useEffect(() => {
    initiatePaymentHistoryData();
  }, [paymentHistory]);

  useEffect(() => {
    initiatePaymentHistoryAllData();
  }, [paymentHistoryMaker]);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Payment
      </Typography>
      <br />
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Client"
            size="small"
            value={companyName}
            fullWidth
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            InputLabelProps={{ shrink: true }}
            size="small"
            value={address}
            disabled
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Invocie Number"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={invoiceNumber}
            fullWidth
            disabled
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{ shrink: true }}
            label="Subject"
            size="small"
            value={subject}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{
                textField: { size: "small", fullWidth: true },
              }}
              label="Invoice Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={invoiceDate ? new Date(invoiceDate) : new Date()}
              onChange={(newValue) => {
                setInvoiceDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Total Amount"
            size="small"
            disabled
            fullWidth
            value={totalAmount}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setTotalAmount(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Due Amount"
            size="small"
            disabled
            fullWidth
            // value={totalAmount - lastDueAmount}
            value={totalAmount - paidAmount}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            // onChange={(e) => {
            //   setTotalAmount(e.target.value);
            // }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Payment Amount"
            inputProps={{
              autoComplete: 'off',
            }}
            size="small"
            type="number"
            fullWidth
            // value={totalAmount}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              e.target.value <= totalAmount - paidAmount
                ? setPaymentAmount(parseFloat(e.target.value)) &&
                  setButtonCon(false)
                : toast.error("Payment Amount is higher than Due Amount!!") &&
                  setButtonCon(true);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{
                textField: { size: "small", fullWidth: true },
              }}
              label="Payment Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={paymentDate ? new Date(paymentDate) : new Date()}
              onChange={(newValue) => {
                setPaymentDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "cash" },
              { id: 2, label: "cheque" },
              { id: 3, label: "NEFT" },
              { id: 4, label: "upi" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Payment Mode" />
            )}
            onInputChange={(event, newInputValue) => {
              setPaymentMode(newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{ shrink: true }}
            label="Narration"
            inputProps={{
              autoComplete: 'off',
            }}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setNarration(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />
      <div>
        <Typography variant="h6" component="div" sx={{ mb: 2 }}>
          Payment History
        </Typography>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Payment Date</TableCell>
                <TableCell>Paid Amount</TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>Narration</TableCell>
                <TableCell>Due Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistoryAllData.map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {row.paidDate
                      ? moment(row.paidDate).format("DD-MM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell>{row.paidAmount}/-</TableCell>
                  <TableCell>{row.paymentMode}</TableCell>
                  <TableCell>{row.narration}</TableCell>
                  <TableCell
                    style={{ color: row.dueAmount > 0 ? "red" : "green" }}
                  >
                    {row.dueAmount}/-
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />

      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            if (
              paymentAmount > 0 &&
              paymentAmount <= totalAmount - paidAmount
            ) {
              props.setLoader(true);
              handleSubmit();
            } else {
              toast.error("Payment Amount is higher than Due Amount!!");
            }
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
