var metric, imperial;

metric = {
  Nm: {
    name: {
      singular: "Newton-meter",
      plural: "Newton-meters",
    },
    to_anchor: 1,
  },
  kgfM: {
    name: {
      singular: "Kilogram-force Meter",
      plural: "Kilogram-force Meters",
    },
    to_anchor: 9.80665,
  },
};

imperial = {
  "Lbf.in": {
    name: {
      singular: "Pound Force-Inch",
      plural: "Pound Force-Inches",
    },
    to_anchor: 1 / 12,
  },
  "Lbf.ft": {
    name: {
      singular: "Pound Force-Foot",
      plural: "Pound Force-Feet",
    },
    to_anchor: 1, // Pound Force-Foot as a base unit
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "Nm",
      ratio: 1 / 0.737562,
    },
    imperial: {
      unit: "ftlb",
      ratio: 0.737562,
    },
  },
};
