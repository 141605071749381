import { jsPDF } from "jspdf";
import "jspdf-autotable";
import AptosNormal from "../../../utils/Customfonts/Aptos.ttf";
import AptosBold from "../../../utils/Customfonts/Aptos-Bold.ttf";
import {
  budget_columns,
  typeA_columns,
  typeB_columns,
} from "../../../constants/uncertaintiesConstants";

// Function to load custom fonts
const loadFonts = async (pdfDoc, fontUrl, fontNameTTF, fontName, fontStyle) => {
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const chunkSize = 8192; // Process in chunks for large buffers
    for (let i = 0; i < bytes.length; i += chunkSize) {
      binary += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
    }
    return btoa(binary);
  };

  const fontResponse = await fetch(fontUrl);
  const fontBuffer = await fontResponse.arrayBuffer();
  const fontBase64 = arrayBufferToBase64(fontBuffer);

  pdfDoc.addFileToVFS(`${fontNameTTF}.ttf`, fontBase64);
  pdfDoc.addFont(`${fontNameTTF}.ttf`, fontName, fontStyle);
};

const customFontFamily = {
  bold: "bold",
  normal: "normal",
  boldFont: "AptosBold",
  normalFont: "AptosNormal",
};

const pageHeight = 841;
const pageWidth = 595;
let pdfDoc;
let processedPages = new Set(); // Set to keep track of processed pages
let firstSkippedPage = null; // To track the first page that should be skipped;
let globaly;

const dynamicHeader = (certifcateviewDetails) => {
  const tableRows = [
    [
      {
        content: "Customer Name",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
          valign: "middle",
        },
      },
      {
        content: "Job No",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "center",
          valign: "middle",
        },
      },
    ],
    [
      {
        content: certifcateviewDetails?.companyName || "",
        styles: { halign: "center", valign: "middle" },
      },
      {
        content: certifcateviewDetails?.certificateNumber || "",
        styles: { halign: "center", valign: "middle" },
      },
    ],
  ];

  pdfDoc.autoTable({
    startY: 6,
    body: tableRows,
    margins: { top: 0, right: 15, bottom: 0, left: 15 },
    theme: "grid",
    styles: {
      font: customFontFamily?.normalFont,
      fontSize: 10,
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      cellPadding: { top: 2, left: 2, bottom: 2, right: 2 },
    },
  });
};

const dynamicFooter = (datasheetDetails) => {
  const tableRows = [
    [
      {
        content: "Calibrated By",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "left",
          valign: "middle",
        },
      },
      {
        content: "Approved By",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          halign: "right",
          valign: "middle",
        },
      },
    ],
    [
      {
        content: datasheetDetails?.calibrated_by || "",
        styles: { halign: "left", valign: "middle" },
      },
      {
        content: datasheetDetails?.approved_by || "",
        styles: { halign: "right", valign: "middle" },
      },
    ],
  ];

  pdfDoc.autoTable({
    startY: pageHeight - 45 ,
    body: tableRows,
    margins: { top: 0, right: 15, bottom: 0, left: 15 },
    theme: "grid",
    styles: {
      font: customFontFamily?.normalFont,
      fontSize: 10,
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      cellPadding: { top: 2, left: 2, bottom: 2, right: 2 },
    },
  });
};

const drawText = (
  text,
  xSize,
  ySize,
  align = "left",
  fontSize = 10,
  topSpace = 0,
  bottomSpace = 0,
  fontType
) => {
  pdfDoc.setFontSize(fontSize);
  if (fontType == "normalfont") {
    pdfDoc.setFont(customFontFamily?.normalFont, customFontFamily?.normal);
  } else {
    pdfDoc.setFont(customFontFamily?.boldFont, customFontFamily?.bold);
  }
  let yPos = ySize + topSpace;
  let textWidth = pdfDoc.getTextWidth(text);
  let xPos = xSize;

  if (align === "left") {
    xPos += 1;
  } else if (align === "center") {
    xPos = (pdfDoc.internal.pageSize.width - textWidth) / 2;
  } else if (align === "right") {
    xPos = pdfDoc.internal.pageSize.width - textWidth - xSize;
  }

  pdfDoc.text(text, xPos, yPos);

  if (fontType == "normalfont") {
    pdfDoc.setFont(customFontFamily?.boldFont, customFontFamily?.bold);
  } else {
    pdfDoc.setFont(customFontFamily?.normalFont, customFontFamily?.normal);
  }

  if (yPos + fontSize + bottomSpace > pageHeight - 50) {
    globaly = 70;
  } else {
    globaly = yPos + fontSize + bottomSpace;
  }
};

const commonPageContet = (certifcateviewDetails, datasheetDetails) => {
  // add page header
  dynamicHeader(certifcateviewDetails);

    // add page header
    dynamicFooter(datasheetDetails);
  // draw border
  pdfDoc.rect(15, 45, pageWidth - 30, pageHeight - 95);
};

const processValue = (col) => {
  if (typeof col === "string") {
    return col?.replaceAll("#", " ")?.split("||")?.[0];
  
  } else if (typeof col === "function") {
    let getValue = col()?.props?.children;
    const [child0, child1, child2, child3] = [getValue?.[0], getValue?.[1], getValue?.[2], getValue?.[3]];
    let result = child0 + (typeof child1 === "number" ? " " + child1 : "") + (typeof child2 === "string" ? "\n" + child2 : "") + (typeof child3 === "string" ? "\n" + child3 : "");    
    return result.trim() ? result : '';  
  
  } else if (typeof col?.[0] === "function") {
    let getValue = col?.[0]()?.props?.children;
    const [child0, child1, child2, child3] = [getValue?.[0], getValue?.[1], getValue?.[2], getValue?.[3]];
    let result = child0 + (typeof child1 === "number" ? " " + child1 : "") + (typeof child2 === "string" ? "\n" + child2 : "") + (typeof child3 === "string" ? "\n" + child3 : "");    
    return result.trim() ? result : ''; 

  } else if (typeof col?.[0] === "string") {
    return col?.[0]?.replaceAll("#", " ")?.split("||")?.[0];
  }
  return col;
};



// Function to build Type A table rows
const buildTypeA = (data) => {
  const tableRows = []; 

  data.forEach((row) => {
    let processedRow = row.map((col) => {
      // Check if the column is an array, indicating colspan
      if (Array.isArray(col)) {
        return {
          content: processValue(col[0]) || "",
          colSpan: processValue(col[1]) || 1,
          styles: {
            halign: "left",
          },
        };
      }
      // If the column is a value (string, number, etc.)
      if (col !== null) {
        return {
          content: processValue(col),
          colSpan: 1,
          styles: {
            halign: "left",
          },
        }; 
      }
      return null; 
    });

    // Filter out any null values (empty or invalid)
    const filteredRow = processedRow.filter((cell) => cell !== null);
    if (filteredRow.length > 0) {
      tableRows.push(filteredRow);
    }
  });

  return tableRows;
};


// Function to build Type B table rows
const buildTypeB = (data) => {
  const tableRows = [];
  data.forEach((row) => {
    const processedRow = row.map((col) => processValue(col));
    if (processedRow.length > 0) {      
      tableRows.push(processedRow);
    }
  });

  return tableRows;
};

const buildBudget = (data, additionalData) => {
  const tableRows = [];
  data.forEach((row) => {
    const processedRow = row.map((col) => processValue(col));
    if (processedRow.length > 0) {
      tableRows.push(processedRow);
    }
  });

  // Add additionalData if necessary
  if (additionalData) {
    tableRows.push([
      {
        content: "COMBINED UNCERTAINTY (Uc)",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
        },
        colSpan: 2,
      },
      {
        content: "EFFECTIVE DEGREES OF FREEDOM",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
        },
        colSpan: 2,
      },
      {
        content: "Expanded Uncertainty",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
        },
        colSpan: 2,
      },
    ]);

    // Add the data from `additionalData`
    tableRows.push([
      {
        content: additionalData?.uc?.replaceAll("#", " ")?.replaceAll("||", " ")?.replaceAll("//", " ") || "",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
        },
        colSpan: 2,
      },
      {
        content: additionalData?.freedom || "",
        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
        },
        colSpan: 2,
      },
      {
        content: additionalData?.finalExpandedUncertainty?.replaceAll("#", " ")?.replaceAll("||", " ")?.replaceAll("//", " ") || "",

        styles: {
          font: customFontFamily?.boldFont,
          fontStyle: customFontFamily?.bold,
          valign: "middle",
        },
        colSpan: 2,
      },
      ,
    ]);
  }

  return tableRows;
};

// Function to add a table to the PDF
const addTable = (
  pdfDoc,
  tableRows,
  startY,
  tableTitle,
  header,
  certifcateviewDetails,
  datasheetDetails
) => {
  startY += 1;
  if (tableTitle) {
    drawText(tableTitle, startY, startY, "center", 12, 5, 5);
  }
  startY += 10;
  pdfDoc.autoTable({
    startY: startY,
    head: [header?.map((col) => col.label)],
    body: tableRows,
    theme: "grid",
    margin: {
      top: 60,
      right: 25,
      left: 25,
      bottom: 70,
    },
    headStyles: {
      fillColor: false,
      textColor: [0, 0, 0],
      fontSize: 9,
      halign: "center",
      valign: "middle",
      font: customFontFamily?.boldFont,
    },
    styles: {
      minCellWidth: 45,
      fontSize: 9,
      cellPadding: 3,
      fillColor: false,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      halign: "left",
      valign: "middle",
      font: customFontFamily?.normalFont,
      cellPadding: { top: 2, left: 3, bottom: 2, right: 3 },
    },
    didDrawPage: (data) => {
      const currentPage = pdfDoc.internal.getNumberOfPages();
      // If the first skipped page is not set, skip the first page that appears
      // if (firstSkippedPage === null) {
      //   firstSkippedPage = currentPage; // Mark this page to be skipped first
      //   console.log(`Skipping initPage for the first occurrence of page: ${currentPage}`);
      //   return; // Skip processing this page
      // }
      // If the current page is the first skipped page, skip it
      if (currentPage === firstSkippedPage) {
        // console.log(`Skipping initPage for page ${currentPage} (first occurrence)`);
        return; // Skip processing this page
      }
      if (data.pageNumber > 1 && processedPages.has(currentPage) ) {
        commonPageContet(certifcateviewDetails, datasheetDetails);
        return;
      }
      if (!processedPages.has(currentPage) ) {
        processedPages.add(currentPage);
        // console.log(`Calling initPage for page ${currentPage}`);
        commonPageContet(certifcateviewDetails, datasheetDetails);
      } 
      // else {
      //   console.log(`Skipping initPage for page ${currentPage}`);
      // }
    },
  });

  globaly += pdfDoc.autoTable.previous.finalY + 10;
};

// Updated addReportDetailsTable function to handle separate tables
const addReportDetailsTable = (reportDetails, certifcateviewDetails, datasheetDetails) => {
  let currentY = 70; 

  reportDetails.forEach((u, index) => {
    // Process Type A data
    if (u[0]?.[0]?.length > 0) {
      const typeARows = buildTypeA(u[0][0]);
      addTable(
        pdfDoc,
        typeARows,
        currentY,
        `TYPE-A 1 EVALUATION`,
         typeA_columns,
         certifcateviewDetails,
         datasheetDetails,
        );
      currentY = pdfDoc.lastAutoTable.finalY + 15;
    }

    // Process Type B data
    if (u[1]) {
      const typeBRows = buildTypeB(u[1]);
      addTable(
        pdfDoc,
        typeBRows,
        currentY,
        `Type-B Contribution`,
        typeB_columns,
        certifcateviewDetails,
        datasheetDetails,
      );
      currentY = pdfDoc.lastAutoTable.finalY + 15;
    }

    // Process Budget data
    if (u[2][0]) {
      const budgetRows = buildBudget(u[2][0], u[2][1]);
      addTable(
        pdfDoc,
        budgetRows,
        currentY,
        `Uncertainty Budget`,
        budget_columns,
        certifcateviewDetails,
      datasheetDetails,
          );
      currentY = pdfDoc.lastAutoTable.finalY + 15;
    }
  });
};

const initPdfDoc = async () => {
  pdfDoc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
    compress: true,
  });

  await Promise.all([
    loadFonts(
      pdfDoc,
      AptosNormal,
      "Aptos",
      customFontFamily?.normalFont,
      customFontFamily?.normal
    ),
    loadFonts(
      pdfDoc,
      AptosBold,
      "Aptos-Bold",
      customFontFamily?.boldFont,
      customFontFamily?.bold
    ),
  ]);

  // Set default font
  pdfDoc.setFont(customFontFamily?.normalFont);
  pdfDoc.setFont(customFontFamily?.boldFont);
};

export const generateUncertaintyPDF = async (
  datasheetId,
  setDownlaodPdfLoading,
  certifcateviewDetails,
  datasheetDetails,
  reportDetails
) => {
  try {
    setDownlaodPdfLoading(true);
    // Define PDF dimensions

    await initPdfDoc();

    // Add report details as a table
    addReportDetailsTable(reportDetails, certifcateviewDetails, datasheetDetails);

    processedPages.clear();

    // Generate pages and add footer content
    const totalPages = pdfDoc.getNumberOfPages();
    for (let page = 1; page <= totalPages; page++) {
      pdfDoc.setPage(page);
      // Add page numbering footer
      const footerContent = `Page No: ${String(page).padStart(
        2,
        "0"
      )} of ${String(totalPages).padStart(2, "0")}`;
      pdfDoc.setFontSize(8);
      pdfDoc.text(footerContent, pageWidth - 75, pageHeight - 5);
    }

    // Save and download the PDF
    const fileName = `budget_${datasheetId}.pdf`;
    pdfDoc.save(fileName);

    // Reset loading state
    setDownlaodPdfLoading(false);
  } catch (error) {
    // Handle errors gracefully
    console.error("Error generating PDF:", error);
    setDownlaodPdfLoading(false);
  }
};
