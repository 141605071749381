var metric, imperial;

metric = {
  "mm³": {
    name: {
      singular: "Cubic Millimeter",
      plural: "Cubic Millimeters",
    },
    to_anchor: 1 / 1000000,
  },
  "cm³": {
    name: {
      singular: "Cubic Centimeter",
      plural: "Cubic Centimeters",
    },
    to_anchor: 1 / 1000,
  },
  µL: {
    name: {
      singular: "Microliter",
      plural: "Microliters",
    },
    to_anchor: 1 / 1000000,
  },
  ml: {
    name: {
      singular: "Millilitre",
      plural: "Millilitres",
    },
    to_anchor: 1 / 1000,
  },
  cl: {
    name: {
      singular: "Centilitre",
      plural: "Centilitres",
    },
    to_anchor: 1 / 100,
  },
  dl: {
    name: {
      singular: "Decilitre",
      plural: "Decilitres",
    },
    to_anchor: 1 / 10,
  },
  l: {
    name: {
      singular: "Litre",
      plural: "Litres",
    },
    to_anchor: 1,
  },
  kl: {
    name: {
      singular: "Kilolitre",
      plural: "Kilolitres",
    },
    to_anchor: 1000,
  },
  "m³": {
    name: {
      singular: "Cubic Meter",
      plural: "Cubic Meters",
    },
    to_anchor: 1000,
  },
  "km³": {
    name: {
      singular: "Cubic Kilometer",
      plural: "Cubic Kilometers",
    },
    to_anchor: 1000000000000,
  },

  // Swedish units
  krm: {
    name: {
      singular: "Matsked",
      plural: "Matskedar",
    },
    to_anchor: 1 / 1000,
  },
  tsk: {
    name: {
      singular: "Tesked",
      plural: "Teskedar",
    },
    to_anchor: 5 / 1000,
  },
  msk: {
    name: {
      singular: "Matsked",
      plural: "Matskedar",
    },
    to_anchor: 15 / 1000,
  },
  kkp: {
    name: {
      singular: "Kaffekopp",
      plural: "Kaffekoppar",
    },
    to_anchor: 150 / 1000,
  },
  glas: {
    name: {
      singular: "Glas",
      plural: "Glas",
    },
    to_anchor: 200 / 1000,
  },
  kanna: {
    name: {
      singular: "Kanna",
      plural: "Kannor",
    },
    to_anchor: 2.617,
  },
};

imperial = {
  tsp: {
    name: {
      singular: "Teaspoon",
      plural: "Teaspoons",
    },
    to_anchor: 1 / 6,
  },
  Tbs: {
    name: {
      singular: "Tablespoon",
      plural: "Tablespoons",
    },
    to_anchor: 1 / 2,
  },
  "in³": {
    name: {
      singular: "Cubic Inch",
      plural: "Cubic Inches",
    },
    to_anchor: 0.55411,
  },
  "fl-oz": {
    name: {
      singular: "Fluid Ounce",
      plural: "Fluid Ounces",
    },
    to_anchor: 1,
  },
  cup: {
    name: {
      singular: "Cup",
      plural: "Cups",
    },
    to_anchor: 8,
  },
  pnt: {
    name: {
      singular: "Pint",
      plural: "Pints",
    },
    to_anchor: 16,
  },
  qt: {
    name: {
      singular: "Quart",
      plural: "Quarts",
    },
    to_anchor: 32,
  },
  gal: {
    name: {
      singular: "Gallon",
      plural: "Gallons",
    },
    to_anchor: 128,
  },
  "ft³": {
    name: {
      singular: "Cubic Foot",
      plural: "Cubic Feet",
    },
    to_anchor: 957.506,
  },
  "yd³": {
    name: {
      singular: "Cubic Yard",
      plural: "Cubic Yards",
    },
    to_anchor: 25852.7,
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "l",
      ratio: 33.8140226,
    },
    imperial: {
      unit: "fl-oz",
      ratio: 1 / 33.8140226,
    },
  },
};
