import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, Input, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";

const ExcelUpload = ({ setExcelArray, buttonName }) => {
  const handleFileUpload = (acceptedFiles) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const keys = parsedData[0];

      // Constructing objects with key-value pairs
      const result = parsedData.slice(1).map((row) => {
        const obj = {};
        keys.forEach((key, index) => {
          obj[key] = row[index];
        });
        return obj;
      });

      setExcelArray(result);
    };
    if (acceptedFiles.length > 0) {
      fileReader.readAsArrayBuffer(acceptedFiles[0]);
    } else {
      toast.error("Something Went Wrong!");
    }
  };

  return (
    <div id="quotation_create_excelUpload_uploadexcel">
      <Input
        id="file-upload-input"
        type="file"
        style={{ display: "none" }}
        onChange={(e) => handleFileUpload(e.target.files)}
      />
      <label htmlFor="file-upload-input">
        <Button variant="contained" component="span">
          {buttonName}
        </Button>
      </label>
    </div>
  );
};

export default ExcelUpload;
