import React from "react";
import { DEVICE_TYPE } from "./global";

const DeviceValidator = () => {
  const [upgradeDevice, setUpgradeDevice] = React.useState(false);
  const [deviceInfo, setDeviceInfo] = React.useState("");

  React.useEffect(() => {
    localStorage.removeItem("deviceInfo");

    function getDeviceType() {
      const userAgent = navigator.userAgent;
      if (/mobile/i.test(userAgent)) {
        return "mobile";
      }
      if (/tablet/i.test(userAgent)) {
        return "tablet";
      }
      if (/iPad|Macintosh/i.test(userAgent) && "ontouchend" in document) {
        // Special case for iPads running iPadOS
        return "tablet";
      }
      return "desktop";
    }

    const storedDeviceInfo = localStorage.getItem("deviceInfo");

    if (storedDeviceInfo) {
      setDeviceInfo(storedDeviceInfo);
    } else {
      const currentDeviceInfo = getDeviceType();
      setDeviceInfo(currentDeviceInfo);
      localStorage.setItem("deviceInfo", currentDeviceInfo);
    }
    // console.log(getDeviceType())

    if (!DEVICE_TYPE.includes(getDeviceType())) {
      setUpgradeDevice(true);
    }
  }, []);
  
  return { upgradeDevice, deviceInfo };
};

export default DeviceValidator;
