import { styled } from "@mui/system";
export const ClassicTable = styled("div")`
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border: 1px solid #ddd;
        text-align: left;
        padding: 8px;
    }

    th {
        background-color: #ddd;
        
    }
`;

export const ClassicTableDatasheet = styled("div")`
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td,
    th {
        border-bottom: 0px solid black;
        border-left: 0px solid black;
        text-align: left;
        padding: 5px;
        font-size: 10px;
    }

    td:first-child,
    th:first-child {
        border-left: 1px solid black;
    }
    
    tr:last-child td,
    tr:last-child th {
        border-bottom: 1px solid black;
    }

    tr:first-child td {
        border-top: 0px solid black;
    }
`;