import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

import CommonForm from "../common/commonForm";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";

const EditExternalCalibration = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [addressesArray, setAddressesArray] = React.useState([]);
  const params = useParams();

  const updateInputObject = (key, value) => {
    setInputStates((prevInputStates) => ({
      ...prevInputStates,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    const formattedInputStates = {
      ...inputStates,
      calibrationDate: inputStates.calibrationDate
        ? moment(inputStates.calibrationDate).format("YYYY-MM-DD")
        : null,
      dueDate: inputStates.dueDate
        ? moment(inputStates.dueDate).format("YYYY-MM-DD")
        : null,
    };

    if (params.id > 0) {
      axiosWithToken
        .patch(url + `externalCalibration/${params.id}`, formattedInputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("ExternalCalibration edit successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    } else {
      axiosWithToken
        .post(url + "externalCalibration", formattedInputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("ExternalCalibration created successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
  };

  const fetchExternalCalibration = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `externalCalibration/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let { lastModified, ...restDataObject } = dataObject;

        let newData = {
          ...inputStates,
          ...restDataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchAddressesArray = (clientId) => {
    let url = BASE_URL;
    let SQLQuery = {
      query: `SELECT id, address FROM addresses WHERE FIND_IN_SET(id, (SELECT address FROM clients WHERE id = ${clientId}))`,
    };
    axiosWithToken
      .post(url + `dynamic`, SQLQuery)
      .then((res) => {
        let dataArray = res.data;
        dataArray && setAddressesArray(dataArray);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id > 0) fetchExternalCalibration();
  }, [params.id]);

  useEffect(() => {
    if (inputStates?.clientId) fetchAddressesArray(inputStates?.clientId);
  }, [inputStates?.clientId]);

 

  const inputFields = [
    {
      type: "Autocomplete",
      label: "Client Name",
      stateKey: "clientId",
      valueKey: "id",
      options: "clients",
      getOptionLabelKey: "companyName",
    },

    {
      type: "Autocomplete",
      label: "Address",
      stateKey: "address",
      valueKey: "id",
      options: addressesArray,
      getOptionLabelKey: "address",
    },
    {
      type: "Autocomplete",
      label: "Instrument Name",
      stateKey: "instrumentId",
      valueKey: "id",
      options: "instruments",
      getOptionLabelKey: "instrumentName",
    },
    { type: "TextField", label: "Requested Name", stateKey: "requestedName" },
    { type: "TextField", label: "DUCID/Serial Number", stateKey: "ducId" },
    { type: "TextField", label: "Certificate Number", stateKey: "certificateNumber" },
    {
      type: "DatePicker",
      label: "Calibration Date ",
      stateKey: "calibrationDate",
    },
    { type: "DatePicker", label: "Due Date", stateKey: "dueDate" },
    {
      type: "ImageUpload",
      label: "Certificate Upload",
      stateKey: "certificateFile",
    },
  ];

  return (
    <>
      <Paper sx={{ mt: -2, p: 2 }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {params.id == 0
            ? "Create External Calibration"
            : "Edit External Calibration"}
        </Typography>

        <CommonForm
          inputStates={inputStates}
          updateInputObject={updateInputObject}
          inputFields={inputFields}
        />

        <Toolbar
          style={{
            padding: "0px",
            overflow: "auto",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            {params.id > 0 ? "Update" : "Save"}
          </Button>
        </Toolbar>
      </Paper>
    </>
  );
};

export default EditExternalCalibration;
