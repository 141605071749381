import { driver } from "driver.js";

export const requestFormListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Request Form list.",
        },
      },
      {
        element: "#request_form_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#request_form_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter your desired search value.",
        },
      },
      {
        element: "#request_form_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#request_form_list_table",
        popover: {
          title: "Discipline Table",
          description:
            "Table displaying discipline details where you can delete Request Form.",
        },
      },
    //   {
    //     element: "#request_form_list_delete",
    //     popover: {
    //       title: "Delete Request Form ",
    //       description: "Click this button to delete the Request Form .",
    //     },
    //   },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage Request Form .",
        },
      },
    ],
  });
};
