import { driver } from "driver.js";

export const feedbackListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Feedback list.",
        },
      },
      {
        element: "#feedback_list_createbtn",
        popover: {
          title: "Create new Feedback",
          description: "When click this button you can create new Feedback.",
        },
      },
      {
        element: "#feedback_list_datefrom",
        popover: {
          title: "Select Date",
          description: "Select start date.",
        },
      },
      {
        element: "#feedback_list_dateto",
        popover: {
          title: "Select Date",
          description: "Select end date.",
        },
      },
      {
        element: "#feedback_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#feedback_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter your desired search value.",
        },
      },
      {
        element: "#feedback_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#feedback_list_table",
        popover: {
          title: "Feedback Table",
          description:
            "Table displaying Feedback details where you can edit,delte and download feedback.",
        },
      },
      {
        element: "#feedback_list_edit",
        popover: {
          title: "Edit Feedback ",
          description: "Click this button to edit the  Feedback.",
        },
      },
      {
        element: "#feedback_list_downalod",
        popover: {
          title: "Download Feedback ",
          description: "Click this button to download the Feedback.",
        },
      },
      {
        element: "#feedback_list_delete",
        popover: {
          title: "Delete Feedback ",
          description: "Click this button to delete the Feedback.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage Feedback.",
        },
      },
    ],
  });
};

export const createfeedbackGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Create/Edit Feedback.",
        },
      },
      {
        element: "#create_feedback_selectcomny",
        popover: {
          title: "Select Organization",
          description:
            "Select an organization using the dropdown below. Information such as address, phone number, and email will be fetched automatically.",
        },
      },
      {
        element: "#create_feedback_savebtn",
        popover: {
          title: "Save/Edit Feedback",
          description:
            "When all fields are filled then you can save Feedback.(this shows in edit/create feedback)",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create/edit Feedback.",
        },
      },
    ],
  });
};
