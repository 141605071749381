import React from "react";
import { createTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
export default function CButton(props) {
    const { enqueueSnackbar } = useSnackbar();
    const theme = createTheme({
        palette: {
            primary: {
                light: "#757ce8",
                main: "#3f50b5",
                dark: "#002884",
                contrastText: "#fff",
            },
            secondary: {
                light: "#ff7961",
                main: "#f44336",
                dark: "#ba000d",
                contrastText: "#000",
            },
        },
    });
    return (
        <Button
            variant="contained"
            sx={{ m: 0 }}
            style={theme.secondary}
            onClick={() => {
                enqueueSnackbar(props.message, {
                    variant: props.variant,
                });
            }}
            {...props}
        >
            {props.label}
        </Button>
    );
}
