import { configureStore } from "@reduxjs/toolkit";

import typeAReducer from "../components/typeA/typeASlice";
import loginSlice from "../components/login/loginSlice";
import globalDataSlice from "../components/globalDataSlice";
import enquirySlice from "../components/master/enquiry/enquirySlice";

function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('reduxState', serializedState);
  } catch (error) {
    console.error('Could not save state', error);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (error) {
    console.error('Could not load state', error);
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

export const store = configureStore({
  reducer: {
    login: loginSlice,
    typeA: typeAReducer,
    globalData: globalDataSlice,
    enquiry: enquirySlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), //.concat(middlewares), 
  persistedState 
});
store.subscribe(() => saveToLocalStorage(store.getState()));
