import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { ClassicTable } from "../../../utils/components/Styles";
import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Tooltip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { editSupportiveInstrumentGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

export default function UpdateSupportiveInstruments(props) {
  const editSupportiveInstrumentGuides = editSupportiveInstrumentGuide();
  const [unitArray, setUnitArray] = React.useState([]);
  const [standardName, setStandardName] = React.useState();
  const [standardRanges, setStandardRanges] = React.useState([]);
  const [id, setId] = React.useState("");
  const params = useParams();
  var [readingRows, setReadingRows] = React.useState([["", "", "", "", ""]]);
  const [readingRangeValue, setReadingRangeValue] = React.useState([
    ["", "", "", "", ""],
  ]);

  var readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr No",
        editable: false,
      },
      {
        field: "range",
        headerName: "Range",
        editable: true,
      },
      {
        field: "axialUniformity",
        headerName: "Axial Uniformity",
        editable: true,
      },
      {
        field: "radialUniformity",
        headerName: "Radial Uniformity",
        editable: true,
      },
      {
        field: "stability",
        headerName: "Stability",
        editable: true,
      },

      {
        field: "Action",
      },
    ],
  ];

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };
  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
    let newReadingRowss = [...readingRangeValue];
    newReadingRowss.push([null, null, null, null, null]);
    setReadingRangeValue([...newReadingRowss]);
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    let deleteed = newReadingRows[rowIndex];
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);

    axiosWithToken
      .patch(BASE_URL + `standardRanges/${deleteed[0]}`, { status: -1 })
      .then((res) => {
        toast.success("Row deleted successfully!");
      });
  };

  const onSubmit = (event) => {
    let url = BASE_URL;
    var data = {
      query: `UPDATE standards
      SET standardName = '${standardName}', stId = '${id}'
      WHERE id = ${params.id};`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        var rows = readingRows.map((row, i) => ({
          id: row[0],
          rangeName:
            readingRangeValue[i][1] != null && row[1] != ""
              ? row[1] + "#" + readingRangeValue[i][1]
              : row[1],
          axialUniformity:
            readingRangeValue[i][2] != null && row[2] != ""
              ? row[2] + "#" + readingRangeValue[i][2]
              : row[2],
          radialUniformity:
            readingRangeValue[i][3] != null && row[3] != ""
              ? row[3] + "#" + readingRangeValue[i][3]
              : row[3],
          stability:
            readingRangeValue[i][4] != null && row[4] != ""
              ? row[4] + "#" + readingRangeValue[i][4]
              : row[4],
        }));

        let patch = [];
        let post = [];
        rows.map((row) => {
          if (row.id) {
            patch.push(row);
          } else {
            delete row.id;
            row.standardId = params.id;
            post.push(row);
          }
        });
        Promise.all(
          patch
            .map((row) => {
              return axiosWithToken
                .patch(BASE_URL + `standardRanges/${row.id}`, row)
                .then((res) => {
                  return res;
                })
                .catch((error) => {
                  if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
                  return error;
                });
            })
            .concat(
              post.length > 0
                ? [
                    axiosWithToken
                      .post(BASE_URL + `standardRanges/bulk`, post)
                      .then((res) => {
                        return res;
                      })
                      .catch((error) => {
                        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
                        return error;
                      }),
                  ]
                : []
            )
        ).then((res) => {
          props.setLoader(false);
          toast.success("Supportive instrument updated successfully!");
        });
      })
      .catch((error) => {
        // props.setLoader(false);
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  // data operations

  const getStandard = () => {
    let url = BASE_URL;
    var data = {
      query: `SELECT * FROM standards WHERE type=2 and id=${params.id}`,
    };
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setStandardName(res.data[0].standardName);
        setId(res.data[0].stId);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error("Something Went Wrong with standards!");
        }
      });
  };
  const getStandardReqading = () => {
    axiosWithToken
      .get(
        BASE_URL +
          `standardRanges?_where=(standardId,eq,${params.id})~and(status,eq,1)`
      )
      .then((res) => {
        setStandardRanges(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong while fetching standardRanges!");
        }
      });
  };
  const initiateRanges = () => {
    let rows = [];
    let rowsSpace = [];
    for (let i = 0; i < standardRanges.length; i++) {
      rowsSpace.push([
        null,
        standardRanges[i].rangeName && standardRanges[i].rangeName.split("#")[1]
          ? standardRanges[i].rangeName.split("#")[1]
          : null,
        standardRanges[i].axialUniformity &&
        standardRanges[i].axialUniformity.split("#")[1]
          ? standardRanges[i].axialUniformity.split("#")[1]
          : null,
        standardRanges[i].radialUniformity &&
        standardRanges[i].radialUniformity.split("#")[1]
          ? standardRanges[i].radialUniformity.split("#")[1]
          : null,
        standardRanges[i].stability && standardRanges[i].stability.split("#")[1]
          ? standardRanges[i].stability.split("#")[1]
          : null,
      ]);
      rows.push([
        standardRanges[i].id,
        standardRanges[i].rangeName && standardRanges[i].rangeName.split("#")[0]
          ? standardRanges[i].rangeName.split("#")[0]
          : "",
        standardRanges[i].axialUniformity &&
        standardRanges[i].axialUniformity.split("#")[0]
          ? standardRanges[i].axialUniformity.split("#")[0]
          : "",
        standardRanges[i].radialUniformity &&
        standardRanges[i].radialUniformity.split("#")[0]
          ? standardRanges[i].radialUniformity.split("#")[0]
          : "",
        standardRanges[i].stability && standardRanges[i].stability.split("#")[0]
          ? standardRanges[i].stability.split("#")[0]
          : "",
      ]);
    }
    setReadingRows(rows);
    setReadingRangeValue(rowsSpace);
  };
  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray([
          {
            id: -1,
            disciplineId: null,
            symbol: "",
            status: 1,
          },
          ...res.data,
        ]);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  useEffect(() => {
    initiateRanges();
  }, [standardRanges]);
  useEffect(() => {
    getUnitMasterArray();
    getStandard();
    getStandardReqading();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Edit Supportive Instrument{" "}
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              editSupportiveInstrumentGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="supportive_instrument_edit_name"
            label="Name"
            size="small"
            fullWidth
            variant="outlined"
            value={standardName}
            onChange={(e) => {
              setStandardName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            id="supportive_instrument_edit_id"
            label="id"
            size="small"
            fullWidth
            variant="outlined"
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />

      <Button
        id="supportive_instrument_edit_addButton"
        style={{ float: "right", marginBottom: "15px" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
        variant="contained"
        size="small"
      >
        Add ranges
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (cellIndex === 0) return "";
                    return (
                      <TableCell>
                        <TextField
                          id="supportive_instrument_edit_entervalue"
                          size="small"
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                        ></TextField>

                        <select
                          id="supportive_instrument_edit_selectunit"
                          styles={{ width: "100%" }}
                          defaultValue={readingRangeValue[index][cellIndex]}
                          value={readingRangeValue[index][cellIndex]}
                          onChange={(e) => {
                            updateRangeCellValue(
                              index,
                              cellIndex,
                              e.target.value
                            );
                          }}
                          disabled={cellIndex === 0}
                        >
                          {unitArray.map((option) => (
                            <option value={option.symbol}>
                              {option.symbol}
                            </option>
                          ))}
                        </select>
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <DeleteIcon
                      id="supportive_instrument_edit_deltebtn"
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      <br />

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="supportive_instrument_edit_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          update
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
