var metric, imperial;

metric = {
  pH: {
    name: {
      singular: "pH",
      plural: "pH",
    },
    to_anchor: 1, // pH is a unitless measure
  },
};

imperial = {
  pH: {
    name: {
      singular: "pH",
      plural: "pH",
    },
    to_anchor: 1, // pH remains the same across systems
  },
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "pH",
      ratio: 1, // No conversion needed
    },
    imperial: {
      unit: "pH",
      ratio: 1, // No conversion needed
    },
  },
};
