import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import EditWrap from "../../commonComponent/editWrap";


export default function CreateExpense() {
  const [srfObject, createSrfObject] = React.useState({});
  const [executing, setExecuting] = React.useState(false);
  const [clientArray, setClientArray] = React.useState([]);
  const refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    setExecuting(true);
    let row = {
      clientId: srfObject.clientId,
      expenseDate: moment(srfObject.expenseDate).format("YYYY-MM-DD"),
      amount: srfObject.amount,

      paymentMode: srfObject.paymentMode,
      nameOfPerson: srfObject.nameOfPerson,
      reason: srfObject.reason,
      createdBy: localStorage.getItem("id"),
    };

    let url = BASE_URL;
    axiosWithToken
      .post(url + "expenses", row)
      .then((res) => {
        toast("Expense created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  const getClientIdList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };
  var pushCArray = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
  }

  useEffect(() => {
    getClientIdList();
  }, []);

  return (
    <EditWrap>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create New Expense
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => <TextField {...params} label="Client *" />}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "clientId",
                parseInt(newInputValue.split(",")[0])
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
             slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Expense Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.expenseDate ? srfObject.expenseDate : new Date()}
              onChange={(newValue) => {
                updateSrfObject("expenseDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="outlined-basic"
            label="Amount *"
            size="small"
            inputProps={{
              autoComplete: 'off',
            }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("amount", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={[
              { id: 1, label: "cash" },
              { id: 2, label: "cheque" },
              { id: 3, label: "NEFT" },
              { id: 4, label: "upi" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Payment Mode *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject("paymentMode", newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="outlined-basic"
            label="Name Of Person *"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: 'off',
            }}
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("nameOfPerson", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="outlined-basic"
            label="Reason *"
            size="small"
            inputProps={{
              autoComplete: 'off',
            }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("reason", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          disabled={executing}
          sx={{ m: 0 }}
          onClick={() => {
            if (!srfObject.clientId) {
              toast.error('Please select a client');
              return;
            }
            if (!srfObject.amount) {
              toast.error('amount is required');
              return;
            }
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </EditWrap>
  );
}
