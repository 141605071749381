
import { BASE_URL } from "../../global";
import axiosWithToken from "../components/axiosTokenConfig";

// state and percentate
// Inword Status	: 25%
// DataSheet Status: 60%
// Approve Status: 80%
// Dispatched Status: 90%
// Invoice Status: 100%

export async function createSRFAnalytics(id, srfInstrumentId, progressPercent) {
  try {
    for (const insID of srfInstrumentId) {
      const url = `${BASE_URL}dynamic`;

      const insertQueryPayload = {
        query: `INSERT INTO srfAnalytics (srfId, srfInstrumentId, progressPercent) VALUES (${id}, ${insID}, ${progressPercent})`,
      };
      await axiosWithToken.post(url, insertQueryPayload);
    }
  } catch (error) {
    console.error("Error creating createSRFAnalytics", error);
  }
}

export async function updateSRFAnalytics(srfInstrumentId, progressPercent) {
  try {
    for (const insID of srfInstrumentId) {
      const url = `${BASE_URL}dynamic`;

      const insertQueryPayload = {
        query: `update srfAnalytics set progressPercent = ${progressPercent} where srfInstrumentId=${insID}`,
      };
      await axiosWithToken.post(url, insertQueryPayload);
    }
  } catch (error) {
    console.error("Error while updating createSRFAnalytics, error: ", error);
  }
}

// TODO: to delete once the above function is tested
// export async function updateSRFAnalytics(id) {
//   try {
//     let progressPercent = 0;

//     const url = `${BASE_URL}dynamic`;

//     const findQueryPayload = {
//       query: `SELECT serviceReqNumber FROM srfs WHERE id = ${id}`,
//     };
//     const response1 = await axiosWithToken.post(url, findQueryPayload);
//     const serviceReqNumber = response1.data?.[0]?.serviceReqNumber;

//     const findInstrumentIDSPayload = {
//       query: `SELECT srfInstrumentId FROM datasheets WHERE serviceReqNumber = '${serviceReqNumber}'`,
//     };
//     const InstrumentIDS = await axiosWithToken.post(url, findInstrumentIDSPayload);
//     const InstIDS = InstrumentIDS.data;
//     const srfInstrumentIdsArray = [
//       ...new Set(InstIDS?.map((item) => item.srfInstrumentId)),
//     ];

//     for (const instrumentId of srfInstrumentIdsArray) {
//       const countQueryPayload = {
//         query: `SELECT COUNT(*) FROM srfAnalytics WHERE srfId = ${id} AND srfInstrumentId = ${instrumentId}`,
//       };

//       const response = await axiosWithToken.post(url, countQueryPayload);
//       const count = response.data?.[0]?.["COUNT(*)"];
//       // console.log("ID and count",id,count)

//       if (count == 0) {
//         const insertQueryPayload = {
//           query: `INSERT INTO srfAnalytics (srfId, srfInstrumentId) VALUES (${id}, ${instrumentId})`,
//         };
//         await axiosWithToken.post(url, insertQueryPayload);
//       }

//       progressPercent += 25;

//       const response2Payload = {
//         query: `SELECT totalReadings FROM datasheets WHERE serviceReqNumber = '${serviceReqNumber}' AND srfInstrumentId = ${instrumentId}`,
//       };
//       const response2 = await axiosWithToken.post(url, response2Payload);
//       const totalReadings = response2?.data?.map((item) => item.totalReadings);
//       if (totalReadings.some((element) => element > 0)) {
//         progressPercent += 25;
//       }

//       // console.log("progressPercentResponse2",progressPercent)
//       const response3Payload = {
//         query: `SELECT approvedby FROM certificates WHERE serviceReqNumber = '${serviceReqNumber}' AND srfInstrumentId = ${instrumentId}`,
//       };
//       const response3 = await axiosWithToken.post(url, response3Payload);
//       const approvedby = response3?.data;
//       if (approvedby?.some((item) => item.approvedby !== null)) {
//         progressPercent += 25;
//       }

//       // console.log("progressPercentResponse3",progressPercent)
//       const response4Payload = {
//         query: `SELECT instrumentIds FROM dispatch WHERE srfsId = ${id}`,
//       };
//       const response4 = await axiosWithToken.post(url, response4Payload);
//       const instrumentIds = response4.data?.[0]?.instrumentIds;
//       const ArrayInstrumentIds = instrumentIds ? instrumentIds.split(",") : [];
//       if (ArrayInstrumentIds.includes(`${instrumentId}`)) {
//         progressPercent += 25;
//       }
//       console.log("ArrayInstrumentIds", ArrayInstrumentIds);

//       const UpdateDataPayload = {
//         query: `UPDATE srfAnalytics SET progressPercent = ${progressPercent} WHERE srfId = ${id} AND srfInstrumentId = ${instrumentId}`,
//       };
//       const UpdateData = await axiosWithToken.post(url, UpdateDataPayload);

//       console.log("progressPercent", progressPercent);
//     }
//   } catch (error) {
//     console.error("Error updateing createSRFAnalytics", error);
//   }
// }
