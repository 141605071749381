import { driver } from "driver.js";

export const usersListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Users list.",
        },
      },
      {
        element: "#users_list_createbtn",
        popover: {
          title: "Create new Users",
          description: "When click this button you can create new Users.",
        },
      },
      {
        element: "#users_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#users_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter your desired search value.",
        },
      },
      {
        element: "#users_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#users_list_table",
        popover: {
          title: "Users Table",
          description:
            "Table displaying Users details where you can delete and edit Users.",
        },
      },
      {
        element: "#users_list_edit",
        popover: {
          title: "Edit Users ",
          description: "Click this button to edit the  Users.",
        },
      },
      {
        element: "#users_list_delete",
        popover: {
          title: "Delete Users ",
          description: "Click this button to delete the Users.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage Users.",
        },
      },
    ],
  });
};

export const createUserGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Create/Edit Users.",
        },
      },
      {
        element: "#create_users_username",
        popover: {
          title: "Enter / Edit Username",
          description: "You can create/edit a username.",
        },
      },
      {
        element: "#create_users_password",
        popover: {
          title: "Enter / Edit Password",
          description: "You can create/edit a password.",
        },
      },
      {
        element: "#create_users_usertype",
        popover: {
          title: "Select User Type",
          description:
            "You can select a user type. (This is shown only when creating a user, not when editing)",
        },
      },
      {
        element: "#create_users_roles",
        popover: {
          title: "Select Roles",
          description: "You can select user roles.",
        },
      },
      {
        element: "#create_users_branch",
        popover: {
          title: "Select Branch",
          description: "You can select the branch the user can access.",
        },
      },
      {
        element: "#create_users_modules",
        popover: {
          title: "Select Modules",
          description: "You can select the user modules they can access.",
        },
      },
      {
        element: "#create_users_branchapply",
        popover: {
          title: "Branch Access",
          description: "When the user type is an engineer, this table shows...",
        },
      },
      {
        element: "#create_users_savebtn",
        popover: {
          title: "Save/Edit User",
          description:
            "When all fields are filled, this button will be active, and you can save users.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create/edit users.",
        },
      },
    ],
  });
};
