import { Box } from "@mui/material";
import React from "react";

const EditWrap = ({ children }) => {
  return (
    <Box style={{ margin: "0px 1rem", paddingBottom: "2rem", }}>{children}</Box>
  );
};

export default EditWrap;
