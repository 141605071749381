import React, { useEffect } from "react";
import MemoryIcon from "@mui/icons-material/Memory";
import { Button, Tooltip, Typography } from "@mui/material";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";
import ResourceModel from "../trainingResources/resourceModel";

export default function ViewUploadedTrainingFile({ certificateId }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [standardMasterArray, setStandardMasterArray] = React.useState([]);
  // Resource Modal state
  const [openModalResource, setOpenModalResource] = React.useState(false);
  const [modalResourceUrl, setModalResourceUrl] = React.useState("");
  const handleOpenModalResource = (url) => {
    setModalResourceUrl(url);
    setOpenModalResource(true);
  };
  const handleCloseModalResource = () => {
    setOpenModalResource(false);
    setModalResourceUrl("");
  };

  function getStandards() {
    axiosWithToken
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(datasheetId,eq,${certificateId})`
      )
      .then((response) => {
        if (response?.data) {
          let standardIds = response?.data
            ?.map((e) => e.standardRanges)
            .filter(Boolean);
          const selectedStandardIds = [];
          standardIds.forEach((str) => {
            str.split(",").forEach((sub) => {
              const num = parseInt(sub.split(":")[0]);
              if (!selectedStandardIds.includes(num)) {
                selectedStandardIds.push(num);
              }
            });
          });

          if (selectedStandardIds) {
            let combineArray = [...new Set(selectedStandardIds)];
            axiosWithToken
              .get(
                BASE_URL + `standards?_where=(id,in,${combineArray.toString()})`
              )
              .then((res) => setStandardMasterArray(res.data))
              .catch((err) => console.log(err));
          }
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getStandards();
  }, []);

  return (
    <div>
      {standardMasterArray?.map((option, index) => (
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Typography variant="h6">{option?.standardName}</Typography>
          <Tooltip title={option?.standardName} placement="top-start">
            <Button
              onClick={() => {
                if (option.certificateUrl) {
                  handleOpenModalResource(option.certificateUrl);
                }
              }}
            >
              <MemoryIcon />
            </Button>
          </Tooltip>
        </div>
      ))}

      <ResourceModel
        open={openModalResource}
        onClose={handleCloseModalResource}
        resourceUrl={modalResourceUrl}
      />
    </div>
  );
}
