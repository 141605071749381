import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { fetchAddresses } from "../invoice/productDetails";

export const fetchCertificateStaticTables = async (
  instrumentId,
  deletedTableIds
) => {
  try {
    const url = `${BASE_URL}certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`;
    const response = await axiosWithToken.get(url);

    let data = response.data;
    if (deletedTableIds) {
      const deletedIdsArray = deletedTableIds
        .split(",")
        .map((id) => Number(id));
      data = data.filter((row) => !deletedIdsArray.includes(row.id));
    }

    data = data.map((table) => ({
      ...table,
      defaultConfiguration: JSON.parse(table?.defaultConfiguration || null),
    }));

    return data;
  } catch (error) {
    console.error("Error fetching certificate static tables:", error);
    return null;
  }
};

export const fetchCertificateDetails = async (certificateId) => {
  const url = `${BASE_URL}dynamic`;
  const sqlQuery = {
    query: `select * from certificatesView where id = ${certificateId}`,
  };

  const response = await axiosWithToken.post(url, sqlQuery);
  return response.data.length > 0 ? response.data[0] : null;
};

const fetchUserDetails = async (userIds) => {
  if (!userIds) return {};

  const url = `${BASE_URL}users?_where=(id,in,${userIds})`;
  const response = await axiosWithToken.get(url);

  const userMap = Object.fromEntries(
    response.data.map((user) => [user.id, user])
  );
  return userMap;
};

const fetchDatasheetStaticReadings = async (datasheetId) => {
  const url = `${BASE_URL}datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})`;
  const response = await axiosWithToken.get(url);
  return response.data;
};

const fetchBillingDetails = async (billingId) => {
  if (!billingId) return null;

  const url = `${BASE_URL}clients/${billingId}`;
  const response = await axiosWithToken.get(url);
  return response.data?.[0]?.companyName || null;
};

const fetchStandards = async (standardIds) => {
  if (standardIds.length === 0) return [];

  const url = `${BASE_URL}standards?_where=(id,in,${standardIds.join(",")})`;
  const response = await axiosWithToken.get(url);
  return response.data;
};

// Main Function
export const fetchCertificate = async (certificateId) => {
  try {
    const certificateDetails = await fetchCertificateDetails(certificateId);
    if (!certificateDetails) return null;

    // Fetch User Details
    const userIds = [
      certificateDetails.calibratedby,
      certificateDetails.approvedby,
      certificateDetails.rejectedBy,
      certificateDetails.reviewedBy,
      certificateDetails.signedBy,
      certificateDetails.witnessBy,
    ]
      .filter(Boolean)
      .join(",");

    const userMap = await fetchUserDetails(userIds);

    const userDetails = {
      calibratedBy: userMap[certificateDetails.calibratedby] || null,
      approvedBy: userMap[certificateDetails.approvedby] || null,
      rejectedBy: userMap[certificateDetails.rejectedBy] || null,
      reviewedBy: userMap[certificateDetails.reviewedBy] || null,
      signedBy: userMap[certificateDetails.signedBy] || null,
      witnessedBy: userMap[certificateDetails.witnessBy] || null,
    };

    // Fetch Datasheet Static Readings
    const datasheetStaticReadings = await fetchDatasheetStaticReadings(
      certificateId
    );

    // Extract Standard IDs and Fetch Standards
    const standardIds = [
      ...new Set(
        datasheetStaticReadings
          .map((e) => e.standardRanges)
          .filter(Boolean)
          .flatMap((range) =>
            range.split(",").map((entry) => parseInt(entry.split(":")[0]))
          )
      ),
    ];

    const standards = await fetchStandards(standardIds);

    // Fetch Billing Details
    const clientName =
      (await fetchBillingDetails(certificateDetails.billingId)) ||
      certificateDetails.companyName ||
      null;

      let splitAddress = certificateDetails?.address?.split(",");
      let newAddressArray = await fetchAddresses(splitAddress?.[0], "0");
      let updateAddress = newAddressArray?.[0];
      let extraColumnsParse = Object.fromEntries(Object.entries(JSON.parse(certificateDetails?.extraColumns)).filter(([_, value]) => value !== ""));

      let updateCertificateDetails = {
        ...certificateDetails,
        newAddressArray :updateAddress,
        extraColumnsParse
      };
    return {
      certificateDetails : updateCertificateDetails,
      userDetails,
      datasheetStaticReadings,
      standards,
      clientName,
    };
  } catch (error) {
    console.error("Error fetching certificate details:", error);
    return null;
  }
};

export const fetchInstrument = (instrumentId) => {
  const url = `${BASE_URL}instruments?_where=(id,eq,${instrumentId})`;

  return axiosWithToken
    .get(url)
    .then((res) => {
      if (res?.data?.length) {
        return res.data[0];
      } else {
        console.warn(
          "No instrument data found for the given ID:",
          instrumentId
        );
        return null;
      }
    })
    .catch((err) => {
      console.error("Instrument data fetching error:", err);
      return { error: err.message };
    });
};

export const certificateCount = (certificateId) => {
  const url = `${BASE_URL}dynamic`;
  const data = {
    query: `SELECT * FROM certificateAnalytics WHERE certificateId = ${certificateId}`,
  };

  return axiosWithToken
    .post(url, data)
    .then((res) => {
      if (res?.data?.length) {
        return res.data[0];
      } else {
        console.warn(
          "No certificate analytics data found for the given ID:",
          certificateId
        );
        return null;
      }
    })
    .catch((err) => {
      console.error("Certificate analytics data fetching error:", err);
      return { error: err.message };
    });
};

export const getOpinionAndAnalysisList = (certificateId) => {
  const url = `${BASE_URL}dynamic`;
  const payload = {
    query: `SELECT * FROM opinionAndAnalysis WHERE certificateId = ${certificateId}`,
  };

  return axiosWithToken
    .post(url, payload)
    .then((res) => {
      if (res.data.length > 0) {
        const parsedData = {
          ...res.data[0],
          accepted: convertToBooleanOrEmpty(res.data[0].accepted),
          limitedUse: convertToBooleanOrEmpty(res.data[0].limitedUse),
          calibrated: convertToBooleanOrEmpty(res.data[0].calibrated),
          rejected: convertToBooleanOrEmpty(res.data[0].rejected),
        };
        return parsedData;
      } else {
        console.warn(
          "No opinion and analysis data found for the given certificate ID:",
          certificateId
        );
        return null;
      }
    })
    .catch((err) => {
      console.error("Error fetching opinion and analysis data:", err);
      return { error: err.message };
    });
};

const convertToBooleanOrEmpty = (value) => {
  if (value === "1") return true;
  if (value === "") return "";
  return false;
};

export const fetchSettings = () => {
  return axiosWithToken
    .get(`${BASE_URL}settings?_where=(status,eq,1)`)
    .then((res) => {
      const settings = res.data;
      let remark = null,
        complance = false,
        calTypeSetting = false,
        clientSetting = false,
        companyHierarchy = false,
        _rolesSettings = {},
        _settingList = {};

      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === "remarks") {
          remark = setting?.value;
        } else if (
          setting?.keyName === "Compliance Status" &&
          setting?.value?.toLowerCase() === "true"
        ) {
          complance = true;
        } else if (
          setting?.keyName === "Client Settings" &&
          setting?.value?.toLowerCase() === "true"
        ) {
          clientSetting = true;
        } else if (
          setting?.keyName === "companyHierarchy" &&
          setting.status == 1
        ) {
          companyHierarchy = true;
        } else if (
          setting?.keyName === "Calibration Type" &&
          setting?.value?.toLowerCase() === "true"
        ) {
          calTypeSetting = true;
        }

        if (setting?.keyName?.startsWith("activeRole")) {
          _rolesSettings[setting?.keyName?.split("activeRole")[1]] = {
            name: setting?.value,
            id: setting?.keyName?.split("activeRole")[1],
          };
        }

        _settingList[setting?.keyName] = setting?.value;
      });

      _settingList["amendment"] = _settingList["amendment"]?.toLowerCase();

      return {
        settingsList: _settingList,
        compliance: complance,
        isClientSettings: clientSetting,
        isCompanyHierarchy: companyHierarchy,
        calTypeSetting: calTypeSetting,
        rolesSettings: _rolesSettings,
      };
    })
    .catch((err) => {
      console.error("Error fetching settings:", err);
      return { error: err.message };
    });
};

export const fetchStaticReadingRows = async (datasheetId) => {
  try {
    const url = `${BASE_URL}datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})`;
    const response = await axiosWithToken.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching static reading rows:", error);
    return null;
  }
};
