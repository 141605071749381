import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { viewsdispatchEditGuide } from "./guide";
import axiosWithToken from "../../utils/components/axiosTokenConfig";

export default function EditDispatch() {
  const viewsdispatchEditGuides = viewsdispatchEditGuide();
  const [companyName, setCompanyName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [instruments, setInstruments] = React.useState("");
  const [dispatchMedium, setDispatchMedium] = React.useState("");

  const params = useParams();

  const getDispatchIdList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `dispatch/${params.id}`)
      .then((res) => {
        setCompanyName(res.data[0].companyName);
        setAddress(res.data[0].address);
        setInstruments(res.data[0].instrumentIds);
        setDispatchMedium(res.data[0].dispatchMedium);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };

  useEffect(() => {
    getDispatchIdList();
  }, []);

  const onSubmit = (id) => {
    var jsonData = {
      companyName: companyName,
      address: address,
      instrumentIds: instruments,
      dispatchMedium: dispatchMedium,
    };
    let url = BASE_URL;

    var refresh = () => {
      window.location.reload(false);
    };
    axiosWithToken
      .patch(url + `dispatch/${params.id}`, jsonData)
      .then((res) => {
        toast("updated Dispacth!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
      });
  };
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              viewsdispatchEditGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Dispatch
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Client *"
            size="small"
            disabled
            value={companyName}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            value={address}
            disabled
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="srf-viewdispatchedit_instruments"
            label="Instruments"
            size="small"
            value={instruments}
            fullWidth
            variant="outlined"
            onChange={(e) => setInstruments(e.target.value)}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="srf-viewdispatchedit_dispatchmedium"
            label="Dispatch Medium *"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={dispatchMedium}
            fullWidth
            variant="outlined"
            onChange={(e) => setDispatchMedium(e.target.value)}
          />
        </Grid>
      </Grid>

      <hr />

      <Toolbar style={{ padding: "0px" }}>
        <Button
          id="srf-viewdispatchedit_dispatchsave"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            // enqueueSnackbar("Standard added successfully !", {
            //     variant: "success",
            // });
            onSubmit();
          }}
        >
          Edit Product
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
