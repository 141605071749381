import moment from "moment";
import unitConvertor from "./components/unitConvertor";

// props : {
//  branch
//  discipline,
//  calibrationProcedureNo
// }
export const generateNumber = (format = "", uid, props) => {
  let isentryDate = props?.entryDate ? new Date(props?.entryDate) : new Date();
  if (format.includes("DDMMYYYY")) {
    format = format.replace("DDMMYYYY", moment(isentryDate).format("DDMMYYYY"));
  }
  if (format.includes("MM/YYYY")) {
    format = format.replace("MM/YYYY", moment(isentryDate).format("MM/YYYY"));
  }
  if (format.includes("DDMMYY")) {
    format = format.replace("DDMMYY", moment(isentryDate).format("DDMMYY"));
  }
  if (format.includes("YYYYMMDD")) {
    format = format.replace("YYYYMMDD", moment(isentryDate).format("YYYYMMDD"));
  }
  if (format.includes("YYYY/MM/DD")) {
    format = format.replace(
      "YYYY/MM/DD",
      moment(isentryDate).format("YYYY/MM/DD")
    );
  }
  if (format.includes("YYMMDD")) {
    format = format.replace("YYMMDD", moment(isentryDate).format("YYMMDD"));
  } else if (format.includes("MMYYYY")) {
    format = format.replace("MMYYYY", moment(isentryDate).format("MMYYYY"));
  } else if (format.includes("DD/MM/YYYY")) {
    format = format.replace(
      "DD/MM/YYYY",
      moment(isentryDate).format("DD/MM/YYYY")
    );
  } else if (format.includes("DD/MM/YY")) {
    format = format.replace("DD/MM/YY", moment(isentryDate).format("DD/MM/YY"));
  } else if (format.includes("YYYY-DD-MM")) {
    format = format.replace(
      "YYYY-DD-MM",
      moment(isentryDate).format("YYYY-DD-MM")
    );
  } else if (format.includes("YYMM")) {
    format = format.replace("YYMM", moment(isentryDate).format("YYMM"));
  } else if (format.includes("DD-MM-YY")) {
    format = format.replace("DD-MM-YY", moment(isentryDate).format("DD-MM-YY"));
  } else if (format.includes("MM-YY")) {
    format = format.replace("MM-YY", moment(isentryDate).format("MM-YY"));
  } else if (format.includes("YYYY/MM")) {
    format = format.replace("YYYY/MM", moment(isentryDate).format("YYYY/MM"));
  } else if (format.includes("YYYY")) {
    format = format.replace("YYYY", moment(isentryDate).format("YYYY"));
  } else if (format.includes("DDYY")) {
    format = format.replace("DDYY", moment(isentryDate).format("DDYY"));
  } else if (format.includes("DDMM")) {
    format = format.replace("DDMM", moment(isentryDate).format("DDMM"));
  } else if (format.includes("MMYY")) {
    format = format.replace("MMYY", moment(isentryDate).format("MMYY"));
  } else if (format.includes("MM/YY")) {
    format = format.replace("MM/YY", moment(isentryDate).format("MM/YY"));
  } else if (format.includes("M/YY")) {
    format = format.replace("M/YY", moment(isentryDate).format("M/YY"));
  }

  let no = format.indexOf("{no}");
  let digits = 0;
  if (no !== -1 && /[\d]/.test(format.slice(no + 4))) {
    digits = Number(
      format
        .slice(no + 4)
        ?.split("]")[0]
        ?.split("[")?.[1]
    );
    format = format.replace(`[${digits}]`, "");
  }
  format = format.replace(
    "{no}",
    digits ? `${uid}`.padStart(digits, "0") + "F" : uid
  );
  no = format.indexOf("{no}");
  format = format.replace("{branch}", props?.branch);
  format = format.replace("{discipline}", props?.discipline);
  format = format.replace(
    "{calibrationProcedureNo}",
    props?.calibrationProcedureNo
  );
  return format;
};

export const getFormattedDates = (from, to) => {
  const fromDate = moment(from)
    .startOf("day")
    .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
  const toDate = moment(to).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  return { fromDate, toDate };
};

export function timeStringToTimestamp(timeString, format = "h:min:s:ms") {
  let checks = {
    hour: true,
    minute: true,
    second: true,
    millisecond: true,
  };
  let hours, minutes, seconds, milliseconds;
  if (format == "min:s:ms") {
    checks.hour = false;
    [minutes, seconds, milliseconds] = String(timeString)
      ?.split(":")
      ?.map(Number);
  }
  if (format == "h:min:s") {
    checks.millisecond = false;
    [hours, minutes, seconds] = String(timeString)?.split(":")?.map(Number);
  } else {
    [hours, minutes, seconds, milliseconds] = String(timeString)
      ?.split(":")
      ?.map(Number);
  }

  let res = 0;
  if (hours > 0) {
    res += hours * 60 * 60 * 1000;
  }
  if (minutes > 0) {
    res += minutes * 60 * 1000;
  }
  if (seconds > 0) {
    res += seconds * 1000;
  }
  if (milliseconds > 0) {
    res += milliseconds;
  }
  return res;
}

export function timestampToTimeString(timestamp, format = "h:min:s:ms") {
  let checks = {
    hour: true,
    minute: true,
    second: true,
    millisecond: true,
  };
  if (format == "min:s:ms") {
    checks.hour = false;
  }
  if (format == "h:min:s") {
    checks.millisecond = false;
  }
  if (format == "min:s") {
    checks.hour = false;
    checks.millisecond = false;
  }

  let timeString = [];

  if (checks.hour) {
    const hours = Math.floor(timestamp / (60 * 60 * 1000));
    timeString.push(String(hours).padStart(2, "0"));
    timestamp %= 60 * 60 * 1000;
  }
  if (checks.minute) {
    const minutes = Math.floor(timestamp / (60 * 1000));
    timeString.push(String(minutes).padStart(2, "0"));
    timestamp %= 60 * 1000;
  }
  if (checks.second) {
    const seconds = Math.floor(timestamp / 1000);
    timeString.push(String(seconds).padStart(2, "0"));
  }
  if (checks.millisecond) {
    const milliseconds = Math.round(timestamp % 1000);
    timeString.push(String(milliseconds).padStart(3, "0"));
  }
  return timeString.join(":");
}

export const angleToTimestamp = (angle) => {
  let [degree, minute, second] = angle.split("-").map(Number);
  return degree * 3600000 + minute * 60000 + second * 1000;
};
export const timestampToAngle = (timestamp) => {
  let degree = Math.floor(timestamp / 3600000);
  timestamp %= 3600000;
  let minute = Math.floor(timestamp / 60000);
  timestamp %= 60000;
  let second = Math.floor(timestamp / 1000);
  return `${degree}-${minute}-${second}`;
};

export const formatSpecialValuesToNumber = (value) => {
  // Replace each timestring (h:min:s:ms) match with its timestamp
  const timeRegex = /\b\d{2}:\d{2}:\d{2}:\d{2,3}\b/g; //h:min:s:ms
  let originalValueType = "number";
  if (timeRegex.test(value)) {
    originalValueType = "h:min:s:ms";
    let timeStrings = value.match(timeRegex);
    timeStrings.forEach((timeString) => {
      value = value.replaceAll(timeString, timeStringToTimestamp(timeString));
    });
  }

  // Replace each timestring (min:s:ms) match with its timestamp
  const timeRegex2 = /^\b\d{2}:\d{2}:\d{2,3}\b/g; //min:s:ms
  if (timeRegex2.test(value)) {
    originalValueType =
      originalValueType == "number" ? "min:s:ms" : originalValueType;
    let timeStrings = value.match(timeRegex);
    timeStrings.forEach((timeString) => {
      value = value.replaceAll(
        timeString,
        timeStringToTimestamp(timeString, "min:s:ms")
      );
    });
  }

  // Replace each timestring (min:s:ms) match with its timestamp
  const timeRegex3 = /^\b\d{2}:\d{2}\b/g; //min:s
  if (timeRegex3.test(value)) {
    originalValueType =
      originalValueType == "number" ? "min:s:ms" : originalValueType;
    let timeStrings = value.match(timeRegex);
    timeStrings.forEach((timeString) => {
      value = value.replaceAll(
        timeString,
        timeStringToTimestamp(timeString, "min:s:ms")
      );
    });
  }

  // Replace each angle match with its timestamp value (preferrign timestamp over radians for precise calculations)
  const angleRegex = /\b\d{1,3}-\d{1,2}-\d{1,2}\b/g;
  if (angleRegex.test(value)) {
    originalValueType =
      originalValueType == "number" ? `° ' "` : originalValueType;
    let angleStrings = value.match(angleRegex);
    angleStrings.forEach((angleString) => {
      value = value.replaceAll(angleString, angleToTimestamp(angleString));
    });
  }
  return [value, originalValueType];
};

export const formatNumberToSpecialValues = (val, desiredValueType) => {
  if (desiredValueType == "h:min:s:ms") {
    val = timestampToTimeString(val);
  } else if (desiredValueType == "min:s:ms") {
    val = timestampToTimeString(val, "min:s:ms");
  } else if (desiredValueType == "min:s") {
    val = timestampToTimeString(val, "min:s");
  } else if (desiredValueType == `° ' "`) {
    val = timestampToAngle(val);
  } else {
    val = unitConvertor(val, "ms", desiredValueType);
  }
  return String(val);
};

// @vishal: plese remove these constants from here its not util
export const indianStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Jammu & Kashmir",
  "Lakshadweep",
  "Chandigarh",
  "Delhi",
  "Ladakh",
  "Puducherry",
];
