import { driver } from "driver.js";

export const viewsdispatchListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this dispatch List.",
        },
      },
      {
        element: "#srf-viewdispatchlist_instruments",
        popover: {
          title: "Select Instrument",
          description: "Select Instrument whoever want to dispatch",
        },
      },
      {
        element: "#srf-viewdispatchlist_dispatchmedium",
        popover: {
          title: "Dispatch Medium",
          description: "Enter Dispatch Medium",
        },
      },
      {
        element: "#srf-viewdispatchlist_dispatchadd",
        popover: {
          title: "Add Dispatch",
          description:
            "When instrument select and Dispatch Medium enter then click this button dispatch will create below list",
        },
      },
      {
        element: "#srf-viewdispatchlist_dispatchchallan",
        popover: {
          title: "Challan Tables",
          description: "Dispatch show in this list",
        },
      },
      {
        element: "#srf-viewdispatchlist_dispatchchallandownload",
        popover: {
          title: "Download Challan",
          description: "Click this button you can download challan",
        },
      },
      {
        element: "#srf-viewdispatchlist_dispatchchallanedit",
        popover: {
          title: "Edit Challan",
          description: "Click this button you can edit challan",
        },
      },
      {
        element: "#srf-viewdispatchlist_dispatchchalland_delete",
        popover: {
          title: "Delete Challan",
          description: "Click this button you can delete challan",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can download or edit challan",
        },
      },
    ],
  });
};
export const viewsdispatchEditGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this dispatch edit.",
        },
      },
      {
        element: "#srf-viewdispatchedit_instruments",
        popover: {
          title: "Select Instrument",
          description: "Select Instrument whoever want to dispatch to edit",
        },
      },
      {
        element: "#srf-viewdispatchedit_dispatchmedium",
        popover: {
          title: "Dispatch Medium",
          description: "edit Dispatch Medium",
        },
      },
      {
        element: "#srf-viewdispatchedit_dispatchsave",
        popover: {
          title: "Save Dispatch",
          description: "Save Dispatch",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can edit dispatch",
        },
      },
    ],
  });
};

export const viewsdispatchGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Lets get started...",
          description:
            "This tour will guide you through all sections and components in this dispatch.",
        },
      },
      {
        element: "#srf-viewdispatch_letterhead",
        popover: {
          title: "Select letterhead",
          description: "Select letterhead with or without",
        },
      },
      {
        element: "#srf-viewdispatch_download",
        popover: {
          title: "Download SRF",
          description:
            "Clicking this button allows you to download the challan.",
        },
      },
      {
        element: "#srf-viewdispatch_print",
        popover: {
          title: "Deilvery Challan Details",
          description: "",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can download challan",
        },
      },
    ],
  });
};
