import React, { useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Rating from "@mui/material/Rating";

import { Grid, TextField, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { BASE_URL } from "../../../global";
import { Today } from "@mui/icons-material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 5,
  width: "85% !important",
};

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 10 },
  { id: "companyName", label: "Client", minWidth: 40 },
  { id: "name", label: "Contact Person Name", minWidth: 40 },
];

function createData(id, companyName, name) {
  return {
    id,
    companyName,
    name,
  };
}

export default function FeedbackReport() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [client, setClient] = React.useState(0);
  const [fromDate, setFromDate] = React.useState(0);
  const [toDate, setToDate] = React.useState(0);
  const [searchData, setSearchData] = React.useState("");
  const [dateData, setDateData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [companyName, setCompanyName] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [clientFedback, setFeedback] = React.useState([]);
  const [p1, setp1] = React.useState("");
  const [p2, setp2] = React.useState("");
  const [p3, setp3] = React.useState("");
  const [p4, setp4] = React.useState("");
  const [p5, setp5] = React.useState("");
  const [p6, setp6] = React.useState("");
  const [p7, setp7] = React.useState("");
  const [p8, setp8] = React.useState("");
  const [date, setDate] = React.useState("");
  const [name, setName] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [firstCol, setFirstCol] = React.useState("");
  const [secondCol, setSecondCol] = React.useState("");
  const ref = useRef(null);

  const handleOpen = (row) => {
    // setTableName(row.tableName)
    let url = BASE_URL;
    axiosWithToken
      .get(url + `feedback?_where=(id,eq,${row.id})`)
      .then((res) => {
        setFeedback(res.data);
        setDate(res.data[0].date);
        setName(res.data[0].name);
        setContactNumber(res.data[0].contactNumber);
        setp1(res.data[0].p1);
        setp2(res.data[0].p2);
        setp3(res.data[0].p3);
        setp4(res.data[0].p4);
        setp5(res.data[0].p5);
        setp6(res.data[0].p6);
        setp7(res.data[0].p7);
        setp8(res.data[0].p8);
        setFirstCol(res.data[0].firstCol);
        setSecondCol(res.data[0].secondCol);
      })
      .catch((error) => {
        toast.error("Something Went Wrong2!");
      });
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var pushArray = [];

  for (let i = 0; i < client.length; i++) {
    pushArray.push({
      label: client[i].companyName,
    });
  }

  const FilterData = () => {
    if (companyName == null) {
      var resultProductData = data.filter(
        (data) =>
          new Date(data.date) >= new Date(fromDate) &&
          new Date(data.date) <= new Date(toDate)
      );
    } else {
      var resultProductData = data.filter(
        (data) =>
          new Date(data.date) >= new Date(fromDate) &&
          new Date(data.date) <= new Date(toDate) &&
          data.client_companyName == companyName.label
      );
    }
    setDateData(resultProductData);
    initializeDataTable(resultProductData);
  };

  // const FilterData = () => {
  //     var resultProductData = data.filter(data => new Date(data.nextDueDate) >= new Date(fromDate) &&
  //      new Date(data.nextDueDate) <= new Date(toDate)
  //      );
  //      console.log(resultProductData);
  //     setDateData(resultProductData);
  //     initializeDataTable(resultProductData);
  // };

  const getData = (event) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "feedback")
      .then((res) => {
        // setDateData(res.data)
        setData(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  var rowData = [];

  for (let i = 0; i < dateData.length; i++) {
    rowData.push(
      createData(dateData[i].id, "BFI", dateData[i].name, dateData[i].rating)
    );
  }

  useEffect(() => {
    getClient();
    getData();
  }, []);

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={fromDate ? fromDate : new Date()}
                onChange={(e) => {
                  setFromDate(e);
                  console.log(fromDate);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={toDate ? toDate : new Date()}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              FilterData();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Search
          </Button>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled
                        label="Date"
                        inputFormat="dd/MM/yyyy"
                        format="dd/MM/yyyy"
                        value={date ? date : new Date()}
                        onChange={(newValue) => {
                          setDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      disabled
                      label="Name"
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      disabled
                      label="Contact Number"
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <br />
                <div className="border-dark p-4 border">
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography component="legend">
                        QUALITY OF SERVICE
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Rating
                        name="no-value"
                        value={p1}
                        onChange={(event, newValue) => {
                          setp1(newValue);
                        }}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography component="legend">
                        TIMELY DELIVERY OF CALIBRATED INSTRUMENTS
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Rating
                        name="no-value"
                        value={p2}
                        onChange={(event, newValue) => {
                          setp2(newValue);
                        }}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography component="legend">
                        TIMELY DELIVERY OF CALIBRATED CERTIFICATES
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Rating
                        name="no-value"
                        value={p3}
                        onChange={(event, newValue) => {
                          setp3(newValue);
                        }}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography component="legend">
                        CALIBRATION REPORTS
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Rating
                        name="no-value"
                        value={p4}
                        onChange={(event, newValue) => {
                          setp4(newValue);
                        }}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography component="legend">
                        ENGINEERS PERFORMANCE AT SITE
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Rating
                        name="no-value"
                        value={p5}
                        onChange={(event, newValue) => {
                          setp5(newValue);
                        }}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography component="legend">
                        RESPONSE TIME OF YOUR ENQUIRIES
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Rating
                        name="no-value"
                        value={p6}
                        onChange={(event, newValue) => {
                          setp6(newValue);
                        }}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography component="legend">
                        CONFORT LEVEL IN DEALING WITH NAGMAN
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Rating
                        name="no-value"
                        value={p7}
                        onChange={(event, newValue) => {
                          setp7(newValue);
                        }}
                      />
                    </Grid>{" "}
                  </Grid>
                  <Grid container spacing={5}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography component="legend">
                        PACKING AND DISPATCH
                      </Typography>
                    </Grid>{" "}
                    <Grid item xs={6}>
                      <Rating
                        name="no-value"
                        value={p8}
                        onChange={(event, newValue) => {
                          setp8(newValue);
                        }}
                      />
                    </Grid>{" "}
                  </Grid>
                </div>
                <br />
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      id="outlined-basic"
                      label="If rated below 3 (Either 2 or 1) please inform us the reason"
                      rows={3}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      value={firstCol}
                      size="small"
                      fullWidth
                      variant="outlined"
                      onChange={(e) => setFirstCol(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      id="outlined-basic"
                      label="Suggestions for improvement in quality and services"
                      rows={3}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      value={secondCol}
                      size="small"
                      fullWidth
                      variant="outlined"
                      onChange={(e) => setSecondCol(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </div>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            id="datatable-keytable"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
                <TableCell>
                  <b>View</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                        >
                          <Tooltip title="View Feedback" placement="top-start">
                            <Button
                              id={row.id}
                              key={row.id}
                              ref={ref}
                              onClick={() => {
                                handleOpen(row);
                              }}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 30, 100]}
            component="div"
            count={rowData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ToastContainer />
        </TableContainer>
      </div>
    </>
  );
}
