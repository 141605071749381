import axios from "axios";
import { BASE_URL } from "../../global";
import { storeAxiosRequestData } from "../../offlineApp/OfflineDatabaseSetup";

const historyData = JSON.parse(sessionStorage.getItem("historicalTable")) || false;

const axiosWithToken = axios.create({
  baseURL: BASE_URL,
});

// List of tables to check for "_hist" suffix
const tables = [
  "datasheetStaticReadings",
  "certificates",
  "datasheets",
  "audit_log",
  "srfInstruments",
  "srfAnalytics",
  "attendance",
  "srfs",
  "certificatesView",
];

// Add an interceptor to modify the request
axiosWithToken.interceptors.request.use(async (config) => {

  if (historyData && config?.url) {
    tables.forEach((table) => {
      const regex = new RegExp(`(?<!\\w)${table}(?!\\w)`); 
      if (regex.test(config.url) && !config.url.includes(`${table}_hist`)) {
        config.url = config.url.replace(regex, `${table}_hist`);
      }
    });
  
    // Handle inner query parameters for "_join" or "_fields"
    if (config.url.includes("?")) {
      const urlParts = config.url.split("?");
      const [urlWithoutParams, queryString] = urlParts;
  
      let modifiedUrl = urlWithoutParams;
  
      tables.forEach((table) => {
        const regex = new RegExp(`(?<!\\w)${table}(?!\\w)`); 
        if (regex.test(urlWithoutParams) && !urlWithoutParams.includes(`${table}_hist`)) {
          modifiedUrl = modifiedUrl.replace(regex, `${table}_hist`);
        }
      });
  
      config.url = modifiedUrl + "?" + queryString; 
    }
  }
  

  // Check if the URL ends with "dynamic" and if historyData is true
  if (config?.url?.endsWith("dynamic") && historyData && config?.data?.query) {
    tables.forEach((table) => {
      const tableRegex = new RegExp(`\\b${table}\\b`, "g"); 
      config.data.query = config.data.query.replace(tableRegex, `${table}_hist`);
    });
  }

  // Check and modify query if `historyData` is true and `config.data.query` exists
  if (historyData && config?.data?.query) {
    tables.forEach((table) => {
      const tableRegex = new RegExp(`\\b${table}\\b`, "g"); 
      config.data.query = config.data.query.replace(tableRegex, `${table}_hist`);
    });
  }

  // Add token to the headers
  const token = sessionStorage.getItem("authToken"); 
  if (token) {
    config.headers["authorization"] = `Bearer ${token}`; 
  }

  return config;
});

// Export axiosWithToken directly
export default axiosWithToken;
