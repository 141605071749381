import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { Checkbox, Grid } from "@mui/material";
import { BASE_URL } from "../../../global";
import { toast } from "react-toastify";
import { DatasheetContext } from "./datasheet.context.js";
import { ClassicTable } from "../../../utils/components/Styles.js";
import axiosWithToken from "../../../utils/components/axiosTokenConfig.js";

const checkboxOptions = ["✔", "NA"];

export const onSubmitOpinionAndAnalysis = async (
  analysisSelectedOption,
  datasheetId,
) => {
  try {
    let payload = {
      ...analysisSelectedOption,
      datasheetId: Number(datasheetId),
      certificateId: Number(datasheetId),
    };

    if (analysisSelectedOption?.id) {
      await axiosWithToken
        .patch(
          BASE_URL + `opinionAndAnalysis/${analysisSelectedOption?.id}`,
          payload,
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          if (err.message !== "request_aborted") {
            toast.error("error : ", err);
          }
        });
    } else {
      await axiosWithToken
        .post(BASE_URL + `opinionAndAnalysis`, payload)
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          if (err.message !== "request_aborted") {
            toast.error("error : ", err);
          }
        });
    }
    return true;
  } catch (error) {
    // Handle errors
    console.error("Error in onSubmit:", error);
    return false;
  }
};

const OpinionAndAnalysisTable = ({ datasheetId }) => {
  const datasheetContextValue = useContext(DatasheetContext);
  const {
    state: { analysisSelectedOption },
    dispatch,
  } = datasheetContextValue;

  const handleChangeForCheckbox = (event, keyName) => {
    const newValue = event.target.checked
      ? event.target.value === "✔"
        ? true
        : false
      : "";

    analysisSelectedOption[keyName] = newValue;
    dispatch({
      field: "analysisSelectedOption",
      value: analysisSelectedOption,
    });
  };

  const convertToBooleanOrEmpty = (value) => {
    if (value === "1") return true;
    if (value === "") return "";
    return false;
  };

  const getOpinionAndAnalysisList = () => {
    let url = BASE_URL;
    const payload = {
      query: `SELECT * FROM opinionAndAnalysis WHERE datasheetId = ${datasheetId}`,
    };
    axiosWithToken
      .post(url + "dynamic", payload)
      .then((res) => {
        if (res.data.length > 0) {
          const parsedData = {
            ...res.data[0],
            accepted: convertToBooleanOrEmpty(res.data[0].accepted),
            limitedUse: convertToBooleanOrEmpty(res.data[0].limitedUse),
            calibrated: convertToBooleanOrEmpty(res.data[0].calibrated),
            rejected: convertToBooleanOrEmpty(res.data[0].rejected),
          };

          dispatch({
            field: "analysisSelectedOption",
            value: parsedData,
          });
          // setAnalysisSelectedOption(parsedData);
        }
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  useEffect(() => {
    getOpinionAndAnalysisList();
  }, []);

  return (
    <div>
      <Typography variant="h6" gutterBottom sx={{ my: 2 }}>
        Opinion And Analysis Table
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3, width: "unset", mx: 0 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            border: "1px solid #ddd",
            padding: "8px",
          }}
        >
          <Typography
            sx={{
              width: "50%",
              textAlign: "left",
              typography: { xs: "body1" },
            }}
          >
            Accepted - Validate for use
          </Typography>
          {checkboxOptions.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={analysisSelectedOption?.accepted === (item === "✔")}
                  onChange={(event) =>
                    handleChangeForCheckbox(event, "accepted")
                  }
                  value={item}
                />
              }
              label={<Typography variant="body2">{item}</Typography>}
            />
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            border: "1px solid #ddd",
            padding: "8px",
          }}
        >
          <Typography
            sx={{
              width: "50%",
              textAlign: "left",
              typography: { xs: "body1" },
            }}
          >
            Calibrated
          </Typography>
          {checkboxOptions.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={
                    analysisSelectedOption?.calibrated === (item === "✔")
                  }
                  onChange={(event) =>
                    handleChangeForCheckbox(event, "calibrated")
                  }
                  value={item}
                />
              }
              label={<Typography variant="body2">{item}</Typography>}
            />
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            border: "1px solid #ddd",
            padding: "8px",
          }}
        >
          <Typography
            sx={{
              width: "50%",
              textAlign: "left",
              typography: { xs: "body1" },
            }}
          >
            Limited Use (Refer - Result)
          </Typography>
          {checkboxOptions.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={
                    analysisSelectedOption?.limitedUse === (item === "✔")
                  }
                  onChange={(event) =>
                    handleChangeForCheckbox(event, "limitedUse")
                  }
                  value={item}
                />
              }
              label={<Typography variant="body2">{item}</Typography>}
            />
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            border: "1px solid #ddd",
            padding: "8px",
          }}
        >
          <Typography
            sx={{
              width: "50%",
              textAlign: "left",
              typography: { xs: "body1" },
            }}
          >
            Rejected/Out of Use
          </Typography>
          {checkboxOptions.map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={analysisSelectedOption?.rejected === (item === "✔")}
                  onChange={(event) =>
                    handleChangeForCheckbox(event, "rejected")
                  }
                  value={item}
                />
              }
              label={<Typography variant="body2">{item}</Typography>}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default OpinionAndAnalysisTable;
