import { MAX_COLS } from "../../../global";
import autoTable from "jspdf-autotable";
import { printDatasheetConfiguration } from "./datasheetConstant";
import { customFontFamily, footerConfig, printdatasheetSection, totalHeaderHeightWithCalibrationLableorNot } from "./datasheetConfig";

let serialNoShow = printdatasheetSection.readingTableSerialNoColumnShow;
let processedPages = new Set(); // Set to keep track of processed pages
let firstSkippedPage = null; // To track the first page that should be skipped


export async function drawTables(
  pdfDoc,
  y,
  staticTables,
  readingRows,
  props
) {
  const { margin, tableMargin, initPage } = props;

  y += 1;

  // format table data
  if (staticTables.length < 1){
    return
  }
  staticTables = staticTables?.filter((table) => table.status == 1);

  let groupedReadingRows = {};
  readingRows.forEach((row) => {
    if (!groupedReadingRows[row.tableId]) {
      groupedReadingRows[row.tableId] = [];
    }
    groupedReadingRows[row.tableId].push(row);
  });

  for (let i = 0; i < staticTables.length; i++) {
    let table = staticTables[i];
    const tableName = table.tableName;
    let tableHeaderHide = table?.defaultConfiguration?.printConfig?.printCommonHeaderConfig ? table?.defaultConfiguration?.printConfig?.printCommonHeaderConfig : table?.defaultConfiguration?.commonHeaderConfig;
    let datasheetColumnHide= table?.defaultConfiguration?.printConfig?.datasheetColumnHide?.split(",");
    let totalColumns = null;

    let header = null;
    let columns = Array.from(
      { length: MAX_COLS },
      (_, i) => table[`c${i + 1}`]
    )
      ?.filter((x) => x)
      .map((x) => x?.split("#")[0]);

        let headerColumns = Array.from({ length: MAX_COLS }, (_, i) => table[`c${i + 1}`])
          .filter((x, i) => x && !datasheetColumnHide?.includes(`c${i + 1}`))
          ?.map((x) => x?.split("#")[0]);          

      if (table?.isUncertainty && !datasheetColumnHide?.includes('uncertainty')) {
        headerColumns.push("Uncertainty (±)");
        columns.push("Uncertainty (±)");
      }
      if (serialNoShow) {
        headerColumns.unshift("Sr.No.");
        columns.unshift("Sr.No.");
      }
      totalColumns = columns.length;
      header = [];
      // process common header configuration
      if (tableHeaderHide) {
        let commonHeaderConfig =
          tableHeaderHide;
          if (serialNoShow) {
            commonHeaderConfig = "$:1," + commonHeaderConfig;
          }
        let i = 0;
        let commonHeaderRow = commonHeaderConfig.split(",").map((config) => {
          const [label, span] = config.split(":");
          if (label === "$") {
            let cell = {
              content: headerColumns[i],
              colSpan: parseInt(span, 10),
              rowSpan: 2,
              styles: { halign: "center", valign: "bottom" },
            };
            headerColumns[i] = "";
            i++;
            return cell;
          } else {
            i += parseInt(span, 10);
          return {
            content: label,
            colSpan: parseInt(span, 10),
          };
        }
        });
        // if (commonHeaderRow.some((row) => row.content !== "")) {
        //   header.push(commonHeaderRow);
        // }
        let removeEmtyStringcommonHeaderRow = commonHeaderRow.filter(row => row !== "");
        if (removeEmtyStringcommonHeaderRow?.some((row) => row.content !== "")) {
          header.push(removeEmtyStringcommonHeaderRow);
        }
        if (table?.isUncertainty && !datasheetColumnHide?.includes('uncertainty')) {
          const totalColSpan = commonHeaderRow.reduce((acc, row) => acc + row.colSpan, 0);
          if (totalColSpan < totalColumns) {
            commonHeaderRow.push(""); 
          }
        }
      }
      // filter empty columns
      headerColumns = headerColumns.filter((x) => x);
      header.push(headerColumns);

    const tableReadings = groupedReadingRows[table.id] || [];
    let globalIndex = 1;
    const tableRows = tableReadings.map((reading) => {
      let row = [];
      let index = globalIndex; // Start with the globalIndex

      const updateReadings = Object.fromEntries(
          Object.entries(reading)?.filter(([key]) => !datasheetColumnHide?.includes(key))
        );
      // parse header rows
      if (updateReadings["c1"]?.includes("_rh_")) {
        row = [
          { content: updateReadings["c1"].split("_rh_")[1], colSpan: totalColumns },
          // ...Array.from({ length: totalColumns - 1 }, (_, i) => ""),
        ];

        globalIndex = 1; // Reset the index after unit row
      }
      // parse unit row
      else if (Object.keys(updateReadings)?.some(key => key.startsWith('c') && parseInt(key.substring(1)) <= MAX_COLS && updateReadings[key]?.includes("_unit_"))) {        
        row =  Array.from({ length: totalColumns }, (_, i) => {
          const columnName = `c${i + 1}`;
          if (!datasheetColumnHide?.includes(columnName)) {
            return updateReadings[columnName]?.replaceAll("_unit_", "")
          }
        }).filter(value => value !== undefined);
        if (table?.isUncertainty && !datasheetColumnHide?.includes('uncertainty')) {
          row.push(updateReadings?.uncertainty?.replaceAll("_unit_", ""));
        }

        globalIndex = 1; // Reset the index after unit row
        // pass serialNoShow column is an empty string
        if (serialNoShow) {
          row.unshift("");
        }
      } 
    //   parse reading rows
      else {
        row = Array.from({length: totalColumns,}, (_, i) => {
            const columnName = `c${i + 1}`;

            if (!datasheetColumnHide?.includes(columnName)) {
              const value = updateReadings[columnName];
              return value ? value?.split("#")[0]?.replaceAll("$", "")?.replaceAll("\t", "") : null; //
            }
            return null;
          }).filter(value => value !== null);
        if (table?.isUncertainty && !datasheetColumnHide?.includes('uncertainty')) {
          row.push(updateReadings?.uncertainty?.split("#")[0]?.replaceAll("$", ""));
        }

        if (serialNoShow) {
          row.unshift(index);
        }
        globalIndex++; // Increment the global index after each row
      }

      return row;
    });


    // Print the table name
    if (
      y + 10 >
      printDatasheetConfiguration.pageHeight -
        footerConfig.footerimgHeight -
        footerConfig.footertableHeight -
        footerConfig.footerandbodygap -
        10
    ) {
      await printDatasheetConfiguration.pdfDoc.addPage();
      y =
        totalHeaderHeightWithCalibrationLableorNot.total + 5;
    }
    if(tableName?.trim()){
      y += 10;
      pdfDoc.setFontSize(printDatasheetConfiguration.fontSizes.h2);
      pdfDoc.setFont(customFontFamily?.boldFont,"bold");
      pdfDoc.text(tableName?.trim(), margin, y);
      y += 5;
    }

    let fontSize;
    let finalColumns = tableRows?.[0]?.length;
    if(finalColumns < 10 ){
      fontSize =printDatasheetConfiguration.fontSizes.h2
    } else if (finalColumns < 15){
      fontSize =printDatasheetConfiguration.fontSizes.h4
    } else if (finalColumns < 20){
      fontSize =printDatasheetConfiguration.fontSizes.h5
    } else {
      fontSize =printDatasheetConfiguration.fontSizes.h6
    }

    // Generate the table in the new page
    autoTable(pdfDoc, {
      startY: y,
      margin: tableMargin,
      head: header,
      body: tableRows,
      theme: "grid",
      headStyles: {
        fillColor: false, 
        textColor: printDatasheetConfiguration.textColor,
        fontSize: fontSize,
        font: customFontFamily?.boldFont,
      },
      styles: {
        halign: "center",
        fillColor: false,
        textColor: printDatasheetConfiguration.textColor,
        lineColor: printDatasheetConfiguration.textColor,
        lineWidth: printDatasheetConfiguration.lineWidth,
        fontSize: fontSize,
        font: customFontFamily?.normalFont,
      },
      didDrawPage: (data) => {
        const currentPage = pdfDoc.internal.getNumberOfPages(); // Get the current page
        // If the first skipped page is not set, skip the first page that appears
        if (firstSkippedPage === null) {
          firstSkippedPage = currentPage; // Mark this page to be skipped first
          return; // Skip processing this page
        }
        // If the current page is the first skipped page, skip it
        if (currentPage === firstSkippedPage) {
          return; // Skip processing this page
        }
        if (data.pageNumber > 1 && processedPages.has(currentPage)) {
          initPage();
          return;
        }
        // Process all other pages and skip any repeated pages
        if (!processedPages.has(currentPage) ) {
          processedPages.add(currentPage); // Mark this page as processed
          initPage();
        }
      },
    });

    y = pdfDoc.autoTable.previous.finalY + 10;
  }
  processedPages.clear();
  return y;
}
