import { driver } from "driver.js";
export const clientListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Client list.",
        },
      },
      {
        element: "#client_list_createbtn",
        popover: {
          title: "Create new Client List",
          description: "When click this button you can create new Client.",
        },
      },
      {
        element: "#client_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#client_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter your desired search value.",
        },
      },
      {
        element: "#client_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#client_list_table",
        popover: {
          title: "Client List Table",
          description:
            "Table displaying Client List details where you can delete and edit Client.",
        },
      },
      {
        element: "#client_list_edit",
        popover: {
          title: "Edit Client List ",
          description: "Click this button to edit the  Client.",
        },
      },
      {
        element: "#client_list_delete",
        popover: {
          title: "Delete Client List ",
          description: "Click this button to delete the Client.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage Client List.",
        },
      },
    ],
  });
};

export const editClientGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Document create / edit Client.",
        },
      },
      {
        element: "#client_edit_compname",
        popover: {
          title: "Create / Edit Client ",
          description: "Enter / Edit Client",
        },
      },
      {
        element: "#client_edit_branch",
        popover: {
          title: "Create / Edit branch ",
          description: "Enter / Edit branch",
        },
      },
      {
        element: "#client_edit_GST",
        popover: {
          title: "Create / Edit GST ",
          description: "Enter / Edit GST",
        },
      },
      {
        element: "#client_edit_State",
        popover: {
          title: "Create / Edit State ",
          description: "Enter / Edit State",
        },
      },
      {
        element: "#client_edit_addaddrbtn",
        popover: {
          title: "Add Address ",
          description:
            "When you click this button, a row will be added below, and you can enter or edit the address.",
        },
      },
      {
        element: "#client_edit_addaddrinput",
        popover: {
          title: "Enter / Edit Address ",
          description: "This is shown when an address is present.",
        },
      },
      {
        element: "#client_edit_addaddrdelte",
        popover: {
          title: "Delte Address Row ",
          description: "Click this button Row will delete.",
        },
      },
      {
        element: "#client_edit_addcontact",
        popover: {
          title: "Add Contact ",
          description:
            "When you click this button, a row will be added below table, and you can enter or edit the contact details.",
        },
      },
      {
        element: "#client_edit_contvalue",
        popover: {
          title: "Enter / Edit value",
          description: "Enter Respected heading value",
        },
      },
      {
        element: "#client_edit_contdelte",
        popover: {
          title: "Delete Contact",
          description: "Click this button Row will delete.",
        },
      },
      {
        element: "#client_edit_extrasetting",
        popover: {
          title: "Select Setting",
          description: "Select respected Setting",
        },
      },
      {
        element: "#client_edit_save",
        popover: {
          title: "Save client",
          description: "Click this button to save the client.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create / edit client",
        },
      },
    ],
  });
};
