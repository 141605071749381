import { driver } from "driver.js";

export const enquiryListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Enquiry list.",
        },
      },
      {
        element: "#enquirylist_createbtn",
        popover: {
          title: "Create New Enquiry",
          description: "Click this button to create a new enquiry.",
        },
      },
      {
        element: "#enquirylist_datefrom",
        popover: {
          title: "Select Date",
          description: "Select the start date.",
        },
      },
      {
        element: "#enquirylist_dateto",
        popover: {
          title: "Select Date",
          description: "Select the end date.",
        },
      },
      {
        element: "#enquirylist_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#enquirylist_searchbytext",
        popover: {
          title: "Enter / Update Your Value",
          description:
            "When you select the previous dropdown value, enter/update your value for the search.",
        },
      },
      {
        element: "#enquirylist_searchbtn",
        popover: {
          title: "Search",
          description:
            "When you select the previous dropdown or enter/update a value, click this button to get the result.",
        },
      },
      {
        element: "#stdMastersEQPList_convertexcel",
        popover: {
          title: "Print All Enquiries",
          description: "Below the list, you can get an Excel file.",
        },
      },
      {
        element: "#enquirylist_table",
        popover: {
          title: "Enquiry Table",
          description:
            "Enquiry table where you can view and manage enquiries.",
        },
      },
      {
        element: "#enquirylist_edit",
        popover: {
          title: "Edit Enquiry",
          description: "Click this button to edit the enquiry.",
        },
      },
      {
        element: "#enquirylist_followup",
        popover: {
          title: "Enquiry Follow-Up",
          description: "Click this button to go to the enquiry follow-up.",
        },
      },
      {
        element: "#enquirylist_preparequotation",
        popover: {
          title: "Prepare Quotation",
          description:
            "Click this button to go to the quotation where using the enquiry you can create a quotation.",
        },
      },
      {
        element: "#enquirylist_delete",
        popover: {
          title: "Delete Enquiry",
          description: "Click this button to delete the enquiry.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage enquiries.",
        },
      },
    ],
  });
};

export const createEnquiryGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in creating / edit an Enquiry.",
        },
      },
      {
        element: "#enquiry_create_company",
        popover: {
          title: "Select Company",
          description: "Select the company using the dropdown.",
        },
      },
      {
        element: "#enquiry_create_address",
        popover: {
          title: "Select Address",
          description:
            "When the company is selected, the address automatically fetches, and if more than one address, then you have to select the address.",
        },
      },
      {
        element: "#enquiry_create_contact_person",
        popover: {
          title: "Contact Person Name",
          description:
            "When the company is selected, the Contact Person Name automatically fetches.",
        },
      },
      {
        element: "#enquiry_create_email",
        popover: {
          title: "Email ID",
          description:
            "When the company is selected, the email automatically fetches.",
        },
      },
      {
        element: "#enquiry_create_source",
        popover: {
          title: "Select Source",
          description:
            "Select the source using the dropdown where you get the enquiry.",
        },
      },
      {
        element: "#enquiry_create_date",
        popover: {
          title: "Select Date",
          description: "Optional Field",
        },
      },
      {
        element: "#enquiry_create_followUpDate",
        popover: {
          title: "Select Follow-Up Date",
          description: "Optional Field",
        },
      },
      {
        element: "#enquiry_create_requirements",
        popover: {
          title: "Enter / Update Requirements",
          description: "Enter / Update requirements.",
        },
      },
      {
        element: "#enquiry_create_leadFollowup",
        popover: {
          title: "Enter / Update Lead Follow-up",
          description: "Enter / Update lead follow-up.",
        },
      },
      {
        element: "#enquiry_create_save",
        popover: {
          title: "Save Enquiry",
          description: "Click this button to save the Enquiry.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create an Enquiry.",
        },
      },
    ],
  });
};

export const followUpReportListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Follow-Up Report list.",
        },
      },
      {
        element: "#followUp_Reportlist_createbtn",
        popover: {
          title: "Select Company",
          description: "Select the company using the dropdown.",
        },
      },
      {
        element: "#followUp_Reportlist_datefrom",
        popover: {
          title: "Select Date",
          description: "Select the start date.",
        },
      },
      {
        element: "#followUp_Reportlist_dateto",
        popover: {
          title: "Select Date",
          description: "Select the end date.",
        },
      },
      {
        element: "#followUp_Reportlist_searchbtn",
        popover: {
          title: "Search",
          description:
            "When you select date, click this button to get the result.",
        },
      },
      {
        element: "#followUp_Reportlist_table",
        popover: {
          title: "Follow-Up Report Table",
          description:
            "Follow-up report table where you can view and manage the follow-up reports.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage follow-up reports.",
        },
      },
    ],
  });
};
