import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import HelpIcon from "@mui/icons-material/Help";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  createClientThunk,
  createEnquiryThunk,
  getEnquiryData,
  getEnquiryThunk,
  normalize,
  updateEnquiryThunk,
} from "./enquirySlice";
import { createEnquiryGuide } from "./guide";
import { fetchAddresses } from "../../invoice/productDetails";
import EditWrap from "../../commonComponent/editWrap";

const sourceArr = [
  {
    id: 0,
    label: "Visit",
  },
  {
    id: 1,
    label: "Tele",
  },
  {
    id: 2,
    label: "Justdial",
  },
  {
    id: 3,
    label: "Customer Call",
  },
];

export default function CreateEnquiry(props) {
  const createEnquiryGuides = createEnquiryGuide();
  const data = useSelector(getEnquiryData);
  const [state, setState] = useState({
    form: {},
    clientArray: [],
    clientId: -1,
    checkedClient: false,
    address: "",
  });
  const [allAddress, setAllAddress] = useState([]);
  const userId = localStorage.getItem("id");
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));
  const params = useParams();
  const dispatch = useDispatch();
  const refresh = () => {
    window.location.reload(false);
  };

  if (data.created) {
    toast.success("Enquiry Created Successfully!");
    dispatch(normalize());
    setTimeout(() => refresh(), 500);
  }

  if (data.updated) {
    toast.success("Enquiry Updated Successfully!");
    dispatch(normalize());
  }

  const onSubmit = (event) => {
    let row = {
      ...state.form,
      source:
        state.form?.source?.id !== undefined
          ? parseInt(state.form?.source?.id)
          : data.enquiry.en_source
          ? parseInt(data.enquiry.en_source)
          : data.enquiry.en_source,
      createdby: userId,
    };
    row.date = moment(row.date).format("YYYY-MM-DD");
    row.followUpDate = moment(row.followUpDate).format("YYYY-MM-DD");
    delete row.client;
    row.address = allAddress.length === 1?allAddress[0]?.id :state?.address || data?.enquiry?.en_address || "";

    if (params.id) dispatch(updateEnquiryThunk({ data: row, id: params.id }));
    else dispatch(createEnquiryThunk({ data: row }));
  };


  const updateForm = (key, value) => {
    let newForm = {
      ...state.form,
    };
    newForm[key] = value;
    setState({
      ...state,
      form: newForm,
    });
  };

  useEffect(async () => {
    let Client = data.clients?.filter((e) => e.id == data.enquiry.client_id)[0];

    const addressIds = Client?.address;
    const shippingAddressIds = Client?.shippingAddress;

    let newAddressArray = await fetchAddresses(addressIds, shippingAddressIds);
    setAllAddress(newAddressArray);
  }, [data.enquiry.client_id, data.clients]);

  useEffect(() => {
    if (allAddress.length === 1) {
      
      setState((prevState) => ({
        ...prevState,
        address: allAddress[0]?.id || "",
      }));
    }
  }, [allAddress]);

  useEffect(() => {
    if (params.id) dispatch(getEnquiryThunk({ id: params.id }));
    else dispatch(getEnquiryThunk());
  }, []);

  useEffect(() => {
    if (data.loading === 2) window.location.reload(false);
  });

  return (
    <EditWrap>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {`${params.id ? "Edit" : "Create New"} Enquiry`}
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              createEnquiryGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            size="small"
            id="enquiry_create_company"
            options={data.clients}
            disabled={!!params.id}
            value={
              state?.form?.client ||
              data?.clients?.filter((c) => c.id == data.enquiry.client_id)[0] ||
              ""
            }
            getOptionLabel={(option) => option.companyName || ""}
            renderInput={(params) => <TextField {...params} label="Client *" />}
            onChange={async (_, val) => {
              const addressIds = val?.address;
              const shippingAddressIds = val?.shippingAddress;

              let newAddressArray = await fetchAddresses(
                addressIds,
                shippingAddressIds
              );
              setAllAddress(newAddressArray);
              setState({
                ...state,
                form: {
                  ...state.form,
                  client: val ? val : "",
                  clientId: val ? val.id : "",
                  address: val ? val.address : "",
                  contactPersonName: val?.contact?.[0]?.contactPersonName || "",

                  emailId: val ? val.contact?.[0]?.emailId : "",
                },
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} id="enquiry_create_address">
         

          {allAddress.length === 1 || params.id ? (
            <TextField
              // value={allAddress[0] || data.enquiry.en_address || ""}
              value={
                allAddress?.length === 1
                  ? allAddress[0]?.address // If allAddress has only one element, use its address
                  : allAddress.find(
                      (e) => e.id == data.enquiry.en_address.split(",")?.[0]
                    )?.address || ""
              }
              id="outlined-basic"
              label="Address *"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
          ) : (
            <Autocomplete
              options={allAddress}
              size="small"
              getOptionLabel={(option) => option?.address}
              onChange={(event, value) => {
                setState((prevState) => ({
                  ...prevState,
                  address: value?.id || "",
                }));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Address *" variant="outlined" />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="enquiry_create_contact_person"
            label="Contact Person Name *"
            size="small"
            inputProps={{
              autoComplete: 'off',
            }}
            value={
              state.form.contactPersonName ||
              data.enquiry.en_contactPersonName ||
              ""
            }
            // defaultValue={`${data.enquiry.en_contactPersonName || ""}`}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateForm("contactPersonName", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="enquiry_create_email"
            label="email Id *"
            inputProps={{
              autoComplete: 'off',
            }}
            size="small"
            value={state.form.emailId || data.enquiry.en_emailId || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateForm("emailId", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <Autocomplete
            size="small"
            id="enquiry_create_source"
            value={
              state.form.source ||
              sourceArr.filter(
                (s) => s.id === parseInt(data.enquiry.en_source)
              )[0] ||
              ""
            }
            options={sourceArr}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => <TextField {...params} label="Source *" />}
            onChange={(_, val) => updateForm("source", val)}
          />
        </Grid>
      
        <Grid item xs={12} sm={6} md={3} lg={3} id="enquiry_create_date">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                state.form.date
                  ? new Date(state.form.date)
                  : data.enquiry.en_date
                  ? new Date(data.enquiry.en_date)
                  : new Date()
              }
              onChange={(newValue) => {
                updateForm("date", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3} id="enquiry_create_followUpDate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Followup Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                state.form.followUpDate
                  ? new Date(state.form.followUpDate)
                  : data.enquiry.en_followUpDate
                  ? new Date(data.enquiry.en_followUpDate)
                  : new Date()
              }
              onChange={(newValue) => {
                updateForm("followUpDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="enquiry_create_requirements"
            style={{
              width: "100%",
            }}
            value={
              state.form.requirements || data.enquiry.en_requirements || ""
            }
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Requirements"
            onChange={(e) => {
              updateForm("requirements", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          <TextField
            id="enquiry_create_leadFollowup"
            style={{
              width: "100%",
            }}
            value={
              state.form.leadFollowup || data.enquiry.en_leadFollowup || ""
            }
            multiline
            minRows={3}
            maxRows={3}
            placeholder="Lead Followe-up"
            onChange={(e) => updateForm("leadFollowup", e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
     
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="enquiry_create_save"
          variant="contained"
          size="small"
          disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
          sx={{ m: 0 }}
          onClick={() => {
            if (params.id == undefined) {
              if (!state?.form?.clientId) {
                toast.error('Please select a Client');
                return;
              }
            }
            // props.setLoader(true);
            onSubmit();
          }}
        >
          {`${params.id ? "Update" : "save"}`}
        </Button>
      </Toolbar>
    </EditWrap>
  );
}
