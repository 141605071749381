import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import EditWrap from "../commonComponent/editWrap";

const EditRemark = () => {
  const [inputStates, setInputStates] = React.useState({});
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );
  const params = useParams();

  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleSubmit = () => {
    let url = BASE_URL;
    if (params.id) {
      axiosWithToken
        .patch(url + `remarks/${params.id}`, inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Remark edit successfully !");
        })
        .catch((error) => {
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    } else {
      axiosWithToken
        .post(url + "remarks", inputStates)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Remark created successfully !");
        })
        .catch((error) => {
          if (error.message !== "request_aborted") {
            toast.error("Something Went Wrong!");
          }
        });
    }
  };

  const fetchRemarks = (inpt) => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `remarks/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];

        let newData = {
          ...inputStates,
          ...dataObject,
        };
        dataObject && setInputStates(newData);
      })
      .catch((error) => {
        if (error.message !== "request_aborted") {
          toast.error("Something Went Wrong!");
        }
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchRemarks();
  }, [params.id]);

  return (
    <EditWrap>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create/Update Remark
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            id="outlined-basic"
            label="Remark Label *"
            size="small"
            fullWidth
            inputProps={{
              autoComplete: "off",
            }}
            variant="outlined"
            value={inputStates?.label || ""}
            onChange={(e) => {
              updateInputObject("label", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <TextField
            id="outlined-basic"
            label="Remark Description *"
            size="small"
            value={inputStates?.value || ""}
            fullWidth
            rows={3}
            inputProps={{
              autoComplete: "off",
            }}
            multiline
            maxRows={5}
            variant="outlined"
            onChange={(e) => {
              updateInputObject("value", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Tooltip
          title={
            !(editAccess?.includes(1) || editAccess?.includes(0))
              ? "You don't have access"
              : ""
          }
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
            onClick={() => {
              if (params.id == undefined) {
                if (!inputStates?.label) {
                  toast.error("Remark Label is required");
                  return;
                }
                if (!inputStates?.value) {
                  toast.error("Remark Description is required");
                  return;
                }
              }
              handleSubmit();
            }}
          >
            {params.id ? "Update" : "Save"}
          </Button>
        </Tooltip>
     </Toolbar>
    </EditWrap>
  );
};

export default EditRemark;
