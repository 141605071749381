import React from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { Button} from "@mui/material";

const SrfRowDetailsModal = ({
  open,
  handleClose,
  rowIndex,
  row,
  fixedColumns,
  updateCellValue,
  setMultiValueData,
  setMultiValueModalOpen,
  instrumentList,
  getInstrument,
  set_DUIC_ID,
  updateSrfDetails,
  setUnitFilter,
  excelImport,
  settings,
  DUC_ID,
  checkDucMasterRecord,
  checkLatestInstrumentRecord,
  additionalReadingColumns,
  renderSRFRow,
}) => {
  return (
    <Modal open={open} onClose={handleClose} disableEnforceFocus >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          "@media (maxWidth: 600px)": {
            width: "80%",
          },
          "@media (maxWidth: 480px)": {
            width: "90%",
          },
          backgroundColor: "white",
          maxHeight: "80vh", 
          overflow: "scroll",
          // border: "2px solid #000",
          boxShadow: 24,
          padding: "10px",
        }}
      >

          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right", margin: "15px" }}
            onClick={handleClose}
          >
            X
          </Button>
          <Paper>
          <br />

          <div className="srf-grid-container">
            {renderSRFRow(
              rowIndex,
              row,
              fixedColumns,
              updateCellValue,
              setMultiValueData,
              setMultiValueModalOpen,
              instrumentList,
              getInstrument,
              set_DUIC_ID,
              updateSrfDetails,
              setUnitFilter,
              excelImport,
              settings,
              DUC_ID,
              checkDucMasterRecord,
              checkLatestInstrumentRecord
            )}
          </div>
        </Paper>
      </div>
    </Modal>
  );
};

export default SrfRowDetailsModal;
