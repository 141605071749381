var metric = {
  dB: {
    name: {
      singular: "Decibel",
      plural: "Decibels",
    },
    to_anchor: 1,
  },
  dBA: {
    name: {
      singular: "Decibel A-weighted",
      plural: "Decibels A-weighted",
    },
    to_anchor: 1,
    // You would need to define the conversion to dB if necessary
  },
  SPL: {
    name: {
      singular: "Sound Pressure Level",
      plural: "Sound Pressure Levels",
    },
    to_anchor: 1,
    // You would need to define the conversion to dB if necessary
  },
};

var imperial = {
  // Define any imperial sound units if needed
};

module.exports = {
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: "dB", // Reference unit for the metric system
      ratio: 1, // Conversion ratio relative to the reference unit (1 for the same unit)
    },
    imperial: {
      unit: "dB", // Reference unit for the imperial system
      ratio: 1, // Conversion ratio relative to the reference unit (1 for the same unit)
    },
  },
};
