import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { TextField, Autocomplete, Table, useMediaQuery, Modal, Box, Grid, Checkbox } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import HelpIcon from "@mui/icons-material/Help";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link, useLocation } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import { uncertinityListGuide } from "./guide";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ListWrap from "../../commonComponent/listWrap";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");



const columns = [
  { id: "id", label: "Sr. No", minWidth: 40 },

  {
    id: "name",
    label: "Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "distribution",
    label: "Distribution",
    align: "left",
    minWidth: 40,
  },
  // {
  //   id: "sensitives",
  //   label: "Sensitive Coefficient",
  //   align: "left",
  //   minWidth: 40,
  // },
];

function createData(id, name, distribution, sensitives) {
  return {
    id,
    name,
    distribution,
    sensitives,
  };
}

export default function UncertinityList() {
  const uncertinityListGuides = uncertinityListGuide();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const urlPath = useLocation();
  const [searchBy, setSearchBy] = React.useState(sessionStorage.getItem("searchBy") || "Uncertainty Name");
  const [searchKey, setSearchKey] = React.useState(sessionStorage.getItem("searchKey") || ""); 
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [uncertinityListList, setUncertinityList] = React.useState([]);
  const [uncertData, setUncertData] = React.useState([]);
  const [filterModalOpen, setFilterModalOpen] = React.useState(false);
  const [searched, setSearched] = React.useState(false);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const [selected, setSelected] = React.useState([]);
  const handleSelectRow = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };
  const isSelected = (id) => selected.includes(id);

  const handleFilterOpen = () => setFilterModalOpen(true);
  const handleFilterClose = () => setFilterModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    const idString = (Array.isArray(id) ? id : [id]).join(',');

    let query = {
      query: `DELETE FROM uncertainty WHERE id IN (${idString});`
      };
    let url = BASE_URL;
    axiosWithToken
      .post(url + `dynamic`, query)
      .then((res) => {
        toast("Uncertinity was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => console.log("err",error));
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getUncertinityList = (event) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Uncertainty Name": "name",
      }[searchBy];

      whereConditions = `and ${colName} like '%${searchKey}%'`;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `select id, name, distribution, status from uncertainty  WHERE status = 1  ${whereConditions} order by id DESC `,
    };

    // axiosWithToken
    //   .get(url + "uncertainty?_where=(status,eq,1)&_sort=-id")
    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setUncertinityList(res.data);
        initializeDataTable();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };
  const filterSection = () => (
    <Grid
      container
      spacing={2}
      // justifyContent="flex-end"
      alignItems="center"
      style={{ padding: "5px 0px", marginBottom: "2px" }}
    >
       <Grid item xs={12} sm={6} md={2} lg={2}>
      <Autocomplete
        sx={{
          m: 0,
          minWidth: 180,
        }}
       className="Listinputcss"
        size="small"
        value={searchBy}
        id="uncertinity_list_searchby"
        options={[{ key: "name", label: "Uncertainty Name" }]}
        renderInput={(params) => (
          <TextField {...params} label="Search By" />
        )}
        onInputChange={(event, newInputValue) => {
          setSearchBy(newInputValue);
          setSearched(false);
        }}
      />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2} >
      <TextField
      style={{ width: "100%" }}
        id="uncertinity_list_searchbytext"
        label={"enter " + searchBy}
        size="small"
        variant="outlined"
        className="Listinputcss"
        value={searchKey}
        inputProps={{
          autoComplete: 'off',
        }}
        onChange={(e) => {
          setSearchKey(e.target.value)
          setSearched(false);
        }}
      />
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={1}  textAlign={"left"}>
      <Button
        id="uncertinity_list_searchbtn"
        variant="contained"
        size="small"
        color={searched ? "error" : "primary"}
        onClick={() => {
          searched ? clearSearch() : search();
          handleFilterClose();
        }}
      >
        {searched ? <RestartAltIcon /> : <SearchIcon />}
      </Button>
    </Grid>
  </Grid>
  );
  const filterModal = () => {
    return (
      <Modal open={filterModalOpen} size="md">
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 30%)",
            backgroundColor: "white",
            // border: "2px solid #000",
            boxShadow: 24,
            padding: "10px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="error"
            style={{ float: "right" }}
            onClick={handleFilterClose}
          >
            X
          </Button>
          {filterSection()}
        </Box>
      </Modal>
    );
  };

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if(storedSearchBy){
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    sessionStorage.setItem("searchBy", searchBy);
    sessionStorage.setItem("searchKey", searchKey);
    sessionStorage.setItem("searched", "true");
    sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    getUncertinityList();
  };
  const clearSearch = () => {
    sessionStorage.removeItem("searchBy");
    sessionStorage.removeItem("searchKey");
    sessionStorage.removeItem("searched", searched);
    sessionStorage.removeItem("currentPath");
    setSearchBy("Uncertainty Name");
    setSearchKey("");
    setSearched(false); 
  };
  useEffect(() => {
    if (searched==false) {
    getUncertinityList();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const uncertinityData = () => {
    var rowDaata = [];
    for (let i = 0; i < uncertinityListList.length; i++) {
      rowDaata.push(
        createData(
          uncertinityListList[i].id,
          uncertinityListList[i].name,
          uncertinityListList[i].distribution,
          uncertinityListList[i].sensitives
        )
      );
    }
    setUncertData(rowDaata);
  };

  function isDoubleByte(str) {
    for (var i = 0, n = str.length; i < n; i++) {
      if (str.charCodeAt(i) > 255) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    uncertinityData();
  }, [uncertinityListList]);

  return (
    <ListWrap>
      {filterModal()}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Toolbar style={{ padding: "0px" }}>
          <Button
            id="uncertinity_list_createbtn"
            variant="contained"
            size="small"
            component={Link}
            to="/master/uncertinity/createUncertinity"
          >
            <b>Create Uncertainty</b>
          </Button>
        </Toolbar>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
            style={{ display: isMobile ? "block" : "none" }}
            onClick={handleFilterOpen}
          >
            <FilterAltIcon />
          </Button>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              uncertinityListGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
        </div>
      </div>
      {!isMobile && filterSection()}
      {selected.length > 0 && (
        <Box sx={{textAlign:"left", margin:"10px 0px"}}>
          <Button
          variant="contained"
          size="small"
          color={"error"}
          onClick={() => {
            if (window.confirm("Really want to delete Uncertinity?")){
              submitSiteDelete(selected);
            } 
          }}
          >Multiple Delete</Button>
        </Box>
      )}
    <TableContainer>
      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="uncertinity_list_table"
        sx={{ borderCollapse: "collapse" }}
      >
        <TableHead>
          <TableRow>
        {(editAccess?.includes(2) || editAccess?.includes(0)) && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < uncertData.length}
                checked={selected.length === uncertData.length}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSelected(uncertData.map((row) => row.id));
                  } else {
                    setSelected([]);
                  }
                }}
              />
            </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uncertData?.length > 0 ? (
            uncertData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                   {(editAccess?.includes(2) || editAccess?.includes(0)) && (
                   <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected(row.id)}
                      onChange={() => handleSelectRow(row.id)}  
                    />
                  </TableCell>
                   )}
                  {columns.map((column) => {
                    let value = row[column.id];

                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Uncertinity" placement="top-start">
                        <Button
                          id="uncertinity_list_edit"
                          component={Link}
                          to={`/master/uncertinity/EditUncertinity/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Uncertinity" placement="top-start">
                        <Button
                          id="uncertinity_list_delete"
                          disabled={!(editAccess?.includes(2) || editAccess?.includes(0))}
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Really want to delete Uncertinity?"
                              )
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color:!(editAccess?.includes(2) || editAccess?.includes(0)) ? 'lightgray' : '#dc3545' }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align="center">
               <h6><b>Data Not Found</b></h6>
              </TableCell>
            </TableRow>
          )} 
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 30, 100]}
        component="div"
        count={uncertData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ToastContainer />
    </TableContainer>
    </ListWrap>
  );
}
