import { driver } from "driver.js";

export const editSupportiveInstrumentGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Document Edit Supportive Instrument.",
        },
      },
      {
        element: "#supportive_instrument_edit_name",
        popover: {
          title: "Edit Name ",
          description: "Edit Supportive Instrument name",
        },
      },
      {
        element: "#supportive_instrument_edit_id",
        popover: {
          title: "Edit Id ",
          description: "Edit Supportive Instrument id",
        },
      },
      {
        element: "#supportive_instrument_edit_addButton",
        popover: {
          title: "Add Row",
          description:
            "When clicking this button you can add new row in below table",
        },
      },
      {
        element: "#supportive_instrument_edit_entervalue",
        popover: {
          title: "Edit Value",
          description: "Edit value",
        },
      },
      {
        element: "#supportive_instrument_edit_selectunit",
        popover: {
          title: "Edit Unit",
          description: "Using dropdown you can edit unit",
        },
      },
      {
        element: "#supportive_instrument_edit_deltebtn",
        popover: {
          title: "Delete row",
          description: "When clicking this button you can delete this row",
        },
      },
      {
        element: "#supportive_instrument_edit_save",
        popover: {
          title: "Save Instrument",
          description: "Click this button to save the Instrument.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can edit  Supportive Instrument",
        },
      },
    ],
  });
};

export const createSupportiveInstrumentGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Document create Supportive Instrument.",
        },
      },
      {
        element: "#supportive_instrument_create_name",
        popover: {
          title: "Enter Name ",
          description: "Enter Supportive Instrument name",
        },
      },
      {
        element: "#supportive_instrument_create_id",
        popover: {
          title: "Enter Id ",
          description: "Enter Supportive Instrument id",
        },
      },
      {
        element: "#supportive_instrument_create_addButton",
        popover: {
          title: "Add Row",
          description:
            "When clicking this button you can add new row in below table",
        },
      },
      {
        element: "#supportive_instrument_create_entervalue",
        popover: {
          title: "Enter Value",
          description: "Enter value",
        },
      },
      {
        element: "#supportive_instrument_create_selectunit",
        popover: {
          title: "Select Unit",
          description: "Using dropdown you can select unit",
        },
      },
      {
        element: "#supportive_instrument_create_deltebtn",
        popover: {
          title: "Delete row",
          description: "When clicking this button you can delete this row",
        },
      },
      {
        element: "#supportive_instrument_create_save",
        popover: {
          title: "Save Instrument",
          description: "Click this button to save the Instrument.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create Supportive Instrument",
        },
      },
    ],
  });
};

export const supportiveinstrumentsListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Supportive Instruments list.",
        },
      },
      {
        element: "#suppt_instr_list_createbtn",
        popover: {
          title: "Create new Supportive Instruments",
          description:
            "When click this button you can create new Supportive Instruments.",
        },
      },
      {
        element: "#suppt_instr_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#suppt_instr_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter your desired search value.",
        },
      },
      {
        element: "#suppt_instr_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#suppt_instr_list_table",
        popover: {
          title: "Supportive Instruments Table",
          description:
            "Table displaying Supportive Instruments details where you can delete and edit Supportive Instruments.",
        },
      },
      {
        element: "#suppt_instr_list_edit",
        popover: {
          title: "Edit Supportive Instruments ",
          description: "Click this button to edit the  Supportive Instruments.",
        },
      },
      {
        element: "#suppt_instr_list_delete",
        popover: {
          title: "Delete Supportive Instruments ",
          description:
            "Click this button to delete the Supportive Instruments.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage Supportive Instruments.",
        },
      },
    ],
  });
};
