import { driver } from "driver.js";
export const auditListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Audit list.",
        },
      },
      {
        element: "#audit_list_datefrom",
        popover: {
          title: "Select Date",
          description: "Select start date.",
        },
      },
      {
        element: "#audit_list_dateto",
        popover: {
          title: "Select Date",
          description: "Select end date.",
        },
      },
      {
        element: "#audit_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#audit_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When you select the previous dropdown value, enter your value for the search.",
        },
      },
      {
        element: "#audit_list_searchbtn",
        popover: {
          title: "Search ",
          description:
            "When you select the previous dropdown or enter a value, click this button to get the result.",
        },
      },
      {
        element: "#audit_list_printall",
        popover: {
          title: "Print All audit",
          description: "Below the list, you can print all audit at once.",
        },
      },
      {
        element: "#audit_list_table",
        popover: {
          title: "Audit Table",
          description: "Audit table where you can view and download audit.",
        },
      },
      {
        element: "#audit_list_download",
        popover: {
          title: "Downlaod Button",
          description: "Click this button to view and download the audit.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can download audit.",
        },
      },
    ],
  });
};
