import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { useParams } from "react-router-dom";
import {
  Button,
  Typography,
  Toolbar,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import EditWrap from "../../commonComponent/editWrap";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import generatePDFVoucher from "./voucherPrint";

export default function PaymentVoucher() {
  const printComponentRef = React.useRef();
  const [expenseDate, setExpenseDate] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [nameOfPerson, setNameOfPerson] = React.useState("");
  const [reason, setReason] = React.useState("");
  const params = useParams();
  const [letterHead, setLetterHead] = React.useState(true);

  useEffect(() => {
    getExpenseList();
  }, []);

  const getExpenseList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `expenses?_where=(id,eq,${params.id})`)
      .then((res) => {
        setExpenseDate(res.data[0].date);
        setAmount(res.data[0].amount);
        setNameOfPerson(res.data[0].nameOfPerson);
        setReason(res.data[0].reason);
      })
      .catch((error) => console.log(error));
  };

  return (
    <EditWrap>
      <Grid container spacing={2} align="left">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            size="small"
            options={[
              { id: 1, label: "With Letter Head" },
              { id: 2, label: "Without Letter Head" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="letterHead" />
            )}
            onInputChange={(event, newInputValue) => {
              if (
                newInputValue === "With Letter Head" ||
                newInputValue === ""
              ) {
                setLetterHead(true);
              } else if (newInputValue === "Without Letter Head") {
                setLetterHead(false);
              }
            }}
          />
        </Grid>
      </Grid>
      <div
        style={{ width: "90%", margin: "1rem auto" }}
        className="row border-dark p-3 border"
        ref={printComponentRef}
      >
        <Typography variant="h5" style={{ textAlign: "center" }}>
          <b>
            <u>Payment Voucher</u>
          </b>
        </Typography>
        <hr />
        <div className="col-6 text-start">
          <span>
            <b>Paid To:</b> {nameOfPerson}
          </span>
          <br />
          <span>
            <b>An Amount Of Rupees:</b> {amount}
          </span>
          <br />
          <b>Rs:</b> {amount}
        </div>
        <div className="col-6 text-start">
          <span>
            <b>Date:</b> {moment(expenseDate).format("DD-MM-YYYY")}
          </span>
          <br />
          <span>
            <b>Towards:</b> {reason}
          </span>
        </div>
      </div>

      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: "grey" }}
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() =>
            generatePDFVoucher(
              nameOfPerson,
              amount,
              expenseDate,
              reason,
              letterHead
            )
          }
        >
          Download PDF
        </Button>
      </Toolbar>
    </EditWrap>
  );
}
