export const typeA_columns = [
  {
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    label: "READINGS x",
    align: "left",
    minWidth: 40,
  },
  {
    label: "MEAN x̄",
    align: "left",
    minWidth: 40,
  },
  {
    label: "x-x̄",
    align: "left",
    minWidth: 40,
  },
  {
    label: "(x-x̄)²",
    align: "left",
    minWidth: 40,
  },
];

export const typeB_columns = [
  {
    label: "",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Source",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Distribution",
    align: "left",
    minWidth: 40,
  },
  {
    label: "DOF",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Calculation",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Value(±)",
    align: "left",
    minWidth: 40,
  },
];

export const budget_columns = [
  {
    label: "Sources of Uncertainty",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Estimate",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Limits Xi",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Probability Distribution",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Standard Uncertainty",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Sensitivity Coefficient",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Uncertainty Contribution",
    align: "left",
    minWidth: 40,
  },
  {
    label: "Degrees of freedom",
    align: "left",
    minWidth: 40,
  },
];
