import { driver } from "driver.js";

export const uncertinityListGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Uncertinity list.",
        },
      },
      {
        element: "#uncertinity_list_createbtn",
        popover: {
          title: "Create new Uncertinity List",
          description: "When click this button you can create new Uncertinity.",
        },
      },
      {
        element: "#uncertinity_list_searchby",
        popover: {
          title: "Search Field",
          description: "Use this dropdown to search.",
        },
      },
      {
        element: "#uncertinity_list_searchbytext",
        popover: {
          title: "Enter Your Value",
          description:
            "When selecting a previous dropdown value, enter your desired search value.",
        },
      },
      {
        element: "#uncertinity_list_searchbtn",
        popover: {
          title: "Search",
          description:
            "After selecting a previous dropdown or entering a value, click this button to get results.",
        },
      },
      {
        element: "#uncertinity_list_table",
        popover: {
          title: "Uncertinity List Table",
          description:
            "Table displaying Uncertinity List details where you can delete and edit Uncertinity.",
        },
      },
      {
        element: "#uncertinity_list_edit",
        popover: {
          title: "Edit Uncertinity List ",
          description: "Click this button to edit the  Uncertinity.",
        },
      },
      {
        element: "#uncertinity_list_delete",
        popover: {
          title: "Delete Uncertinity List ",
          description: "Click this button to delete the Uncertinity.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can manage Uncertinity List.",
        },
      },
    ],
  });
};

export const createUncertinityGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Document create Uncertinity.",
        },
      },
      {
        element: "#uncertinity_create_name",
        popover: {
          title: "Enter Name ",
          description: "Enter Uncertinity name",
        },
      },
      {
        element: "#uncertinity_create_distrubution",
        popover: {
          title: "Enter distribution ",
          description: "Enter distribution",
        },
      },
      {
        element: "#uncertinity_create_condcoeff",
        popover: {
          title: "Sensitivity Coefficient Condition",
          description: "this will not change",
        },
      },
      {
        element: "#uncertinity_create_formulacoeff",
        popover: {
          title: "Enter Formula ",
          description: "Enter Formula",
        },
      },
      {
        element: "#uncertinity_create_unitcoeff",
        popover: {
          title: "Select Unit ",
          description: "Select Unit",
        },
      },
      {
        element: "#uncertinity_create_condFact",
        popover: {
          title: "Uncertainty Factors Condition",
          description: "this will not change",
        },
      },
      {
        element: "#uncertinity_create_formulaFact",
        popover: {
          title: "Enter Formula ",
          description: "Enter Formula",
        },
      },
      {
        element: "#uncertinity_create_unitFact",
        popover: {
          title: "Select Unit ",
          description: "Select Unit",
        },
      },
      {
        element: "#uncertinity_create_save",
        popover: {
          title: "Save Uncertinity",
          description: "Click this button to save the uncertinity.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can create Uncertinity",
        },
      },
    ],
  });
};
export const editUncertinityGuide = () => {
  return driver({
    showProgress: true,
    steps: [
      {
        popover: {
          title: "👋 Let's get started...",
          description:
            "This tour will guide you through all sections and components in this Document edit Uncertinity.",
        },
      },
      {
        element: "#uncertinity_edit_name",
        popover: {
          title: "Edit Name ",
          description: "Edit Uncertinity name",
        },
      },
      {
        element: "#uncertinity_edit_distrubution",
        popover: {
          title: "Edit distribution ",
          description: "Edit distribution",
        },
      },
      {
        element: "#uncertinity_edit_condcoeff",
        popover: {
          title: "Sensitivity Coefficient Condition",
          description: "this will not change",
        },
      },
      {
        element: "#uncertinity_edit_formulacoeff",
        popover: {
          title: "Edit Formula ",
          description: "Edit Formula",
        },
      },
      {
        element: "#uncertinity_edit_unitcoeff",
        popover: {
          title: "Edit Unit ",
          description: "Edit Unit",
        },
      },
      {
        element: "#uncertinity_edit_condFact",
        popover: {
          title: "Uncertainty Factors Condition",
          description: "this will not change",
        },
      },
      {
        element: "#uncertinity_edit_formulaFact",
        popover: {
          title: "Edit Formula ",
          description: "Edit Formula",
        },
      },
      {
        element: "#uncertinity_edit_unitFact",
        popover: {
          title: "Edit Unit ",
          description: "Edit Unit",
        },
      },
      {
        element: "#uncertinity_edit_save",
        popover: {
          title: "Save Uncertinity",
          description: "Click this button to save the uncertinity.",
        },
      },
      {
        popover: {
          title: "Congratulations 🎉",
          description: "Now you can edit Uncertinity",
        },
      },
    ],
  });
};
