import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import { useLocation } from "react-router";

const ExcelDownload = ({ finalData, notNeededColumn, headers, secondTable }) => {
  const [editAccess, setEditAccess] = React.useState(
    localStorage.getItem("editAccess")?.split(",").map(Number)
  );

  const location = useLocation();
  const routeSegments = location.pathname.split("/");
  const selectedRouteInUpperCase = (routeSegments[1] === "master"
    ? routeSegments[2]
    : routeSegments[1])
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());

  const convertToExcel = () => {
    const dataWithoutNotNeededKeys =
      notNeededColumn.length > 0
        ? finalData.map((item) => {
            const filteredItem = {};
            for (const key in item) {
              if (item.hasOwnProperty(key) && !notNeededColumn.includes(key)) {
                let value = item[key];

                if (Array.isArray(value)) {
                  value = value.join(", ");
                }

                filteredItem[key] = shouldConvertToDate(key)
                  ? value
                    ? new Date(value).toLocaleString()
                    : ""
                  : value;
              }
            }
            return filteredItem;
          })
        : finalData;

    const headersMap = headers?.reduce((acc, { key, label }) => {
      acc[key] = label;
      return acc;
    }, {});

    const transformedData = dataWithoutNotNeededKeys.map((item) => {
      const newItem = {};
      for (const key in item) {
        newItem[headersMap?.[key] || key] = item[key];
      }
      return newItem;
    });

    const workbook = XLSX.utils.book_new();
    const worksheet1 = XLSX.utils.json_to_sheet(transformedData);
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Sheet1");

    if (secondTable?.length > 0) {
      const counts = secondTable.reduce((acc, { Location }) => {
        if (Location === "Onsite") acc.onsite++;
        if (Location === "In House") acc.inHouse++;
        return acc;
      }, { onsite: 0, inHouse: 0 });
    
      const worksheet2 = XLSX.utils.json_to_sheet(secondTable);
      
      XLSX.utils.sheet_add_json(worksheet2, [
        {}, 
        {},
        {A: "", B: "", C: "", Location: "Onsite Count", Count: counts.onsite },
        { A: "", B: "", C: "",Location: "In House Count", Count: counts.inHouse }
      ], { skipHeader: true, origin: secondTable.length + 1 });
    
      XLSX.utils.book_append_sheet(workbook, worksheet2, "Sheet2");
    }
    

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(data, `${selectedRouteInUpperCase || "data"}.xlsx`);
  };

  function shouldConvertToDate(key) {
    const dateKeys = [
      "checkinDatetime",
      "checkoutDatetime",
      "lastModified",
      "outwardDate",
      "expectedReturnDate",
      "returnDate",
      "siteInwardDate",
    ];
    return dateKeys.includes(key);
  }

  return (
    <div>
      <Button
        id="stdMastersEQPList_convertexcel"
        variant="contained"
        size="small"
        disabled={!(editAccess?.includes(4) || editAccess?.includes(0))}
        style={{ marginLeft: "10px" }}
        onClick={convertToExcel}
      >
        Export Excel
      </Button>
    </div>
  );
};

export default ExcelDownload;
